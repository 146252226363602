import { InteractionLogic } from "models/logic";

import { ApolloAPI } from "../../models/API";
import { Class as ClassDTO } from "../../models/schema";
import ClassMapper from "./ClassMapper";
import { Class } from "./models/class";

type API = ApolloAPI<"classes">;

interface Operations {
}

type Models = {
	classes: Class[]
};

const useClasses: InteractionLogic<API, Operations, Models> = (classesAPI) => {
	const mapper = new ClassMapper();

	const extractClassDTOsFromData = (data: API["data"]): DeepPartial<ClassDTO>[] => data?.classes || [];

	const classes = classesAPI?.data
		? extractClassDTOsFromData(classesAPI.data).map(mapper.classDTOToClass)
		: [];

	return ({
		models: {
			classes,
		},
		operations: {
		},
	});
};

export default useClasses;
