import styled from "styled-components";
import superFive from "../../assets/images/homepage-five.png";

const PageWithFormStyle = styled.main`
	display: grid;
	grid-template-columns: 1fr 440px 1fr 50%;
	grid-template-areas: ". form . side";
	align-items: center;
	height: 100vh;
	padding: 0;

	.form {
		grid-area: form;
	}

	.side {
		grid-area: side;
		height: 100%;
		align-self: flex-start;
		max-height: calc(100vh - 1rem);
	}
`;

export const SideComponent = styled.img.attrs({
	src: superFive,
	alt: "Five",
})`
	max-height: 100%;
	float: right;
`;

export default PageWithFormStyle;
