/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProjectMetricsVariables = Types.Exact<{
  filter?: Types.Maybe<Types.SchoolyearFilter>;
  paging?: Types.Maybe<Types.Paging>;
  sort?: Types.Maybe<Array<Types.Maybe<Types.Sort>> | Types.Maybe<Types.Sort>>;
}>;


export type GetProjectMetrics = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'moduleRegistrationsCount' | 'pupilsCount'>
  & { schoolyears: Array<(
    { __typename?: 'Schoolyear' }
    & Pick<Types.Schoolyear, 'id'>
    & { schoolyearTotal: (
      { __typename?: 'SchoolyearTotal' }
      & Pick<Types.SchoolyearTotal, 'id' | 'totalDistance' | 'totalCO2' | 'totalFuel'>
    ) }
  )> }
);


export const GetProjectMetricsDocument = gql`
    query getProjectMetrics($filter: SchoolyearFilter, $paging: Paging, $sort: [Sort]) {
  schoolyears(filter: $filter, paging: $paging, sort: $sort) {
    id
    schoolyearTotal {
      id
      totalDistance
      totalCO2
      totalFuel
    }
  }
  moduleRegistrationsCount
  pupilsCount
}
    `;

/**
 * __useGetProjectMetrics__
 *
 * To run a query within a React component, call `useGetProjectMetrics` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMetrics` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMetrics({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetProjectMetrics(baseOptions?: Apollo.QueryHookOptions<GetProjectMetrics, GetProjectMetricsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectMetrics, GetProjectMetricsVariables>(GetProjectMetricsDocument, options);
      }
export function useGetProjectMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectMetrics, GetProjectMetricsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectMetrics, GetProjectMetricsVariables>(GetProjectMetricsDocument, options);
        }
export type GetProjectMetricsHookResult = ReturnType<typeof useGetProjectMetrics>;
export type GetProjectMetricsLazyQueryHookResult = ReturnType<typeof useGetProjectMetricsLazyQuery>;
export type GetProjectMetricsQueryResult = Apollo.QueryResult<GetProjectMetrics, GetProjectMetricsVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    