/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  Time: any;
  Upload: any;
};


export type AcceptedPolicyInput = {
  id: Scalars['String'];
  type: PolicyType;
  accepted: Scalars['Boolean'];
};

export type Achievement = {
  __typename?: 'Achievement';
  id: Scalars['String'];
  badgeImage: Scalars['String'];
  description: Scalars['String'];
  goal: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
  recurring: Scalars['Boolean'];
  coinsToEarn: Scalars['Int'];
  unlocked: Scalars['Boolean'];
  progress: Scalars['Float'];
  achievementProgresses: Array<AchievementProgress>;
  achievementsUnlocked: Array<AchievementUnlocked>;
};

export type AchievementCreateInput = {
  badgeImage: Scalars['String'];
  description: Scalars['String'];
  goal: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
  recurring: Scalars['Boolean'];
  coinsToEarn: Scalars['Int'];
};

export type AchievementFilter = {
  id?: Maybe<StringFilter>;
  visible?: Maybe<BooleanFilter>;
  type?: Maybe<StringFilter>;
  AND?: Maybe<Array<AchievementFilter>>;
  OR?: Maybe<Array<AchievementFilter>>;
};

export type AchievementLevel = {
  __typename?: 'AchievementLevel';
  id: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  achievement: Achievement;
  fromProgress: Scalars['Float'];
  toProgress: Scalars['Float'];
  status?: Maybe<AchievementLevelPupilStatus>;
  createdAt: Scalars['DateTime'];
  backgrounds: Array<AchievementLevelBackground>;
  stepStones: Array<AchievementLevelStepStone>;
};

export type AchievementLevelBackground = {
  __typename?: 'AchievementLevelBackground';
  id: Scalars['String'];
  assetUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type AchievementLevelFilter = {
  id?: Maybe<StringFilter>;
  achievement?: Maybe<AchievementFilter>;
  fromProgress?: Maybe<FloatFilter>;
  toProgress?: Maybe<FloatFilter>;
  AND?: Maybe<Array<AchievementLevelFilter>>;
  OR?: Maybe<Array<AchievementLevelFilter>>;
};

export type AchievementLevelPupilStatus = {
  __typename?: 'AchievementLevelPupilStatus';
  id: Scalars['String'];
  achievementLevel: AchievementLevel;
  active: Scalars['Boolean'];
  progress: Scalars['Float'];
  completedAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type AchievementLevelPupilStatusFilter = {
  id?: Maybe<StringFilter>;
  pupilId?: Maybe<StringFilter>;
  achievementLevel?: Maybe<AchievementLevelFilter>;
  active?: Maybe<BooleanFilter>;
  progress?: Maybe<FloatFilter>;
  completedAt?: Maybe<DateTimeFilter>;
  startedAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<AchievementLevelPupilStatusFilter>>;
  OR?: Maybe<Array<AchievementLevelPupilStatusFilter>>;
};

export type AchievementLevelStepStone = {
  __typename?: 'AchievementLevelStepStone';
  id: Scalars['String'];
  assetUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type AchievementMilestone = {
  __typename?: 'AchievementMilestone';
  id: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  achievement: Achievement;
  progress: Scalars['Float'];
  status?: Maybe<AchievementMilestonePupilStatus>;
  createdAt: Scalars['DateTime'];
  rewards: Array<AchievementMilestoneReward>;
};

export type AchievementMilestoneFilter = {
  id?: Maybe<StringFilter>;
  achievement?: Maybe<AchievementFilter>;
  progress?: Maybe<FloatFilter>;
  AND?: Maybe<Array<AchievementMilestoneFilter>>;
  OR?: Maybe<Array<AchievementMilestoneFilter>>;
};

export type AchievementMilestonePupilStatus = {
  __typename?: 'AchievementMilestonePupilStatus';
  id: Scalars['String'];
  achievementMilestone: AchievementMilestone;
  active: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type AchievementMilestonePupilStatusFilter = {
  id?: Maybe<StringFilter>;
  pupilId?: Maybe<StringFilter>;
  achievementMilestone?: Maybe<AchievementMilestoneFilter>;
  active?: Maybe<BooleanFilter>;
  completedAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<AchievementMilestonePupilStatusFilter>>;
  OR?: Maybe<Array<AchievementMilestonePupilStatusFilter>>;
};

export type AchievementMilestoneReward = {
  __typename?: 'AchievementMilestoneReward';
  id: Scalars['String'];
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type AchievementProgress = {
  __typename?: 'AchievementProgress';
  id: Scalars['String'];
  progress: Scalars['Int'];
  pupil: Pupil;
  achievement: Achievement;
};

export type AchievementUnlocked = {
  __typename?: 'AchievementUnlocked';
  id: Scalars['String'];
  pupil: Pupil;
  unlockedOn: Scalars['DateTime'];
  achievement: Achievement;
};

export type AchievementUpdateInput = {
  badgeImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
  recurring?: Maybe<Scalars['Boolean']>;
  coinsToEarn?: Maybe<Scalars['Int']>;
};

export type AirQuality = {
  __typename?: 'AirQuality';
  id: Scalars['String'];
  airQuality: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  location: Location;
};

export type AirQualityFilter = {
  id?: Maybe<StringFilter>;
  airQuality?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  location?: Maybe<BasicStringFilter>;
  AND?: Maybe<Array<AirQualityFilter>>;
  OR?: Maybe<Array<AirQualityFilter>>;
};

export type AnonymousTokenInput = {
  schoolCode?: Maybe<Scalars['String']>;
  projectCode?: Maybe<Scalars['String']>;
};

export type Asset = {
  __typename?: 'Asset';
  id: Scalars['String'];
  url: Scalars['String'];
  fileName: Scalars['String'];
  fileSizeBytes: Scalars['Int'];
};

export type AssetUploadInput = {
  contentBase64: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
};

export type AssignedShopItem = {
  __typename?: 'AssignedShopItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  image: Asset;
  price: Scalars['Int'];
  availableStock: Scalars['Int'];
  orderRestrictions: ShopItemOrderRestrictions;
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
  type: ShopItemType;
  canOrder: Scalars['Boolean'];
  canOrderDetails: AssignedShopItemCanOrderDetails;
  createdAt: Scalars['DateTime'];
};

export type AssignedShopItemCanOrderDetails = {
  __typename?: 'AssignedShopItemCanOrderDetails';
  isSoldOut: Scalars['Boolean'];
  sufficientCoins: Scalars['Boolean'];
  validOrderRestrictions: Scalars['Boolean'];
};

export type AssignedShopItemFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  instructions?: Maybe<StringFilter>;
  price?: Maybe<IntFilter>;
  publishOn?: Maybe<DateTimeFilter>;
  publishUntil?: Maybe<DateTimeFilter>;
  project?: Maybe<ProjectFilter>;
  school?: Maybe<SchoolFilter>;
  class?: Maybe<ClassFilter>;
  AND?: Maybe<Array<ShopItemFilter>>;
  OR?: Maybe<Array<ShopItemFilter>>;
};

export type Avatar = {
  __typename?: 'Avatar';
  id: Scalars['String'];
  eyebrows: Scalars['String'];
  face: Scalars['String'];
  hair: Scalars['String'];
  hairColor: Scalars['String'];
  head: Scalars['String'];
  nose: Scalars['String'];
  skinColor: Scalars['String'];
  sweater: Scalars['String'];
};

export type AvatarCreateInput = {
  eyebrows: Scalars['String'];
  face: Scalars['String'];
  hair: Scalars['String'];
  hairColor: Scalars['String'];
  head: Scalars['String'];
  nose: Scalars['String'];
  skinColor: Scalars['String'];
  sweater: Scalars['String'];
};

export type AvatarElement = {
  __typename?: 'AvatarElement';
  id: Scalars['String'];
  type: Scalars['String'];
  assetUrl: Scalars['String'];
  possibleColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  visibilityType: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type AvatarElementFilter = {
  id?: Maybe<StringFilter>;
  type?: Maybe<StringFilter>;
  visibilityType?: Maybe<StringFilter>;
  AND?: Maybe<Array<AvatarElementFilter>>;
  OR?: Maybe<Array<AvatarElementFilter>>;
};

export type BasicStringArrayFilter = {
  containsSome?: Maybe<Array<Maybe<Scalars['String']>>>;
  containsAll?: Maybe<Array<Maybe<Scalars['String']>>>;
  equals?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BasicStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BooleanFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<Scalars['Boolean']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  id: Scalars['String'];
  title: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  coinsToEarn: Scalars['Int'];
  coinEarnModel: CampaignCoinEarnModel;
  classes?: Maybe<Array<Class>>;
  checkSummary?: Maybe<Array<Maybe<CampaignCheckSummary>>>;
  checks?: Maybe<Array<Maybe<CampaignCheck>>>;
};


export type CampaignCheckSummaryArgs = {
  filter?: Maybe<CampaignCheckFilter>;
  groupBy: CampaignCheckSummaryGroupBy;
};


export type CampaignChecksArgs = {
  filter?: Maybe<CampaignCheckFilter>;
};

export type CampaignCheck = {
  __typename?: 'CampaignCheck';
  pupil: Pupil;
  date: Scalars['DateTime'];
};

export type CampaignCheckCreateManyInput = {
  campaign: Scalars['String'];
  pupils: Array<Scalars['String']>;
  date: Scalars['DateTime'];
};

export type CampaignCheckFilter = {
  date?: Maybe<DateTimeFilter>;
  pupil?: Maybe<PupilReferenceFilter>;
};

export type CampaignCheckSummary = {
  __typename?: 'CampaignCheckSummary';
  pupil?: Maybe<Pupil>;
  class?: Maybe<Class>;
  school?: Maybe<School>;
  schoolyear?: Maybe<Schoolyear>;
  project?: Maybe<Project>;
  count: Scalars['Int'];
};

export enum CampaignCheckSummaryGroupBy {
  Pupil = 'PUPIL',
  Class = 'CLASS',
  School = 'SCHOOL',
  Schoolyear = 'SCHOOLYEAR',
  Project = 'PROJECT'
}

export enum CampaignCoinEarnModel {
  Once = 'ONCE',
  EveryDay = 'EVERY_DAY'
}

export type CampaignCreateInput = {
  title: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  coinsToEarn: Scalars['Int'];
  coinEarnModel: CampaignCoinEarnModel;
  classes: Array<Scalars['String']>;
};

export type CampaignFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  activeFrom?: Maybe<DateTimeFilter>;
  activeTo?: Maybe<DateTimeFilter>;
  class?: Maybe<BasicStringArrayFilter>;
  school?: Maybe<BasicStringArrayFilter>;
  schoolyear?: Maybe<BasicStringArrayFilter>;
  project?: Maybe<BasicStringArrayFilter>;
  AND?: Maybe<Array<CampaignFilter>>;
  OR?: Maybe<Array<CampaignFilter>>;
};

export type CampaignUpdateInput = {
  title?: Maybe<Scalars['String']>;
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  coinsToEarn?: Maybe<Scalars['Int']>;
  coinEarnModel?: Maybe<CampaignCoinEarnModel>;
  classes?: Maybe<Array<Scalars['String']>>;
};

export type Challenge = {
  __typename?: 'Challenge';
  id: Scalars['String'];
  title: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  school: School;
  classes: Array<Class>;
  scoringType: Scalars['String'];
  description: Scalars['String'];
  goal: Scalars['Int'];
  progress: Array<ChallengeProgress>;
};

export type ChallengeCreateInput = {
  title: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  school: Scalars['String'];
  classes?: Maybe<Array<Scalars['String']>>;
  scoringType: Scalars['String'];
  description: Scalars['String'];
  goal: Scalars['Int'];
};

export type ChallengeFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  activeFrom?: Maybe<DateTimeFilter>;
  activeTo?: Maybe<DateTimeFilter>;
  school?: Maybe<SchoolFilter>;
  class?: Maybe<BasicStringArrayFilter>;
  AND?: Maybe<Array<ChallengeFilter>>;
  OR?: Maybe<Array<ChallengeFilter>>;
};

export type ChallengeProgress = {
  __typename?: 'ChallengeProgress';
  classId?: Maybe<Scalars['String']>;
  progress: Scalars['Int'];
};

export type ChallengeSearchItem = SearchItem & {
  __typename?: 'ChallengeSearchItem';
  type: SearchType;
  challenge: Challenge;
};

export type ChallengeUpdateInput = {
  title?: Maybe<Scalars['String']>;
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  school?: Maybe<Scalars['String']>;
  classes?: Maybe<Array<Scalars['String']>>;
  scoringType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['Int']>;
};

export type Class = {
  __typename?: 'Class';
  id: Scalars['String'];
  title: Scalars['String'];
  pupils?: Maybe<Array<Pupil>>;
  pupilsCount: Scalars['Int'];
  school: School;
  classTotal: ClassTotal;
  moduleRegistrationsCount: Scalars['Int'];
  moduleRegistrationStatistics: ModuleRegistrationStatistics;
};


export type ClassModuleRegistrationsCountArgs = {
  filter?: Maybe<ModuleRegistrationReferenceFilter>;
};


export type ClassModuleRegistrationStatisticsArgs = {
  filter?: Maybe<ModuleRegistrationReferenceFilter>;
};

export type ClassCreateInput = {
  title: Scalars['String'];
  school: Scalars['String'];
};

export type ClassExercise = {
  __typename?: 'ClassExercise';
  id: Scalars['String'];
  class: Class;
  exercise: Exercise;
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link?: Maybe<ClassExerciseLink>;
};

export type ClassExerciseCreateInput = {
  class: Scalars['String'];
  exercise: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link?: Maybe<ClassExerciseLinkCreateInput>;
};

export type ClassExerciseCreateManyExerciseInput = {
  id: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
};

export type ClassExerciseCreateManyInput = {
  classIds: Array<Scalars['String']>;
  exercises: Array<ClassExerciseCreateManyExerciseInput>;
};

export type ClassExerciseFilter = {
  id?: Maybe<StringFilter>;
  class?: Maybe<BasicStringFilter>;
  exercise?: Maybe<ExerciseFilter>;
  link?: Maybe<ClassExerciseLinkFilter>;
  activeFrom?: Maybe<DateTimeFilter>;
  activeTo?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<ClassExerciseFilter>>;
  OR?: Maybe<Array<ClassExerciseFilter>>;
};

export type ClassExerciseForExerciseCreateInput = {
  id?: Maybe<Scalars['String']>;
  class: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link?: Maybe<ClassExerciseLinkCreateInput>;
};

export type ClassExerciseLink = {
  __typename?: 'ClassExerciseLink';
  id: Scalars['String'];
  classLibrary: ClassLibrary;
  linkedBy?: Maybe<Scalars['String']>;
  linkedByName?: Maybe<Scalars['String']>;
};

export type ClassExerciseLinkCreateInput = {
  classLibrary: Scalars['String'];
  linkedBy?: Maybe<Scalars['String']>;
  linkedByName?: Maybe<Scalars['String']>;
};

export type ClassExerciseLinkFilter = {
  id?: Maybe<StringFilter>;
  classLibrary?: Maybe<BasicStringFilter>;
  class?: Maybe<ClassReferenceFilter>;
  AND?: Maybe<Array<ClassExerciseLinkFilter>>;
  OR?: Maybe<Array<ClassExerciseLinkFilter>>;
};

export type ClassExerciseLinkReferenceFilter = {
  id?: Maybe<StringFilter>;
  classLibrary?: Maybe<BasicStringFilter>;
  class?: Maybe<ClassReferenceFilter>;
  exists?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<ClassExerciseLinkReferenceFilter>>;
  OR?: Maybe<Array<ClassExerciseLinkReferenceFilter>>;
};

export type ClassExerciseUpdateInput = {
  class?: Maybe<Scalars['String']>;
  exercise?: Maybe<Scalars['String']>;
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  link?: Maybe<ClassExerciseLinkCreateInput>;
};

export type ClassExerciseUpdateManyExerciseInput = {
  id: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
};

export type ClassFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  school?: Maybe<SchoolFilter>;
  AND?: Maybe<Array<ClassFilter>>;
  OR?: Maybe<Array<ClassFilter>>;
};

export type ClassLibrary = {
  __typename?: 'ClassLibrary';
  id: Scalars['String'];
  class: Class;
  exercise: Exercise;
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link?: Maybe<ClassExerciseLink>;
};

export type ClassLibraryCreateInput = {
  class: Scalars['String'];
  exercise: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link: Scalars['String'];
};

export type ClassLibraryCreateManyExerciseInput = {
  id: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
};

export type ClassLibraryCreateManyInput = {
  classIds: Array<Scalars['String']>;
  exercises: Array<ClassLibraryCreateManyExerciseInput>;
};

export type ClassLibraryFilter = {
  id?: Maybe<StringFilter>;
  class?: Maybe<ClassFilter>;
  exercise?: Maybe<ExerciseFilter>;
  link?: Maybe<ClassExerciseLinkFilter>;
  activeFrom?: Maybe<DateTimeFilter>;
  activeTo?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<ClassLibraryFilter>>;
  OR?: Maybe<Array<ClassLibraryFilter>>;
};

export type ClassLibraryUpdateInput = {
  class?: Maybe<Scalars['String']>;
  exercise?: Maybe<Scalars['String']>;
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  link?: Maybe<Scalars['String']>;
};

export type ClassMessage = {
  __typename?: 'ClassMessage';
  id: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  showFrom: Scalars['DateTime'];
  showTo: Scalars['DateTime'];
};

export type ClassReferenceFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  school?: Maybe<SchoolReferenceFilter>;
  AND?: Maybe<Array<ClassReferenceFilter>>;
  OR?: Maybe<Array<ClassReferenceFilter>>;
};

export type ClassSearchItem = SearchItem & {
  __typename?: 'ClassSearchItem';
  type: SearchType;
  class: Class;
};

export type ClassTotal = {
  __typename?: 'ClassTotal';
  id: Scalars['String'];
  class: Class;
  totalCredits: Scalars['Int'];
  totalRegistrations: Scalars['Int'];
  totalDistance: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  highestTotalCreditsOnOneDay: Scalars['Int'];
};

export type ClassUpdateInput = {
  title?: Maybe<Scalars['String']>;
};

export type ContentBlock = {
  __typename?: 'ContentBlock';
  id: Scalars['String'];
  title: Scalars['String'];
  published: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  showAsMenuItem: Scalars['Boolean'];
  menuTitle?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  content: Scalars['String'];
  visibility: ContentBlockVisibility;
  order?: Maybe<Scalars['Int']>;
  schoolyear: Schoolyear;
  schools: Array<School>;
};

export type ContentBlockCreateInput = {
  title: Scalars['String'];
  published: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  showAsMenuItem: Scalars['Boolean'];
  menuTitle?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  content: Scalars['String'];
  visibility: ContentBlockVisibility;
  order?: Maybe<Scalars['Int']>;
  schoolyear: Scalars['String'];
  schools?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContentBlockFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  published?: Maybe<BooleanFilter>;
  url?: Maybe<StringFilter>;
  showAsMenuItem?: Maybe<BooleanFilter>;
  type?: Maybe<StringFilter>;
  visibility?: Maybe<ContentBlockVisibilityFilter>;
  schoolyear?: Maybe<BasicStringFilter>;
  AND?: Maybe<Array<ContentBlockFilter>>;
  OR?: Maybe<Array<ContentBlockFilter>>;
};

export type ContentBlockUpdateInput = {
  title?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  showAsMenuItem?: Maybe<Scalars['Boolean']>;
  menuTitle?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  visibility?: Maybe<ContentBlockVisibility>;
  order?: Maybe<Scalars['Int']>;
  schools?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ContentBlockVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type ContentBlockVisibilityFilter = {
  equals?: Maybe<ContentBlockVisibility>;
  not?: Maybe<ContentBlockVisibility>;
  in?: Maybe<Array<Maybe<ContentBlockVisibility>>>;
  notIn?: Maybe<Array<Maybe<ContentBlockVisibility>>>;
};

export type ContentItem = {
  __typename?: 'ContentItem';
  id: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  thumbnail: Scalars['String'];
  url: Scalars['String'];
  tags: Array<ContentTag>;
  visibilityType: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type ContentItemFilter = {
  id?: Maybe<StringFilter>;
  tag?: Maybe<ContentTagFilter>;
  visibilityType?: Maybe<StringFilter>;
  AND?: Maybe<Array<ContentItemFilter>>;
  OR?: Maybe<Array<ContentItemFilter>>;
};

export type ContentTag = {
  __typename?: 'ContentTag';
  id: Scalars['String'];
  title: Scalars['String'];
  banner: Scalars['String'];
  icon: Scalars['String'];
  type: ContentType;
  createdAt: Scalars['DateTime'];
};

export type ContentTagFilter = {
  id?: Maybe<StringFilter>;
  type?: Maybe<ContentTypeFilter>;
  AND?: Maybe<Array<ContentTagFilter>>;
  OR?: Maybe<Array<ContentTagFilter>>;
};

export type ContentType = {
  __typename?: 'ContentType';
  id: Scalars['String'];
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type ContentTypeFilter = {
  id?: Maybe<StringFilter>;
  type?: Maybe<StringFilter>;
  AND?: Maybe<Array<ContentTypeFilter>>;
  OR?: Maybe<Array<ContentTypeFilter>>;
};



export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  not?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type DecreasePupilCoinInput = {
  amount: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
};

export type DecreasePupilEnergyInput = {
  amount: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
};

export type Device = {
  __typename?: 'Device';
  id: Scalars['String'];
  type: DeviceType;
};

export type DeviceCreateInput = {
  id: Scalars['String'];
  type: DeviceType;
};

export type DeviceFilter = {
  id?: Maybe<StringFilter>;
  type?: Maybe<DeviceTypeFilter>;
  AND?: Maybe<Array<DeviceFilter>>;
  OR?: Maybe<Array<DeviceFilter>>;
};

export enum DeviceType {
  Http = 'http',
  Udp = 'udp',
  Iotc = 'iotc'
}

export type DeviceTypeFilter = {
  equals?: Maybe<DeviceType>;
  not?: Maybe<DeviceType>;
  in?: Maybe<Array<Maybe<DeviceType>>>;
  notIn?: Maybe<Array<Maybe<DeviceType>>>;
};

export type Exercise = {
  __typename?: 'Exercise';
  id: Scalars['String'];
  title: Scalars['String'];
  tags: Array<ExerciseTag>;
  course: ExerciseCourse;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  creditsToEarn: Scalars['Int'];
  grade?: Maybe<ExerciseGrade>;
  image: Asset;
  library?: Maybe<Scalars['Boolean']>;
  owner: Scalars['String'];
  ownerName: Scalars['String'];
  questions: Array<ExerciseQuestion>;
  results: Array<PupilExerciseResult>;
  classExercises: Array<ClassExercise>;
  classExercisesCount: Scalars['Int'];
};

export type ExerciseAnswer = {
  __typename?: 'ExerciseAnswer';
  id: Scalars['String'];
  question: ExerciseQuestion;
  answer: Scalars['String'];
  image?: Maybe<Asset>;
  correct: Scalars['Boolean'];
};

export type ExerciseAnswerCreateInput = {
  question: Scalars['String'];
  answer: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  correct: Scalars['Boolean'];
};

export type ExerciseAnswerForQuestionCreateInput = {
  id?: Maybe<Scalars['String']>;
  answer: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  correct: Scalars['Boolean'];
};

export type ExerciseAnswerUpdateInput = {
  answer?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  correct?: Maybe<Scalars['Boolean']>;
};

export type ExerciseCourse = {
  __typename?: 'ExerciseCourse';
  id: Scalars['String'];
  title: Scalars['String'];
  image: Asset;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  exercisesCount: Scalars['Int'];
  classExercisesCount: Scalars['Int'];
};

export type ExerciseCourseCreateInput = {
  title: Scalars['String'];
  image: Scalars['String'];
};

export type ExerciseCourseFilter = {
  id?: Maybe<StringFilter>;
};

export type ExerciseCourseUpdateInput = {
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type ExerciseCreateInput = {
  title: Scalars['String'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  course: Scalars['String'];
  creditsToEarn: Scalars['Int'];
  grade?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  library?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<ExerciseQuestionForExerciseCreateInput>>;
  classExercises?: Maybe<Array<ClassExerciseForExerciseCreateInput>>;
};

export type ExerciseFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  owner?: Maybe<StringFilter>;
  course?: Maybe<BasicStringFilter>;
  grade?: Maybe<BasicStringFilter>;
  tags?: Maybe<BasicStringFilter>;
  library?: Maybe<BooleanFilter>;
  AND?: Maybe<Array<ExerciseFilter>>;
  OR?: Maybe<Array<ExerciseFilter>>;
};

export type ExerciseGrade = {
  __typename?: 'ExerciseGrade';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type ExerciseGradeCreateInput = {
  title: Scalars['String'];
};

export type ExerciseGradeFilter = {
  id?: Maybe<StringFilter>;
};

export type ExerciseGradeUpdateInput = {
  title?: Maybe<Scalars['String']>;
};

export type ExerciseQuestion = {
  __typename?: 'ExerciseQuestion';
  id: Scalars['String'];
  answers: Array<ExerciseAnswer>;
  type: ExerciseQuestionType;
  question: Scalars['String'];
  image?: Maybe<Asset>;
  order: Scalars['Int'];
};

export type ExerciseQuestionCreateInput = {
  exercise: Scalars['String'];
  type: ExerciseQuestionType;
  question: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  answers?: Maybe<Array<ExerciseAnswerForQuestionCreateInput>>;
};

export type ExerciseQuestionFilter = {
  id?: Maybe<StringFilter>;
};

export type ExerciseQuestionForExerciseCreateInput = {
  id?: Maybe<Scalars['String']>;
  type: ExerciseQuestionType;
  question: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  answers?: Maybe<Array<ExerciseAnswerForQuestionCreateInput>>;
};

export enum ExerciseQuestionType {
  Image = 'IMAGE',
  Text = 'TEXT'
}

export type ExerciseQuestionUpdateInput = {
  type?: Maybe<ExerciseQuestionType>;
  question?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  answers?: Maybe<Array<ExerciseAnswerForQuestionCreateInput>>;
};

export type ExerciseReferenceFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  owner?: Maybe<StringFilter>;
  course?: Maybe<BasicStringFilter>;
  grade?: Maybe<BasicStringFilter>;
  tags?: Maybe<BasicStringFilter>;
  library?: Maybe<BooleanFilter>;
  AND?: Maybe<Array<ExerciseReferenceFilter>>;
  OR?: Maybe<Array<ExerciseReferenceFilter>>;
};

export type ExerciseSearchItem = SearchItem & {
  __typename?: 'ExerciseSearchItem';
  type: SearchType;
  exercise: Exercise;
};

export type ExerciseTag = {
  __typename?: 'ExerciseTag';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type ExerciseTagCreateInput = {
  title: Scalars['String'];
};

export type ExerciseTagFilter = {
  id?: Maybe<StringFilter>;
};

export type ExerciseTagUpdateInput = {
  title?: Maybe<Scalars['String']>;
};

export type ExerciseUpdateInput = {
  title?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  course?: Maybe<Scalars['String']>;
  creditsToEarn?: Maybe<Scalars['Int']>;
  grade?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  library?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<ExerciseQuestionForExerciseCreateInput>>;
  classExercises?: Maybe<Array<ClassExerciseForExerciseCreateInput>>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  not?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
};

export type Game = {
  __typename?: 'Game';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type GameAttribute = {
  __typename?: 'GameAttribute';
  id: Scalars['String'];
  game: Game;
  title?: Maybe<Scalars['String']>;
  level?: Maybe<GameAttributeLevel>;
  status?: Maybe<GameAttributeStatus>;
  createdAt: Scalars['DateTime'];
};

export type GameAttributeFilter = {
  id?: Maybe<StringFilter>;
  gameId?: Maybe<StringFilter>;
  AND?: Maybe<Array<GameAttributeFilter>>;
  OR?: Maybe<Array<GameAttributeFilter>>;
};

export type GameAttributeLevel = {
  __typename?: 'GameAttributeLevel';
  id: Scalars['String'];
  gameAttribute: GameAttribute;
  level: Scalars['Int'];
  createdAt: Scalars['DateTime'];
};

export type GameAttributeLevelFilter = {
  gameAttributeId?: Maybe<StringFilter>;
  pupil?: Maybe<PupilFilter>;
  AND?: Maybe<Array<GameAttributeLevelFilter>>;
  OR?: Maybe<Array<GameAttributeLevelFilter>>;
};

export type GameAttributeLevelInput = {
  gameAttributeId: Scalars['String'];
  level: Scalars['Int'];
};

export type GameAttributeStatus = {
  __typename?: 'GameAttributeStatus';
  id: Scalars['String'];
  gameAttribute: GameAttribute;
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type GameAttributeStatusFilter = {
  gameAttributeId?: Maybe<StringFilter>;
  pupil?: Maybe<PupilFilter>;
  AND?: Maybe<Array<GameAttributeStatusFilter>>;
  OR?: Maybe<Array<GameAttributeStatusFilter>>;
};

export type GameAttributeStatusInput = {
  gameAttributeId: Scalars['String'];
  active: Scalars['Boolean'];
};

export type GameHighScore = {
  __typename?: 'GameHighScore';
  id: Scalars['String'];
  game: Game;
  score: Scalars['Int'];
  createdAt: Scalars['DateTime'];
};

export type GameHighScoreFilter = {
  gameId?: Maybe<StringFilter>;
  pupil?: Maybe<PupilFilter>;
  AND?: Maybe<Array<GameHighScoreFilter>>;
  OR?: Maybe<Array<GameHighScoreFilter>>;
};

export type GameLeaderboardEntry = {
  __typename?: 'GameLeaderboardEntry';
  id: Scalars['String'];
  pupil: Pupil;
  game: Game;
  position: Scalars['Int'];
  score: Scalars['Int'];
  createdAt: Scalars['DateTime'];
};

export type GamePupilTotal = {
  __typename?: 'GamePupilTotal';
  coins: Scalars['Int'];
  energy: Scalars['Int'];
};

export type GamePupilTotalFilter = {
  pupil?: Maybe<PupilFilter>;
  AND?: Maybe<Array<GamePupilTotalFilter>>;
  OR?: Maybe<Array<GamePupilTotalFilter>>;
};

export type GameScoreClassLeaderboardFilter = {
  gameId?: Maybe<StringFilter>;
  pupil?: Maybe<PupilFilter>;
  class?: Maybe<ClassFilter>;
  AND?: Maybe<Array<GameScoreClassLeaderboardFilter>>;
  OR?: Maybe<Array<GameScoreClassLeaderboardFilter>>;
};

export type GameScoreSchoolLeaderboardFilter = {
  gameId?: Maybe<StringFilter>;
  school?: Maybe<SchoolFilter>;
  AND?: Maybe<Array<GameScoreSchoolLeaderboardFilter>>;
  OR?: Maybe<Array<GameScoreSchoolLeaderboardFilter>>;
};

export type GlobalRegimeInactivityInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type HazardousLocation = {
  __typename?: 'HazardousLocation';
  id: Scalars['String'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  reason: HazardousLocationReason;
  location: Location;
  reportedBy: Scalars['String'];
  status?: Maybe<HazardousLocationStatus>;
  declinedAt?: Maybe<Scalars['DateTime']>;
  declinedBy?: Maybe<Scalars['String']>;
  declinedReason?: Maybe<Scalars['String']>;
};

export type HazardousLocationCreateInput = {
  reason: Scalars['String'];
  location: LocationCreateInput;
  reportedBy: Scalars['String'];
  schoolyear: Scalars['String'];
};

export type HazardousLocationFilter = {
  id?: Maybe<StringFilter>;
  schoolyear?: Maybe<BasicStringFilter>;
  status?: Maybe<HazardousLocationStatusFilter>;
  AND?: Maybe<Array<HazardousLocationFilter>>;
  OR?: Maybe<Array<HazardousLocationFilter>>;
};

export type HazardousLocationReason = {
  __typename?: 'HazardousLocationReason';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type HazardousLocationReasonCreateInput = {
  title: Scalars['String'];
  project: Scalars['String'];
};

export type HazardousLocationReasonFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  AND?: Maybe<Array<HazardousLocationReasonFilter>>;
  OR?: Maybe<Array<HazardousLocationReasonFilter>>;
};

export type HazardousLocationReasonUpdateInput = {
  title?: Maybe<Scalars['String']>;
};

export enum HazardousLocationStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Reported = 'REPORTED'
}

export type HazardousLocationStatusFilter = {
  equals?: Maybe<HazardousLocationStatus>;
  not?: Maybe<HazardousLocationStatus>;
  in?: Maybe<Array<Maybe<HazardousLocationStatus>>>;
  notIn?: Maybe<Array<Maybe<HazardousLocationStatus>>>;
};

export type HazardousLocationUpdateInput = {
  status?: Maybe<HazardousLocationStatus>;
  declinedReason?: Maybe<Scalars['String']>;
};

export type IncreasePupilCoinInput = {
  amount: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  not?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
};


export enum Language {
  Nl = 'nl'
}

export type Location = {
  __typename?: 'Location';
  id: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  friendlyName?: Maybe<Scalars['String']>;
  schoolyear?: Maybe<Schoolyear>;
  airQualities: Array<AirQuality>;
  airQualitiesCount: Scalars['Int'];
};


export type LocationAirQualitiesArgs = {
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};

export type LocationCreateInput = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  friendlyName?: Maybe<Scalars['String']>;
};

export type LocationFilter = {
  id?: Maybe<StringFilter>;
  schoolyear?: Maybe<BasicStringFilter>;
  AND?: Maybe<Array<LocationFilter>>;
  OR?: Maybe<Array<LocationFilter>>;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  token: Scalars['String'];
};

export type Module = {
  __typename?: 'Module';
  id: Scalars['String'];
  deviceId: Scalars['String'];
  location: Location;
  title: Scalars['String'];
  ignoreOnRoute: Scalars['Boolean'];
  softDeleted: Scalars['Boolean'];
  moduleRegistrations?: Maybe<Array<Maybe<ModuleRegistration>>>;
  moduleRegistrationsCount: Scalars['Int'];
  regime: Regime;
  moduleTypes?: Maybe<Array<Maybe<ModuleType>>>;
  reference?: Maybe<Scalars['String']>;
};


export type ModuleModuleRegistrationsArgs = {
  filter?: Maybe<ModuleRegistrationReferenceFilter>;
};


export type ModuleModuleRegistrationsCountArgs = {
  filter?: Maybe<ModuleRegistrationReferenceFilter>;
};

export type ModuleCreateInput = {
  deviceId: Scalars['String'];
  location: LocationCreateInput;
  title: Scalars['String'];
  ignoreOnRoute: Scalars['Boolean'];
  regime: Scalars['String'];
  moduleTypes: Array<ModuleTypeCreateInput>;
  schoolyear: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
};

export type ModuleFilter = {
  id?: Maybe<StringFilter>;
  location?: Maybe<BasicStringFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<StringArrayFilter>;
  softDeleted?: Maybe<BooleanFilter>;
  schoolyear?: Maybe<BasicStringFilter>;
  AND?: Maybe<Array<ModuleFilter>>;
  OR?: Maybe<Array<ModuleFilter>>;
};

export type ModuleReferenceFilter = {
  id?: Maybe<StringFilter>;
  location?: Maybe<BasicStringFilter>;
  title?: Maybe<StringFilter>;
  softDeleted?: Maybe<BooleanFilter>;
  schoolyear?: Maybe<BasicStringFilter>;
  AND?: Maybe<Array<ModuleReferenceFilter>>;
  OR?: Maybe<Array<ModuleReferenceFilter>>;
};

export type ModuleRegistration = {
  __typename?: 'ModuleRegistration';
  id: Scalars['String'];
  pupil: Pupil;
  createdAt: Scalars['DateTime'];
  identifier: Scalars['String'];
  module: Module;
  transaction: PupilCredit;
  replaced: Scalars['Boolean'];
  coinsEarned: Scalars['Int'];
  distance: Scalars['Float'];
  type: Scalars['String'];
};

export type ModuleRegistrationFilter = {
  id?: Maybe<StringFilter>;
  pupil?: Maybe<PupilFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  module?: Maybe<ModuleFilter>;
  replaced?: Maybe<BooleanFilter>;
  type?: Maybe<StringFilter>;
  AND?: Maybe<Array<ModuleRegistrationFilter>>;
  OR?: Maybe<Array<ModuleRegistrationFilter>>;
};

export type ModuleRegistrationReferenceFilter = {
  id?: Maybe<StringFilter>;
  pupil?: Maybe<PupilReferenceFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  module?: Maybe<ModuleReferenceFilter>;
  replaced?: Maybe<BooleanFilter>;
  type?: Maybe<StringFilter>;
  AND?: Maybe<Array<ModuleRegistrationReferenceFilter>>;
  OR?: Maybe<Array<ModuleRegistrationReferenceFilter>>;
};

export type ModuleRegistrationSimulateInput = {
  pupil: Scalars['String'];
  module: Scalars['String'];
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type ModuleRegistrationStatistics = {
  __typename?: 'ModuleRegistrationStatistics';
  totalDistance: Scalars['Float'];
};

export enum ModuleRegistrationTimeSeriesGroupBy {
  Class = 'CLASS',
  Module = 'MODULE',
  Pupil = 'PUPIL',
  School = 'SCHOOL'
}

export type ModuleType = {
  __typename?: 'ModuleType';
  type: Scalars['String'];
  coinsToEarn: Scalars['Int'];
};

export type ModuleTypeCreateInput = {
  type: Scalars['String'];
  coinsToEarn: Scalars['Int'];
};

export type ModuleUpdateInput = {
  regime?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  location?: Maybe<LocationCreateInput>;
  moduleTypes?: Maybe<Array<ModuleTypeCreateInput>>;
  reference?: Maybe<Scalars['String']>;
};

export type MultiLanguageText = {
  __typename?: 'MultiLanguageText';
  language: Language;
  text: Scalars['String'];
};

export type MultiLanguageTextFilter = {
  language: Language;
  text: StringFilter;
};

export type MultiLanguageTextInput = {
  language: Language;
  text: Scalars['String'];
};

export type Multiplier = {
  __typename?: 'Multiplier';
  id: Scalars['String'];
  school: School;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  multiplier: Scalars['Int'];
};

export type MultiplierCreateInput = {
  school: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  multiplier: Scalars['Int'];
};

export type MultiplierFilter = {
  id?: Maybe<StringFilter>;
  school?: Maybe<BasicStringFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  multiplier?: Maybe<IntFilter>;
  AND?: Maybe<Array<MultiplierFilter>>;
  OR?: Maybe<Array<MultiplierFilter>>;
};

export type MultiplierUpdateInput = {
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  multiplier?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOneAchievement: Achievement;
  updateOneAchievement: Achievement;
  deleteOneAchievement: Success;
  uploadAsset: Asset;
  createOneCampaign: Campaign;
  updateOneCampaign: Campaign;
  deleteOneCampaign: Success;
  createManyCampaignChecks: Success;
  createOneChallenge: Challenge;
  updateOneChallenge: Challenge;
  deleteOneChallenge: Success;
  createOneClass: Class;
  updateOneClass: Class;
  deleteOneClass: Success;
  createOneClassExercise: ClassExercise;
  updateOneClassExercise: ClassExercise;
  deleteOneClassExercise: Success;
  createManyClassExercises: Success;
  deleteClassExercises: Success;
  deleteClassExerciseLinks: Success;
  createOneClassLibrary: ClassLibrary;
  updateOneClassLibrary: ClassLibrary;
  deleteOneClassLibrary: Success;
  createManyClassLibraries: Success;
  deleteClassLibraries: Success;
  createOneContentBlock: ContentBlock;
  updateOneContentBlock: ContentBlock;
  deleteOneContentBlock: Success;
  createOneDevice: Device;
  deleteOneDevice: Success;
  createOneExercise: Exercise;
  updateOneExercise: Exercise;
  deleteOneExercise: Success;
  completeExercise: SubmitResult;
  createOneExerciseAnswer: ExerciseAnswer;
  updateOneExerciseAnswer: ExerciseAnswer;
  deleteOneExerciseAnswer: Success;
  createOneExerciseCourse: ExerciseCourse;
  updateOneExerciseCourse: ExerciseCourse;
  deleteOneExerciseCourse: Success;
  createOneExerciseGrade: ExerciseGrade;
  updateOneExerciseGrade: ExerciseGrade;
  deleteOneExerciseGrade: Success;
  createOneExerciseQuestion: ExerciseQuestion;
  updateOneExerciseQuestion: ExerciseQuestion;
  deleteOneExerciseQuestion: Success;
  createOneExerciseTag: ExerciseTag;
  updateOneExerciseTag: ExerciseTag;
  deleteOneExerciseTag: Success;
  forgotPupilData: Success;
  setGameAttributeStatus: GameAttribute;
  setGameAttributeLevel: GameAttribute;
  increasePupilCoin: Success;
  decreasePupilCoin: Success;
  decreasePupilEnergy: GamePupilTotal;
  logGamePupilScore: Success;
  createOneHazardousLocation: HazardousLocation;
  updateOneHazardousLocation: HazardousLocation;
  deleteOneHazardousLocation: Success;
  createOneHazardousLocationReason: HazardousLocationReason;
  updateOneHazardousLocationReason: HazardousLocationReason;
  deleteOneHazardousLocationReason: Success;
  pupilLogin: LoginResult;
  usernamePupilLogin: LoginResult;
  nfcPasswordPupilLogin: LoginResult;
  nfcPupilLogin: LoginResult;
  login: LoginResult;
  createOneModule: Module;
  updateOneModule: Module;
  simulateOneModuleRegistration: Success;
  createOneMultiplier: Multiplier;
  updateOneMultiplier: Multiplier;
  deleteOneMultiplier: Success;
  resetPassword: Success;
  createOneProject: Project;
  updateOneProject: Project;
  deleteOneProject: Success;
  createOnePupil: Pupil;
  updateOnePupil: Pupil;
  deleteOnePupil: Success;
  saveZoneForPupil: Pupil;
  validatePupilClassNumber: Success;
  setOnePupilAvatarElement: PupilAvatarElement;
  createOnePupilCredit: PupilCredit;
  updateOnePupilCredit: PupilCredit;
  deleteOnePupilCredit: Success;
  updateOnePupilNotificationLog: PupilNotificationLog;
  updatePupilPassword: Success;
  updatePupilPasswordAnonymous: Success;
  pupilPasswordReset: Success;
  pupilNfcPasswordReset: Success;
  validatePupilTag: Success;
  createOneRegime: Regime;
  updateOneRegime: Regime;
  deleteOneRegime: Success;
  deleteOneRegimeInactivity: Success;
  createOneReward: Reward;
  updateOneReward: Reward;
  deleteOneReward: Success;
  createOneRewardQuantity: RewardQuantity;
  updateOneRewardQuantity: RewardQuantity;
  deleteOneRewardQuantity: Success;
  createOneRewardRequest: RewardRequest;
  markOneRewardRequestAsReceived: RewardRequest;
  createOneSchool: School;
  updateOneSchool: School;
  deleteOneSchool: Success;
  createOneSchoolTrophy: SchoolTrophy;
  updateOneSchoolTrophy: SchoolTrophy;
  deleteOneSchoolTrophy: Success;
  createOneSchoolyear: Schoolyear;
  updateOneSchoolyear: Schoolyear;
  deleteOneSchoolyear: Success;
  createOneShopItem: ShopItem;
  updateOneShopItem: ShopItem;
  createOneShopItemAssignment: ShopItemAssignment;
  updateOneShopItemAssignment: ShopItemAssignment;
  createOneShopItemOrder: ShopItemOrder;
  completeOneShopItemOrder: ShopItemOrder;
  cancelOneShopItemOrder: ShopItemOrder;
  getOneAnonymousToken: TokenResult;
  createOneUser: User;
  updateOneUser: User;
  deleteOneUser: Success;
  updatePassword: Success;
  updatePasswordAnonymous: Success;
  generateOneUsername: Username;
  createOneZone: Zone;
  updateOneZone: Zone;
  deleteOneZone: Success;
};


export type MutationCreateOneAchievementArgs = {
  data: AchievementCreateInput;
};


export type MutationUpdateOneAchievementArgs = {
  id: Scalars['String'];
  data: AchievementUpdateInput;
};


export type MutationDeleteOneAchievementArgs = {
  id: Scalars['String'];
};


export type MutationUploadAssetArgs = {
  data: AssetUploadInput;
};


export type MutationCreateOneCampaignArgs = {
  data: CampaignCreateInput;
};


export type MutationUpdateOneCampaignArgs = {
  id: Scalars['String'];
  data: CampaignUpdateInput;
};


export type MutationDeleteOneCampaignArgs = {
  id: Scalars['String'];
};


export type MutationCreateManyCampaignChecksArgs = {
  data: CampaignCheckCreateManyInput;
};


export type MutationCreateOneChallengeArgs = {
  data: ChallengeCreateInput;
};


export type MutationUpdateOneChallengeArgs = {
  id: Scalars['String'];
  data: ChallengeUpdateInput;
};


export type MutationDeleteOneChallengeArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneClassArgs = {
  data: ClassCreateInput;
};


export type MutationUpdateOneClassArgs = {
  id: Scalars['String'];
  data: ClassUpdateInput;
};


export type MutationDeleteOneClassArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneClassExerciseArgs = {
  data: ClassExerciseCreateInput;
};


export type MutationUpdateOneClassExerciseArgs = {
  id: Scalars['String'];
  data: ClassExerciseUpdateInput;
};


export type MutationDeleteOneClassExerciseArgs = {
  id: Scalars['String'];
};


export type MutationCreateManyClassExercisesArgs = {
  data: ClassExerciseCreateManyInput;
};


export type MutationDeleteClassExercisesArgs = {
  filter?: Maybe<ClassExerciseFilter>;
};


export type MutationDeleteClassExerciseLinksArgs = {
  filter?: Maybe<ClassExerciseLinkFilter>;
};


export type MutationCreateOneClassLibraryArgs = {
  data: ClassLibraryCreateInput;
};


export type MutationUpdateOneClassLibraryArgs = {
  id: Scalars['String'];
  data: ClassLibraryUpdateInput;
};


export type MutationDeleteOneClassLibraryArgs = {
  id: Scalars['String'];
};


export type MutationCreateManyClassLibrariesArgs = {
  data: ClassLibraryCreateManyInput;
};


export type MutationDeleteClassLibrariesArgs = {
  filter?: Maybe<ClassLibraryFilter>;
};


export type MutationCreateOneContentBlockArgs = {
  data: ContentBlockCreateInput;
};


export type MutationUpdateOneContentBlockArgs = {
  id: Scalars['String'];
  data: ContentBlockUpdateInput;
};


export type MutationDeleteOneContentBlockArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneDeviceArgs = {
  data: DeviceCreateInput;
};


export type MutationDeleteOneDeviceArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseArgs = {
  data: ExerciseCreateInput;
};


export type MutationUpdateOneExerciseArgs = {
  id: Scalars['String'];
  data: ExerciseUpdateInput;
};


export type MutationDeleteOneExerciseArgs = {
  id: Scalars['String'];
};


export type MutationCompleteExerciseArgs = {
  id: Scalars['String'];
  answers?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateOneExerciseAnswerArgs = {
  data: ExerciseAnswerCreateInput;
};


export type MutationUpdateOneExerciseAnswerArgs = {
  id: Scalars['String'];
  data: ExerciseAnswerUpdateInput;
};


export type MutationDeleteOneExerciseAnswerArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseCourseArgs = {
  data: ExerciseCourseCreateInput;
};


export type MutationUpdateOneExerciseCourseArgs = {
  id: Scalars['String'];
  data: ExerciseCourseUpdateInput;
};


export type MutationDeleteOneExerciseCourseArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseGradeArgs = {
  data: ExerciseGradeCreateInput;
};


export type MutationUpdateOneExerciseGradeArgs = {
  id: Scalars['String'];
  data: ExerciseGradeUpdateInput;
};


export type MutationDeleteOneExerciseGradeArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseQuestionArgs = {
  data: ExerciseQuestionCreateInput;
};


export type MutationUpdateOneExerciseQuestionArgs = {
  id: Scalars['String'];
  data: ExerciseQuestionUpdateInput;
};


export type MutationDeleteOneExerciseQuestionArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseTagArgs = {
  data: ExerciseTagCreateInput;
};


export type MutationUpdateOneExerciseTagArgs = {
  id: Scalars['String'];
  data: ExerciseTagUpdateInput;
};


export type MutationDeleteOneExerciseTagArgs = {
  id: Scalars['String'];
};


export type MutationForgotPupilDataArgs = {
  email: Scalars['String'];
};


export type MutationSetGameAttributeStatusArgs = {
  data: GameAttributeStatusInput;
};


export type MutationSetGameAttributeLevelArgs = {
  data: GameAttributeLevelInput;
};


export type MutationIncreasePupilCoinArgs = {
  data: DecreasePupilCoinInput;
};


export type MutationDecreasePupilCoinArgs = {
  data: DecreasePupilCoinInput;
};


export type MutationDecreasePupilEnergyArgs = {
  data: DecreasePupilEnergyInput;
};


export type MutationLogGamePupilScoreArgs = {
  data: PupilGameScoreInput;
};


export type MutationCreateOneHazardousLocationArgs = {
  data: HazardousLocationCreateInput;
};


export type MutationUpdateOneHazardousLocationArgs = {
  id: Scalars['String'];
  data: HazardousLocationUpdateInput;
};


export type MutationDeleteOneHazardousLocationArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneHazardousLocationReasonArgs = {
  data: HazardousLocationReasonCreateInput;
};


export type MutationUpdateOneHazardousLocationReasonArgs = {
  id: Scalars['String'];
  data: HazardousLocationReasonUpdateInput;
};


export type MutationDeleteOneHazardousLocationReasonArgs = {
  id: Scalars['String'];
};


export type MutationPupilLoginArgs = {
  classId: Scalars['String'];
  classNumber: Scalars['Int'];
  password: Scalars['String'];
};


export type MutationUsernamePupilLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationNfcPasswordPupilLoginArgs = {
  nfcTagId: Scalars['String'];
  nfcTagType: Scalars['String'];
  password: Scalars['String'];
};


export type MutationNfcPupilLoginArgs = {
  nfcTagId: Scalars['String'];
  nfcTagType: Scalars['String'];
  validationCode: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateOneModuleArgs = {
  data: ModuleCreateInput;
};


export type MutationUpdateOneModuleArgs = {
  id: Scalars['String'];
  data: ModuleUpdateInput;
};


export type MutationSimulateOneModuleRegistrationArgs = {
  data: ModuleRegistrationSimulateInput;
};


export type MutationCreateOneMultiplierArgs = {
  data: MultiplierCreateInput;
};


export type MutationUpdateOneMultiplierArgs = {
  id: Scalars['String'];
  data: MultiplierUpdateInput;
};


export type MutationDeleteOneMultiplierArgs = {
  id: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreateOneProjectArgs = {
  data: ProjectCreateInput;
};


export type MutationUpdateOneProjectArgs = {
  id: Scalars['String'];
  data: ProjectUpdateInput;
};


export type MutationDeleteOneProjectArgs = {
  id: Scalars['String'];
};


export type MutationCreateOnePupilArgs = {
  data: PupilCreateInput;
};


export type MutationUpdateOnePupilArgs = {
  id: Scalars['String'];
  data: PupilUpdateInput;
};


export type MutationDeleteOnePupilArgs = {
  id: Scalars['String'];
};


export type MutationSaveZoneForPupilArgs = {
  pupil: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};


export type MutationValidatePupilClassNumberArgs = {
  data: ValidatePupilClassNumberInput;
};


export type MutationSetOnePupilAvatarElementArgs = {
  input: SetPupilAvatarElementInput;
};


export type MutationCreateOnePupilCreditArgs = {
  data: PupilCreditCreateInput;
};


export type MutationUpdateOnePupilCreditArgs = {
  id: Scalars['String'];
  data: PupilCreditUpdateInput;
};


export type MutationDeleteOnePupilCreditArgs = {
  id: Scalars['String'];
};


export type MutationUpdateOnePupilNotificationLogArgs = {
  id: Scalars['String'];
  data: PupilNotificationLogUpdateInput;
};


export type MutationUpdatePupilPasswordArgs = {
  data: PupilPasswordUpdateInput;
};


export type MutationUpdatePupilPasswordAnonymousArgs = {
  data: PupilPasswordAnonymousUpdateInput;
};


export type MutationPupilPasswordResetArgs = {
  classId: Scalars['String'];
  classNumber: Scalars['Int'];
};


export type MutationPupilNfcPasswordResetArgs = {
  nfcTagId: Scalars['String'];
  nfcTagType: Scalars['String'];
};


export type MutationValidatePupilTagArgs = {
  data: ValidatePupilTagInput;
};


export type MutationCreateOneRegimeArgs = {
  data: RegimeCreateInput;
};


export type MutationUpdateOneRegimeArgs = {
  id: Scalars['String'];
  data: RegimeUpdateInput;
};


export type MutationDeleteOneRegimeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteOneRegimeInactivityArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneRewardArgs = {
  data: RewardCreateInput;
};


export type MutationUpdateOneRewardArgs = {
  id: Scalars['String'];
  data: RewardUpdateInput;
};


export type MutationDeleteOneRewardArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneRewardQuantityArgs = {
  data: RewardQuantityCreateInput;
};


export type MutationUpdateOneRewardQuantityArgs = {
  id: Scalars['String'];
  data: RewardQuantityUpdateInput;
};


export type MutationDeleteOneRewardQuantityArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneRewardRequestArgs = {
  reward: Scalars['String'];
};


export type MutationMarkOneRewardRequestAsReceivedArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneSchoolArgs = {
  data: SchoolCreateInput;
};


export type MutationUpdateOneSchoolArgs = {
  id: Scalars['String'];
  data: SchoolUpdateInput;
};


export type MutationDeleteOneSchoolArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneSchoolTrophyArgs = {
  data: SchoolTrophyCreateInput;
};


export type MutationUpdateOneSchoolTrophyArgs = {
  id: Scalars['String'];
  data: SchoolTrophyUpdateInput;
};


export type MutationDeleteOneSchoolTrophyArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneSchoolyearArgs = {
  data: SchoolyearCreateInput;
};


export type MutationUpdateOneSchoolyearArgs = {
  id: Scalars['String'];
  data: SchoolyearUpdateInput;
};


export type MutationDeleteOneSchoolyearArgs = {
  id: Scalars['String'];
};


export type MutationCreateOneShopItemArgs = {
  input: ShopItemCreateInput;
};


export type MutationUpdateOneShopItemArgs = {
  id: Scalars['String'];
  input: ShopItemUpdateInput;
};


export type MutationCreateOneShopItemAssignmentArgs = {
  input: ShopItemAssignmentCreateInput;
};


export type MutationUpdateOneShopItemAssignmentArgs = {
  id: Scalars['String'];
  input: ShopItemAssignmentUpdateInput;
};


export type MutationCreateOneShopItemOrderArgs = {
  assignedShopItemId: Scalars['String'];
};


export type MutationCompleteOneShopItemOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationCancelOneShopItemOrderArgs = {
  orderId: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  dontUpdateAvailableStock?: Maybe<Scalars['Boolean']>;
};


export type MutationGetOneAnonymousTokenArgs = {
  data: AnonymousTokenInput;
};


export type MutationCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationUpdateOneUserArgs = {
  id: Scalars['String'];
  data?: Maybe<UserUpdateInput>;
};


export type MutationDeleteOneUserArgs = {
  id: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  data: PasswordUpdateInput;
};


export type MutationUpdatePasswordAnonymousArgs = {
  data: PasswordAnonymousUpdateInput;
};


export type MutationCreateOneZoneArgs = {
  data: ZoneCreateInput;
};


export type MutationUpdateOneZoneArgs = {
  id: Scalars['String'];
  data: ZoneUpdateInput;
};


export type MutationDeleteOneZoneArgs = {
  id: Scalars['String'];
};

export enum NotificationChannel {
  Email = 'EMAIL',
  PushNotification = 'PUSH_NOTIFICATION',
  PlatformNotification = 'PLATFORM_NOTIFICATION',
  LocalNotification = 'LOCAL_NOTIFICATION',
  CustomNotification = 'CUSTOM_NOTIFICATION'
}

export enum NotificationSuppressionReason {
  ConfigurationNotEnabled = 'CONFIGURATION_NOT_ENABLED',
  ConfigurationInvalid = 'CONFIGURATION_INVALID',
  CommunicationNotAllowed = 'COMMUNICATION_NOT_ALLOWED',
  TransmitterNotFound = 'TRANSMITTER_NOT_FOUND',
  InternalError = 'INTERNAL_ERROR'
}

export enum NotificationType {
  AchievementLevelCompleted = 'ACHIEVEMENT_LEVEL_COMPLETED',
  MilestoneContentItemUnlocked = 'MILESTONE_CONTENT_ITEM_UNLOCKED',
  MilestoneAvatarElementUnlocked = 'MILESTONE_AVATAR_ELEMENT_UNLOCKED',
  MilestoneCoinsGifted = 'MILESTONE_COINS_GIFTED',
  MilestoneGameAttributeGifted = 'MILESTONE_GAME_ATTRIBUTE_GIFTED',
  RewardRequestSchool = 'REWARD_REQUEST_SCHOOL',
  RewardRequestParent = 'REWARD_REQUEST_PARENT',
  ResetPassword = 'RESET_PASSWORD',
  ResetPupilPassword = 'RESET_PUPIL_PASSWORD',
  ForgotPupilData = 'FORGOT_PUPIL_DATA',
  WelcomePupilParent = 'WELCOME_PUPIL_PARENT',
  ShopitemorderConfirmed = 'SHOPITEMORDER_CONFIRMED',
  ShopitemorderCanceled = 'SHOPITEMORDER_CANCELED',
  RewardsAndUpdatesForChild = 'REWARDS_AND_UPDATES_FOR_CHILD'
}

export type Paging = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PasswordAnonymousUpdateInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordUpdateInput = {
  password: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type Policy = {
  __typename?: 'Policy';
  id: Scalars['String'];
  type: PolicyType;
  version: Scalars['String'];
  url: Scalars['String'];
  required: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type PolicyFilter = {
  id?: Maybe<StringFilter>;
  type?: Maybe<PolicyTypeFilter>;
  version?: Maybe<StringFilter>;
  required?: Maybe<BooleanFilter>;
  active?: Maybe<BooleanFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<PolicyFilter>>;
  OR?: Maybe<Array<PolicyFilter>>;
};

export enum PolicyType {
  DataProcessingAgreement = 'DATA_PROCESSING_AGREEMENT',
  PrivacyPolicy = 'PRIVACY_POLICY',
  Unknown = 'UNKNOWN'
}

export type PolicyTypeFilter = {
  equals?: Maybe<PolicyType>;
  not?: Maybe<PolicyType>;
  in?: Maybe<Array<Maybe<PolicyType>>>;
  notIn?: Maybe<Array<Maybe<PolicyType>>>;
};

export type Profile = {
  __typename?: 'Profile';
  showDailyLoginPopup: Scalars['Boolean'];
  consecutiveDays: Scalars['Int'];
  pupil: Pupil;
  showZoneForm: Scalars['Boolean'];
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  kioskUrl?: Maybe<Scalars['String']>;
  registrationUrl?: Maybe<Scalars['String']>;
  challengesEnabled: Scalars['Boolean'];
  rewardsEnabled: Scalars['Boolean'];
  bikeRegistrationsEnabled: Scalars['Boolean'];
  highFiveRegistrationsEnabled: Scalars['Boolean'];
  fluoRegistrationsEnabled: Scalars['Boolean'];
  zonesEnabled: Scalars['Boolean'];
  challengeIcon: Asset;
  challengeBackground: Asset;
  challengeForeground: Asset;
  image: Asset;
  activeSchoolyear?: Maybe<Schoolyear>;
  schoolyears?: Maybe<Array<Schoolyear>>;
  code?: Maybe<Scalars['String']>;
};

export type ProjectCreateInput = {
  title: Scalars['String'];
  url: Scalars['String'];
  kioskUrl?: Maybe<Scalars['String']>;
  registrationUrl?: Maybe<Scalars['String']>;
  challengesEnabled?: Maybe<Scalars['Boolean']>;
  rewardsEnabled?: Maybe<Scalars['Boolean']>;
  bikeRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  fluoRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  zonesEnabled?: Maybe<Scalars['Boolean']>;
  challengeIcon: Scalars['String'];
  challengeBackground: Scalars['String'];
  challengeForeground: Scalars['String'];
  image: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type ProjectFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  AND?: Maybe<Array<ProjectFilter>>;
  OR?: Maybe<Array<ProjectFilter>>;
};

export type ProjectUpdateInput = {
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  kioskUrl?: Maybe<Scalars['String']>;
  registrationUrl?: Maybe<Scalars['String']>;
  challengesEnabled?: Maybe<Scalars['Boolean']>;
  rewardsEnabled?: Maybe<Scalars['Boolean']>;
  bikeRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  fluoRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  zonesEnabled?: Maybe<Scalars['Boolean']>;
  challengeIcon?: Maybe<Scalars['String']>;
  challengeBackground?: Maybe<Scalars['String']>;
  challengeForeground?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type Pupil = {
  __typename?: 'Pupil';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  class: Class;
  classNumber?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Avatar>;
  pupilTotal?: Maybe<PupilTotal>;
  gamePupilTotal?: Maybe<GamePupilTotal>;
  distance?: Maybe<Scalars['Int']>;
  zone?: Maybe<Zone>;
  noRegistrations: Scalars['Boolean'];
  moduleRegistrations?: Maybe<Array<Maybe<ModuleRegistration>>>;
  moduleRegistrationsCount: Scalars['Int'];
  pupilTags?: Maybe<Array<Maybe<PupilTag>>>;
  username?: Maybe<Username>;
};


export type PupilModuleRegistrationsArgs = {
  filter?: Maybe<ModuleRegistrationReferenceFilter>;
};


export type PupilModuleRegistrationsCountArgs = {
  filter?: Maybe<ModuleRegistrationReferenceFilter>;
};

export type PupilAvatarElement = {
  __typename?: 'PupilAvatarElement';
  id: Scalars['String'];
  type: Scalars['String'];
  avatarElement: AvatarElement;
  color?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
};

export type PupilAvatarElementFilter = {
  id?: Maybe<StringFilter>;
  type?: Maybe<StringFilter>;
  pupilId?: Maybe<StringFilter>;
  AND?: Maybe<Array<PupilAvatarElementFilter>>;
  OR?: Maybe<Array<PupilAvatarElementFilter>>;
};

export type PupilCreateInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  classNumber?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<AvatarCreateInput>;
  noRegistrations?: Maybe<Scalars['Boolean']>;
  pupilTags?: Maybe<Array<Maybe<PupilTagInput>>>;
  usernameId?: Maybe<Scalars['String']>;
  parents?: Maybe<Array<PupilParentInput>>;
};

export type PupilCredit = {
  __typename?: 'PupilCredit';
  id: Scalars['String'];
  type: Scalars['String'];
  credit: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  registration?: Maybe<ModuleRegistration>;
  pupil: Pupil;
  valid: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
};

export type PupilCreditCreateInput = {
  credit: Scalars['Int'];
  pupil: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type PupilCreditFilter = {
  id?: Maybe<StringFilter>;
  pupil?: Maybe<PupilFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  credit?: Maybe<IntFilter>;
  valid?: Maybe<BooleanFilter>;
  AND?: Maybe<Array<PupilCreditFilter>>;
  OR?: Maybe<Array<PupilCreditFilter>>;
};

export enum PupilCreditTimeSeriesGroupBy {
  Class = 'CLASS',
  Pupil = 'PUPIL',
  School = 'SCHOOL'
}

export type PupilCreditUpdateInput = {
  credit?: Maybe<Scalars['Int']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type PupilExerciseAnswer = {
  __typename?: 'PupilExerciseAnswer';
  id: Scalars['String'];
  pupil: Pupil;
  exercise: Exercise;
  question: ExerciseQuestion;
  answer: ExerciseAnswer;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PupilExerciseResult = {
  __typename?: 'PupilExerciseResult';
  id: Scalars['String'];
  pupil: Pupil;
  maxScore: Scalars['Int'];
  score: Scalars['Int'];
  answers?: Maybe<Array<Maybe<PupilExerciseAnswer>>>;
  exercise?: Maybe<Exercise>;
  createdAt: Scalars['DateTime'];
};

export type PupilExerciseResultFilter = {
  id?: Maybe<StringFilter>;
  pupil?: Maybe<PupilFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  score?: Maybe<IntFilter>;
  maxScore?: Maybe<IntFilter>;
  exercise?: Maybe<ExerciseFilter>;
  AND?: Maybe<Array<PupilExerciseResultFilter>>;
  OR?: Maybe<Array<PupilExerciseResultFilter>>;
};

export type PupilFilter = {
  id?: Maybe<StringFilter>;
  class?: Maybe<ClassFilter>;
  classNumber?: Maybe<IntFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  fullName?: Maybe<StringFilter>;
  username?: Maybe<StringFilter>;
  distance?: Maybe<IntFilter>;
  zone?: Maybe<BasicStringFilter>;
  noRegistrations?: Maybe<BooleanFilter>;
  pupilTag?: Maybe<PupilTagFilter>;
  AND?: Maybe<Array<PupilFilter>>;
  OR?: Maybe<Array<PupilFilter>>;
};

export type PupilGameScoreInput = {
  gameId: Scalars['String'];
  score: Scalars['Int'];
};

export type PupilNotificationLog = {
  __typename?: 'PupilNotificationLog';
  id: Scalars['String'];
  type: NotificationType;
  channel: NotificationChannel;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  suppressedAt?: Maybe<Scalars['DateTime']>;
  suppressionReason?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
};

export type PupilNotificationLogFilter = {
  id?: Maybe<StringFilter>;
  pupilId?: Maybe<StringFilter>;
  type?: Maybe<StringFilter>;
  channel?: Maybe<StringFilter>;
  suppressedAt?: Maybe<DateTimeFilter>;
  suppressionReason?: Maybe<StringFilter>;
  readAt?: Maybe<DateTimeFilter>;
  archivedAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<PupilNotificationLogFilter>>;
  OR?: Maybe<Array<PupilNotificationLogFilter>>;
};

export type PupilNotificationLogUpdateInput = {
  readAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
};

export type PupilParentInput = {
  email: Scalars['String'];
  communicationPreferences: Array<UserCommunicationPreferenceInput>;
  acceptedPolicies?: Maybe<Array<AcceptedPolicyInput>>;
};

export type PupilPasswordAnonymousUpdateInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type PupilPasswordUpdateInput = {
  password: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type PupilReferenceFilter = {
  id?: Maybe<StringFilter>;
  class?: Maybe<ClassReferenceFilter>;
  classNumber?: Maybe<IntFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  fullName?: Maybe<StringFilter>;
  username?: Maybe<StringFilter>;
  distance?: Maybe<IntFilter>;
  zone?: Maybe<BasicStringFilter>;
  noRegistrations?: Maybe<BooleanFilter>;
  AND?: Maybe<Array<PupilReferenceFilter>>;
  OR?: Maybe<Array<PupilReferenceFilter>>;
};

export type PupilSearchItem = SearchItem & {
  __typename?: 'PupilSearchItem';
  type: SearchType;
  pupil: Pupil;
};

export type PupilTag = {
  __typename?: 'PupilTag';
  type: Scalars['String'];
  tag: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type PupilTagFilter = {
  type: Scalars['String'];
  tag?: Maybe<StringArrayFilter>;
};

export type PupilTagInput = {
  type: Scalars['String'];
  tag: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type PupilTotal = {
  __typename?: 'PupilTotal';
  id: Scalars['String'];
  pupil: Pupil;
  totalCredits: Scalars['Int'];
  totalRegistrations: Scalars['Int'];
  totalDistance: Scalars['Float'];
  updateAt: Scalars['DateTime'];
};

export type PupilTotalFilter = {
  id?: Maybe<StringFilter>;
  pupil?: Maybe<PupilFilter>;
  totalCredits?: Maybe<IntFilter>;
  totalRegistrations?: Maybe<IntFilter>;
  AND?: Maybe<Array<PupilTotalFilter>>;
  OR?: Maybe<Array<PupilTotalFilter>>;
};

export type PupilUpdateInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  classNumber?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<AvatarCreateInput>;
  noRegistrations?: Maybe<Scalars['Boolean']>;
  pupilTags?: Maybe<Array<Maybe<PupilTagInput>>>;
  parents?: Maybe<Array<PupilParentInput>>;
};

export type Query = {
  __typename?: 'Query';
  achievements: Array<Achievement>;
  achievementsCount: Scalars['Int'];
  achievementLevels: Array<AchievementLevel>;
  achievementLevelPupilStatuses: Array<AchievementLevelPupilStatus>;
  achievementMilestones: Array<AchievementMilestone>;
  achievementMilestonePupilStatuses: Array<AchievementMilestonePupilStatus>;
  airQualities: Array<AirQuality>;
  airQualitiesCount: Scalars['Int'];
  assignedShopItems: Array<AssignedShopItem>;
  avatarElements: Array<AvatarElement>;
  campaigns: Array<Campaign>;
  campaignsCount: Scalars['Int'];
  challenges: Array<Challenge>;
  challengesCount: Scalars['Int'];
  classes: Array<Class>;
  classesCount: Scalars['Int'];
  classExercises: Array<ClassExercise>;
  classExercisesCount: Scalars['Int'];
  classLibraries: Array<ClassLibrary>;
  classLibrariesCount: Scalars['Int'];
  contentBlocks: Array<ContentBlock>;
  contentBlocksCount: Scalars['Int'];
  contentItems: Array<ContentItem>;
  contentTags: Array<ContentTag>;
  devices: Array<Device>;
  devicesCount: Scalars['Int'];
  exercises: Array<Exercise>;
  exercisesCount: Scalars['Int'];
  exerciseCourses: Array<ExerciseCourse>;
  exerciseCoursesCount: Scalars['Int'];
  exerciseGrades: Array<ExerciseGrade>;
  exerciseGradesCount: Scalars['Int'];
  exerciseTags: Array<ExerciseTag>;
  exerciseTagsCount: Scalars['Int'];
  games: Array<Game>;
  gameAttributes: Array<GameAttribute>;
  gameHighScores: Array<GameHighScore>;
  gamePupilTotal?: Maybe<GamePupilTotal>;
  gameScoreClassLeaderboard: Array<GameLeaderboardEntry>;
  gameScoreSchoolLeaderboard: Array<GameLeaderboardEntry>;
  hazardousLocations: Array<HazardousLocation>;
  hazardousLocationsCount: Scalars['Int'];
  hazardousLocationReasons: Array<HazardousLocationReason>;
  hazardousLocationReasonsCount: Scalars['Int'];
  locations: Array<Location>;
  locationsCount: Scalars['Int'];
  modules: Array<Module>;
  modulesCount: Scalars['Int'];
  moduleRegistrations: Array<ModuleRegistration>;
  moduleRegistrationsCount: Scalars['Int'];
  moduleRegistrationTimeSeries: Array<TimeSeries>;
  multipliers: Array<Multiplier>;
  multipliersCount: Scalars['Int'];
  policies: Array<Policy>;
  profile: Profile;
  projects: Array<Project>;
  projectsCount: Scalars['Int'];
  pupils: Array<Pupil>;
  pupilsCount: Scalars['Int'];
  pupilAvatarElements: Array<PupilAvatarElement>;
  pupilCredits: Array<PupilCredit>;
  pupilCreditsCount: Scalars['Int'];
  pupilCreditTimeSeries: Array<TimeSeries>;
  pupilExerciseResults: Array<PupilExerciseResult>;
  pupilExerciseResultsCount: Scalars['Int'];
  pupilNotificationLogs: Array<PupilNotificationLog>;
  pupilNotificationLogsCount: Scalars['Int'];
  pupilTotals: Array<PupilTotal>;
  pupilTotalsCount: Scalars['Int'];
  rawModuleMessages: Array<RawModuleMessage>;
  rawModuleMessagesCount: Scalars['Int'];
  regimes: Array<Regime>;
  regimesCount: Scalars['Int'];
  rewards: Array<Reward>;
  rewardsCount: Scalars['Int'];
  rewardQuantities: Array<RewardQuantity>;
  rewardQuantitiesCount: Scalars['Int'];
  rewardRequests: Array<RewardRequest>;
  rewardRequestsCount: Scalars['Int'];
  schools: Array<School>;
  schoolsCount: Scalars['Int'];
  schoolStatistics: Array<SchoolStatistics>;
  schoolTrophies: Array<SchoolTrophy>;
  schoolTrophiesCount: Scalars['Int'];
  schoolyears: Array<Schoolyear>;
  schoolyearsCount: Scalars['Int'];
  search: SearchResult;
  shopItems: Array<ShopItem>;
  shopItemAssignments: Array<ShopItemAssignment>;
  shopItemOrders: Array<ShopItemOrder>;
  shopItemTypes: Array<ShopItemType>;
  users: Array<User>;
  userCount: Scalars['Int'];
  zones: Array<Zone>;
  zonesCount: Scalars['Int'];
};


export type QueryAchievementsArgs = {
  filter?: Maybe<AchievementFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryAchievementsCountArgs = {
  filter?: Maybe<AchievementFilter>;
};


export type QueryAchievementLevelsArgs = {
  filter?: Maybe<AchievementLevelFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryAchievementLevelPupilStatusesArgs = {
  filter?: Maybe<AchievementLevelPupilStatusFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryAchievementMilestonesArgs = {
  filter?: Maybe<AchievementMilestoneFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryAchievementMilestonePupilStatusesArgs = {
  filter?: Maybe<AchievementMilestonePupilStatusFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryAirQualitiesArgs = {
  filter?: Maybe<AirQualityFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryAirQualitiesCountArgs = {
  filter?: Maybe<AirQualityFilter>;
};


export type QueryAssignedShopItemsArgs = {
  filter?: Maybe<AssignedShopItemFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryAvatarElementsArgs = {
  filter?: Maybe<AvatarElementFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryCampaignsArgs = {
  filter?: Maybe<CampaignFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryCampaignsCountArgs = {
  filter?: Maybe<CampaignFilter>;
};


export type QueryChallengesArgs = {
  filter?: Maybe<ChallengeFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryChallengesCountArgs = {
  filter?: Maybe<ChallengeFilter>;
};


export type QueryClassesArgs = {
  filter?: Maybe<ClassFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryClassesCountArgs = {
  filter?: Maybe<ClassFilter>;
};


export type QueryClassExercisesArgs = {
  filter?: Maybe<ClassExerciseFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryClassExercisesCountArgs = {
  filter?: Maybe<ClassExerciseFilter>;
};


export type QueryClassLibrariesArgs = {
  filter?: Maybe<ClassLibraryFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryClassLibrariesCountArgs = {
  filter?: Maybe<ClassLibraryFilter>;
};


export type QueryContentBlocksArgs = {
  filter?: Maybe<ContentBlockFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryContentBlocksCountArgs = {
  filter?: Maybe<ContentBlockFilter>;
};


export type QueryContentItemsArgs = {
  filter?: Maybe<ContentItemFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryContentTagsArgs = {
  filter?: Maybe<ContentTagFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryDevicesArgs = {
  filter?: Maybe<DeviceFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryDevicesCountArgs = {
  filter?: Maybe<DeviceFilter>;
};


export type QueryExercisesArgs = {
  filter?: Maybe<ExerciseFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryExercisesCountArgs = {
  filter?: Maybe<ExerciseFilter>;
};


export type QueryExerciseCoursesArgs = {
  filter?: Maybe<ExerciseCourseFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryExerciseCoursesCountArgs = {
  filter?: Maybe<ExerciseCourseFilter>;
};


export type QueryExerciseGradesArgs = {
  filter?: Maybe<ExerciseGradeFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryExerciseGradesCountArgs = {
  filter?: Maybe<ExerciseGradeFilter>;
};


export type QueryExerciseTagsArgs = {
  filter?: Maybe<ExerciseTagFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryExerciseTagsCountArgs = {
  filter?: Maybe<ExerciseTagFilter>;
};


export type QueryGamesArgs = {
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryGameAttributesArgs = {
  filter?: Maybe<GameAttributeFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryGameHighScoresArgs = {
  filter?: Maybe<GameHighScoreFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryGamePupilTotalArgs = {
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryGameScoreClassLeaderboardArgs = {
  filter?: Maybe<GameScoreClassLeaderboardFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryGameScoreSchoolLeaderboardArgs = {
  filter?: Maybe<GameScoreSchoolLeaderboardFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryHazardousLocationsArgs = {
  filter?: Maybe<HazardousLocationFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryHazardousLocationsCountArgs = {
  filter?: Maybe<HazardousLocationFilter>;
};


export type QueryHazardousLocationReasonsArgs = {
  filter?: Maybe<HazardousLocationReasonFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryHazardousLocationReasonsCountArgs = {
  filter?: Maybe<HazardousLocationReasonFilter>;
};


export type QueryLocationsArgs = {
  filter?: Maybe<LocationFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryLocationsCountArgs = {
  filter?: Maybe<LocationFilter>;
};


export type QueryModulesArgs = {
  filter?: Maybe<ModuleFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryModulesCountArgs = {
  filter?: Maybe<ModuleFilter>;
};


export type QueryModuleRegistrationsArgs = {
  filter?: Maybe<ModuleRegistrationFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryModuleRegistrationsCountArgs = {
  filter?: Maybe<ModuleRegistrationFilter>;
};


export type QueryModuleRegistrationTimeSeriesArgs = {
  bucketWidth: Scalars['String'];
  groupBy: ModuleRegistrationTimeSeriesGroupBy;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  filter?: Maybe<ModuleRegistrationFilter>;
};


export type QueryMultipliersArgs = {
  filter?: Maybe<MultiplierFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryMultipliersCountArgs = {
  filter?: Maybe<MultiplierFilter>;
};


export type QueryPoliciesArgs = {
  filter?: Maybe<PolicyFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryProjectsArgs = {
  filter?: Maybe<ProjectFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryProjectsCountArgs = {
  filter?: Maybe<ProjectFilter>;
};


export type QueryPupilsArgs = {
  filter?: Maybe<PupilFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryPupilsCountArgs = {
  filter?: Maybe<PupilFilter>;
};


export type QueryPupilAvatarElementsArgs = {
  filter?: Maybe<AvatarElementFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryPupilCreditsArgs = {
  filter?: Maybe<PupilCreditFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryPupilCreditsCountArgs = {
  filter?: Maybe<PupilCreditFilter>;
};


export type QueryPupilCreditTimeSeriesArgs = {
  bucketWidth: Scalars['String'];
  groupBy: PupilCreditTimeSeriesGroupBy;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  filter?: Maybe<PupilCreditFilter>;
};


export type QueryPupilExerciseResultsArgs = {
  filter?: Maybe<PupilExerciseResultFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryPupilExerciseResultsCountArgs = {
  filter?: Maybe<PupilExerciseResultFilter>;
};


export type QueryPupilNotificationLogsArgs = {
  filter?: Maybe<PupilNotificationLogFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryPupilNotificationLogsCountArgs = {
  filter?: Maybe<PupilNotificationLogFilter>;
};


export type QueryPupilTotalsArgs = {
  filter?: Maybe<PupilTotalFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryPupilTotalsCountArgs = {
  filter?: Maybe<PupilTotalFilter>;
};


export type QueryRawModuleMessagesArgs = {
  filter?: Maybe<RawModuleMessageFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryRawModuleMessagesCountArgs = {
  filter?: Maybe<RawModuleMessageFilter>;
};


export type QueryRegimesArgs = {
  filter?: Maybe<RegimeFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryRegimesCountArgs = {
  filter?: Maybe<RegimeFilter>;
};


export type QueryRewardsArgs = {
  filter?: Maybe<RewardFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryRewardsCountArgs = {
  filter?: Maybe<RewardFilter>;
};


export type QueryRewardQuantitiesArgs = {
  filter?: Maybe<RewardQuantityFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryRewardQuantitiesCountArgs = {
  filter?: Maybe<RewardQuantityFilter>;
};


export type QueryRewardRequestsArgs = {
  filter?: Maybe<RewardRequestFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryRewardRequestsCountArgs = {
  filter?: Maybe<RewardRequestFilter>;
};


export type QuerySchoolsArgs = {
  filter?: Maybe<SchoolFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QuerySchoolsCountArgs = {
  filter?: Maybe<SchoolFilter>;
};


export type QuerySchoolStatisticsArgs = {
  filter?: Maybe<SchoolStatisticsFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QuerySchoolTrophiesArgs = {
  filter?: Maybe<SchoolTrophyFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QuerySchoolTrophiesCountArgs = {
  filter?: Maybe<SchoolTrophyFilter>;
};


export type QuerySchoolyearsArgs = {
  filter?: Maybe<SchoolyearFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QuerySchoolyearsCountArgs = {
  filter?: Maybe<SchoolyearFilter>;
};


export type QuerySearchArgs = {
  search: Scalars['String'];
  filter?: Maybe<SearchFilter>;
};


export type QueryShopItemsArgs = {
  filter?: Maybe<ShopItemFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryShopItemAssignmentsArgs = {
  filter?: Maybe<ShopItemAssignmentFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryShopItemOrdersArgs = {
  filter?: Maybe<ShopItemOrderFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryShopItemTypesArgs = {
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryUsersArgs = {
  filter?: Maybe<UserFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryUserCountArgs = {
  filter?: Maybe<UserFilter>;
};


export type QueryZonesArgs = {
  filter?: Maybe<ZoneFilter>;
  paging?: Maybe<Paging>;
  sort?: Maybe<Array<Maybe<Sort>>>;
};


export type QueryZonesCountArgs = {
  filter?: Maybe<ZoneFilter>;
};

export type RawModuleMessage = {
  __typename?: 'RawModuleMessage';
  id: Scalars['String'];
  deviceId: Scalars['String'];
  data: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  receivedAt: Scalars['DateTime'];
};

export type RawModuleMessageDataFilter = {
  nfcId?: Maybe<StringFilter>;
};

export type RawModuleMessageFilter = {
  id?: Maybe<StringFilter>;
  deviceId?: Maybe<StringFilter>;
  data?: Maybe<RawModuleMessageDataFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  receivedAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<RawModuleMessageFilter>>;
  OR?: Maybe<Array<RawModuleMessageFilter>>;
};

export type Regime = {
  __typename?: 'Regime';
  id: Scalars['String'];
  title: Scalars['String'];
  days: Array<RegimeDay>;
  inactivities: Array<RegimeInactivity>;
  type: RegimeType;
  project?: Maybe<Project>;
  template?: Maybe<Regime>;
  overrideTemplateDays?: Maybe<Scalars['Boolean']>;
};

export type RegimeCreateInput = {
  title: Scalars['String'];
  days: Array<RegimeDayInput>;
  inactivities: Array<RegimeInactivityInput>;
  project?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  overrideTemplateDays?: Maybe<Scalars['Boolean']>;
};

export type RegimeDay = {
  __typename?: 'RegimeDay';
  id: Scalars['String'];
  weekDay: Scalars['Int'];
  hours: Array<RegimeHour>;
};

export type RegimeDayInput = {
  id?: Maybe<Scalars['String']>;
  weekDay: Scalars['Int'];
  hours: Array<RegimeHourInput>;
};

export type RegimeFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<RegimeTypeFilter>;
  project?: Maybe<BasicStringFilter>;
  template?: Maybe<BasicStringFilter>;
  AND?: Maybe<Array<RegimeFilter>>;
  OR?: Maybe<Array<RegimeFilter>>;
};

export type RegimeHour = {
  __typename?: 'RegimeHour';
  id: Scalars['String'];
  fromHours: Scalars['Int'];
  toHours: Scalars['Int'];
};

export type RegimeHourInput = {
  id?: Maybe<Scalars['String']>;
  fromHours: Scalars['Int'];
  toHours: Scalars['Int'];
};

export type RegimeInactivity = {
  __typename?: 'RegimeInactivity';
  id: Scalars['String'];
  title: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  appliedForAllSchools: Scalars['Boolean'];
  schools?: Maybe<Array<School>>;
};

export type RegimeInactivityInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  appliedForAllSchools?: Maybe<Scalars['Boolean']>;
  schools?: Maybe<Array<Scalars['String']>>;
};

export enum RegimeType {
  Global = 'GLOBAL',
  FromTemplate = 'FROM_TEMPLATE'
}

export type RegimeTypeFilter = {
  equals?: Maybe<RegimeType>;
  not?: Maybe<RegimeType>;
  in?: Maybe<Array<Maybe<RegimeType>>>;
  notIn?: Maybe<Array<Maybe<RegimeType>>>;
};

export type RegimeUpdateInput = {
  title?: Maybe<Scalars['String']>;
  days?: Maybe<Array<RegimeDayInput>>;
  inactivities?: Maybe<Array<RegimeInactivityInput>>;
  template?: Maybe<Scalars['String']>;
  overrideTemplateDays?: Maybe<Scalars['Boolean']>;
};

export type Reward = {
  __typename?: 'Reward';
  id: Scalars['String'];
  title: Scalars['String'];
  isRewardedMultipleTimes: Scalars['Boolean'];
  availableFrom: Scalars['DateTime'];
  availableTo: Scalars['DateTime'];
  collectInstructions: Scalars['String'];
  image: Asset;
  rewardAssignments: Array<RewardAssignment>;
  valueInCredits: Scalars['Int'];
  rewardRequests?: Maybe<Array<Maybe<RewardRequest>>>;
  rewardRequestsCount: Scalars['Int'];
  rewardQuantities: Array<RewardQuantity>;
  schoolyear: Schoolyear;
  schoolSpecific: Scalars['Boolean'];
};


export type RewardRewardRequestsArgs = {
  filter?: Maybe<RewardRequestReferenceFilter>;
};


export type RewardRewardRequestsCountArgs = {
  filter?: Maybe<RewardRequestReferenceFilter>;
};

export type RewardAssignment = {
  __typename?: 'RewardAssignment';
  id: Scalars['String'];
  email: Array<Scalars['String']>;
  reward: Reward;
  school: School;
  userId: Array<Scalars['String']>;
  amount: Scalars['Int'];
  overrideCollectInstructions: Scalars['Boolean'];
  collectInstructions?: Maybe<Scalars['String']>;
  overrideRewardedMultipleTimes: Scalars['Boolean'];
  isRewardedMultipleTimes?: Maybe<Scalars['Boolean']>;
  overrideValueInCredits: Scalars['Boolean'];
  valueInCredits?: Maybe<Scalars['Int']>;
};

export type RewardAssignmentCreateInput = {
  email: Array<Scalars['String']>;
  school: Scalars['String'];
  userId: Array<Scalars['String']>;
  amount: Scalars['Int'];
  overrideCollectInstructions: Scalars['Boolean'];
  collectInstructions?: Maybe<Scalars['String']>;
  overrideRewardedMultipleTimes: Scalars['Boolean'];
  isRewardedMultipleTimes?: Maybe<Scalars['Boolean']>;
  overrideValueInCredits: Scalars['Boolean'];
  valueInCredits?: Maybe<Scalars['Int']>;
};

export type RewardCreateInput = {
  title: Scalars['String'];
  isRewardedMultipleTimes: Scalars['Boolean'];
  availableFrom: Scalars['DateTime'];
  availableTo: Scalars['DateTime'];
  collectInstructions: Scalars['String'];
  image: Scalars['String'];
  rewardAssignments?: Maybe<Array<Maybe<RewardAssignmentCreateInput>>>;
  valueInCredits: Scalars['Int'];
  schoolyear: Scalars['String'];
};

export type RewardFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  availableFrom?: Maybe<DateTimeFilter>;
  availableTo?: Maybe<DateTimeFilter>;
  schoolSpecific?: Maybe<BooleanFilter>;
  schoolyear?: Maybe<SchoolyearFilter>;
  school?: Maybe<BasicStringFilter>;
  AND?: Maybe<Array<RewardFilter>>;
  OR?: Maybe<Array<RewardFilter>>;
};

export type RewardQuantity = {
  __typename?: 'RewardQuantity';
  id: Scalars['String'];
  type: RewardQuantityType;
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  reward: Reward;
  school: School;
};

export type RewardQuantityChangeCreateInput = {
  type: RewardQuantityChangeType;
  amount: Scalars['Int'];
};

export enum RewardQuantityChangeType {
  AdditionalOrder = 'ADDITIONAL_ORDER',
  Recount = 'RECOUNT'
}

export type RewardQuantityCreateInput = {
  type: RewardQuantityType;
  amount: Scalars['Int'];
  reward: Scalars['String'];
  school: Scalars['String'];
};

export type RewardQuantityFilter = {
  id?: Maybe<StringFilter>;
  reward?: Maybe<BasicStringFilter>;
  school?: Maybe<BasicStringFilter>;
  AND?: Maybe<Array<RewardQuantityFilter>>;
  OR?: Maybe<Array<RewardQuantityFilter>>;
};

export enum RewardQuantityType {
  Initial = 'INITIAL',
  AdditionalOrder = 'ADDITIONAL_ORDER',
  Recount = 'RECOUNT'
}

export type RewardQuantityUpdateInput = {
  amount?: Maybe<Scalars['Int']>;
};

export type RewardRequest = {
  __typename?: 'RewardRequest';
  id: Scalars['String'];
  issuedBy?: Maybe<Scalars['String']>;
  issuedByName?: Maybe<Scalars['String']>;
  pupil: Pupil;
  receivedAt?: Maybe<Scalars['DateTime']>;
  requestedAt: Scalars['DateTime'];
  reward: Reward;
};

export type RewardRequestFilter = {
  id?: Maybe<StringFilter>;
  pupil?: Maybe<PupilFilter>;
  reward?: Maybe<BasicStringFilter>;
  receivedAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<RewardRequestFilter>>;
  OR?: Maybe<Array<RewardRequestFilter>>;
};

export type RewardRequestReferenceFilter = {
  id?: Maybe<StringFilter>;
  pupil?: Maybe<PupilReferenceFilter>;
  reward?: Maybe<BasicStringFilter>;
  receivedAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<RewardRequestReferenceFilter>>;
  OR?: Maybe<Array<RewardRequestReferenceFilter>>;
};

export type RewardSearchItem = SearchItem & {
  __typename?: 'RewardSearchItem';
  type: SearchType;
  reward: Reward;
};

export type RewardUpdateInput = {
  title?: Maybe<Scalars['String']>;
  isRewardedMultipleTimes?: Maybe<Scalars['Boolean']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  collectInstructions?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  rewardAssignments?: Maybe<Array<Maybe<RewardAssignmentCreateInput>>>;
  valueInCredits?: Maybe<Scalars['Int']>;
};

export enum Role {
  Admin = 'ADMIN',
  ProjectManager = 'PROJECT_MANAGER',
  Principal = 'PRINCIPAL',
  Teacher = 'TEACHER',
  Parent = 'PARENT',
  Installer = 'INSTALLER'
}

export type School = {
  __typename?: 'School';
  id: Scalars['String'];
  title: Scalars['String'];
  location: Location;
  classes: Array<Class>;
  schoolyear: Schoolyear;
  schoolTotal: SchoolTotal;
  bikeRegistrationsEnabled: Scalars['Boolean'];
  highFiveRegistrationsEnabled: Scalars['Boolean'];
  fluoRegistrationsEnabled: Scalars['Boolean'];
  zonesEnabled: Scalars['Boolean'];
  radius: Scalars['Int'];
  logo?: Maybe<Asset>;
  website?: Maybe<Scalars['String']>;
  pupilsCount: Scalars['Int'];
  moduleRegistrationsCount: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
};


export type SchoolModuleRegistrationsCountArgs = {
  filter?: Maybe<ModuleRegistrationReferenceFilter>;
};

export type SchoolCreateInput = {
  title: Scalars['String'];
  location: LocationCreateInput;
  schoolyear: Scalars['String'];
  bikeRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  fluoRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  zonesEnabled?: Maybe<Scalars['Boolean']>;
  radius: Scalars['Int'];
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type SchoolFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  schoolyear?: Maybe<SchoolyearFilter>;
  code?: Maybe<StringFilter>;
  AND?: Maybe<Array<SchoolFilter>>;
  OR?: Maybe<Array<SchoolFilter>>;
};

export type SchoolReferenceFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  schoolyear?: Maybe<SchoolyearReferenceFilter>;
  AND?: Maybe<Array<SchoolReferenceFilter>>;
  OR?: Maybe<Array<SchoolReferenceFilter>>;
};

export type SchoolSearchItem = SearchItem & {
  __typename?: 'SchoolSearchItem';
  type: SearchType;
  school: School;
};

export type SchoolStatistics = {
  __typename?: 'SchoolStatistics';
  school: School;
  pupilParticipationRate: Scalars['Float'];
};

export type SchoolStatisticsFilter = {
  school?: Maybe<SchoolFilter>;
  AND?: Maybe<Array<SchoolStatisticsFilter>>;
  OR?: Maybe<Array<SchoolStatisticsFilter>>;
};

export type SchoolTotal = {
  __typename?: 'SchoolTotal';
  id: Scalars['String'];
  school: School;
  totalCredits: Scalars['Int'];
  totalRegistrations: Scalars['Int'];
  totalDistance: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  totalCO2: Scalars['Float'];
  totalFuel: Scalars['Float'];
};

export type SchoolTrophy = {
  __typename?: 'SchoolTrophy';
  id: Scalars['String'];
  title: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  school: School;
  statistics: Array<SchoolTrophyStatistics>;
};

export type SchoolTrophyCreateInput = {
  title: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  school: Scalars['String'];
};

export type SchoolTrophyFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  school?: Maybe<SchoolFilter>;
  AND?: Maybe<Array<SchoolTrophyFilter>>;
  OR?: Maybe<Array<SchoolTrophyFilter>>;
};

export type SchoolTrophyStatistics = {
  __typename?: 'SchoolTrophyStatistics';
  class: Class;
  averageModuleRegistrations: Scalars['Float'];
};

export type SchoolTrophyUpdateInput = {
  title?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type SchoolUpdateInput = {
  title?: Maybe<Scalars['String']>;
  location?: Maybe<LocationCreateInput>;
  schoolyear?: Maybe<Scalars['String']>;
  bikeRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  fluoRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  zonesEnabled?: Maybe<Scalars['Boolean']>;
  radius?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type Schoolyear = {
  __typename?: 'Schoolyear';
  id: Scalars['String'];
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  projectGoal: Scalars['Int'];
  pupilGoal: Scalars['Int'];
  title: Scalars['String'];
  active: Scalars['Boolean'];
  project: Project;
  contentBlocks?: Maybe<Array<Maybe<ContentBlock>>>;
  schools: Array<School>;
  schoolyearTotal: SchoolyearTotal;
};

export type SchoolyearCreateInput = {
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  projectGoal: Scalars['Int'];
  pupilGoal: Scalars['Int'];
  title: Scalars['String'];
  project: Scalars['String'];
};

export type SchoolyearFilter = {
  id?: Maybe<StringFilter>;
  project?: Maybe<BasicStringFilter>;
  active?: Maybe<BooleanFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<SchoolyearFilter>>;
  OR?: Maybe<Array<SchoolyearFilter>>;
};

export type SchoolyearReferenceFilter = {
  id?: Maybe<StringFilter>;
  project?: Maybe<BasicStringFilter>;
  active?: Maybe<BooleanFilter>;
  startsAt?: Maybe<DateTimeFilter>;
  endsAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<SchoolyearReferenceFilter>>;
  OR?: Maybe<Array<SchoolyearReferenceFilter>>;
};

export type SchoolyearTotal = {
  __typename?: 'SchoolyearTotal';
  id: Scalars['String'];
  schoolyear: Schoolyear;
  totalCredits: Scalars['Int'];
  totalRegistrations: Scalars['Int'];
  totalDistance: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  totalCO2: Scalars['Float'];
  totalFuel: Scalars['Float'];
  averageFuelPerPupilPerMonth: Scalars['Float'];
};

export type SchoolyearUpdateInput = {
  endsAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  projectGoal?: Maybe<Scalars['Int']>;
  pupilGoal?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};

export type SearchFilter = {
  types?: Maybe<Array<SearchType>>;
};

export type SearchItem = {
  type: SearchType;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  countByType: Array<SearchResultCountByType>;
  items: Array<SearchItem>;
};

export type SearchResultCountByType = {
  __typename?: 'SearchResultCountByType';
  type: SearchType;
  count: Scalars['Int'];
};

export enum SearchType {
  Pupil = 'PUPIL',
  Class = 'CLASS',
  School = 'SCHOOL',
  Challenge = 'CHALLENGE',
  Reward = 'REWARD',
  Exercise = 'EXERCISE'
}

export type SetPupilAvatarElementInput = {
  type: Scalars['String'];
  elementId: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};

export type ShopItem = {
  __typename?: 'ShopItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  image: Asset;
  price: Scalars['Int'];
  purchasedAmount?: Maybe<Scalars['Int']>;
  physicalStock?: Maybe<Scalars['Int']>;
  availableStock: Scalars['Int'];
  deliveredStock: Scalars['Int'];
  openRequestCount: Scalars['Int'];
  orderRestrictions: ShopItemOrderRestrictions;
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
  type: ShopItemType;
  assignments: Array<ShopItemAssignment>;
  createdAt: Scalars['DateTime'];
};

export type ShopItemAssignment = {
  __typename?: 'ShopItemAssignment';
  id: Scalars['String'];
  shopItem: ShopItem;
  project: Project;
  school?: Maybe<School>;
  class?: Maybe<Class>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  price?: Maybe<Scalars['Int']>;
  purchasedAmount?: Maybe<Scalars['Int']>;
  physicalStock?: Maybe<Scalars['Int']>;
  availableStock?: Maybe<Scalars['Int']>;
  deliveredStock: Scalars['Int'];
  openRequestCount: Scalars['Int'];
  orderRestrictions?: Maybe<ShopItemOrderRestrictions>;
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
};

export type ShopItemAssignmentCreateInput = {
  shopItemId: Scalars['String'];
  projectId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  titles?: Maybe<Array<MultiLanguageTextInput>>;
  descriptions?: Maybe<Array<MultiLanguageTextInput>>;
  instructions?: Maybe<Array<MultiLanguageTextInput>>;
  image?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  purchasedAmount?: Maybe<Scalars['Int']>;
  physicalStock?: Maybe<Scalars['Int']>;
  availableStock?: Maybe<Scalars['Int']>;
  deliveredStock?: Maybe<Scalars['Int']>;
  openRequestCount?: Maybe<Scalars['Int']>;
  orderRestrictions?: Maybe<ShopItemOrderRestrictionsInput>;
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
};

export type ShopItemAssignmentFilter = {
  id?: Maybe<StringFilter>;
  shopItem?: Maybe<StringFilter>;
  project?: Maybe<ProjectFilter>;
  school?: Maybe<SchoolFilter>;
  class?: Maybe<ClassFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  instructions?: Maybe<StringFilter>;
  price?: Maybe<IntFilter>;
  publishOn?: Maybe<DateTimeFilter>;
  publishUntil?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<ShopItemFilter>>;
  OR?: Maybe<Array<ShopItemFilter>>;
};

export type ShopItemAssignmentUpdateInput = {
  shopItemId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  titles?: Maybe<Array<MultiLanguageTextInput>>;
  descriptions?: Maybe<Array<MultiLanguageTextInput>>;
  instructions?: Maybe<Array<MultiLanguageTextInput>>;
  image?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  purchasedAmount?: Maybe<Scalars['Int']>;
  physicalStock?: Maybe<Scalars['Int']>;
  availableStock?: Maybe<Scalars['Int']>;
  deliveredStock?: Maybe<Scalars['Int']>;
  openRequestCount?: Maybe<Scalars['Int']>;
  orderRestrictions?: Maybe<ShopItemOrderRestrictionsInput>;
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
};

export type ShopItemCreateInput = {
  titles?: Maybe<Array<MultiLanguageTextInput>>;
  descriptions?: Maybe<Array<MultiLanguageTextInput>>;
  instructions?: Maybe<Array<MultiLanguageTextInput>>;
  image: Scalars['String'];
  price: Scalars['Int'];
  purchasedAmount?: Maybe<Scalars['Int']>;
  physicalStock?: Maybe<Scalars['Int']>;
  availableStock: Scalars['Int'];
  deliveredStock: Scalars['Int'];
  openRequestCount?: Maybe<Scalars['Int']>;
  orderRestrictions: ShopItemOrderRestrictionsInput;
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
};

export type ShopItemFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  instructions?: Maybe<StringFilter>;
  price?: Maybe<IntFilter>;
  publishOn?: Maybe<DateTimeFilter>;
  publishUntil?: Maybe<DateTimeFilter>;
  project?: Maybe<ProjectFilter>;
  school?: Maybe<SchoolFilter>;
  class?: Maybe<ClassFilter>;
  AND?: Maybe<Array<ShopItemFilter>>;
  OR?: Maybe<Array<ShopItemFilter>>;
};

export type ShopItemOrder = {
  __typename?: 'ShopItemOrder';
  id: Scalars['String'];
  shopItem?: Maybe<AssignedShopItem>;
  price?: Maybe<Scalars['Int']>;
  orderedAt: Scalars['DateTime'];
  deliveredAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  canceledAt?: Maybe<Scalars['DateTime']>;
  voucher?: Maybe<ShopItemVoucher>;
  code?: Maybe<ShopItemVoucherCode>;
  pupil?: Maybe<Pupil>;
};

export type ShopItemOrderFilter = {
  id?: Maybe<StringFilter>;
  project?: Maybe<ProjectFilter>;
  school?: Maybe<SchoolFilter>;
  class?: Maybe<ClassFilter>;
  pupil?: Maybe<PupilFilter>;
  shopItem?: Maybe<ShopItemFilter>;
  shopItemAssignment?: Maybe<ShopItemAssignmentFilter>;
  assignedShopItemId?: Maybe<StringFilter>;
  price?: Maybe<IntFilter>;
  orderedAt?: Maybe<DateTimeFilter>;
  deliveredAt?: Maybe<DateTimeFilter>;
  canceledAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<ShopItemOrderFilter>>;
  OR?: Maybe<Array<ShopItemOrderFilter>>;
};

export type ShopItemOrderRestrictions = {
  __typename?: 'ShopItemOrderRestrictions';
  id: Scalars['String'];
  enableMaxItemsPerPerson: Scalars['Boolean'];
  maxItemsPerPerson?: Maybe<Scalars['Int']>;
};

export type ShopItemOrderRestrictionsInput = {
  enableMaxItemsPerPerson: Scalars['Boolean'];
  maxItemsPerPerson?: Maybe<Scalars['Int']>;
};

export type ShopItemType = {
  __typename?: 'ShopItemType';
  id: Scalars['String'];
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type ShopItemUpdateInput = {
  titles?: Maybe<Array<MultiLanguageTextInput>>;
  descriptions?: Maybe<Array<MultiLanguageTextInput>>;
  instructions?: Maybe<Array<MultiLanguageTextInput>>;
  image?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  purchasedAmount?: Maybe<Scalars['Int']>;
  physicalStock?: Maybe<Scalars['Int']>;
  availableStock?: Maybe<Scalars['Int']>;
  deliveredStock?: Maybe<Scalars['Int']>;
  openRequestCount?: Maybe<Scalars['Int']>;
  orderRestrictions?: Maybe<ShopItemOrderRestrictionsInput>;
  publishOn?: Maybe<Scalars['DateTime']>;
  publishUntil?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
};

export type ShopItemVoucher = {
  __typename?: 'ShopItemVoucher';
  id: Scalars['String'];
  instructions?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type ShopItemVoucherCode = {
  __typename?: 'ShopItemVoucherCode';
  id: Scalars['String'];
  voucher: ShopItemVoucher;
  code: Scalars['String'];
  used: Scalars['Boolean'];
  order?: Maybe<ShopItemOrder>;
  ticket?: Maybe<ShopItemVoucherCodeTicket>;
  createdAt: Scalars['DateTime'];
};

export type ShopItemVoucherCodeTicket = {
  __typename?: 'ShopItemVoucherCodeTicket';
  id: Scalars['String'];
  url: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type Sort = {
  field: Scalars['String'];
  order: SortOrder;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringArrayFilter = {
  containsSome?: Maybe<Array<Maybe<Scalars['String']>>>;
  containsAll?: Maybe<Array<Maybe<Scalars['String']>>>;
  equals?: Maybe<Array<Maybe<Scalars['String']>>>;
  someContains?: Maybe<Scalars['String']>;
  allContains?: Maybe<Scalars['String']>;
  someStartsWith?: Maybe<Scalars['String']>;
  allStartsWith?: Maybe<Scalars['String']>;
  someEndsWith?: Maybe<Scalars['String']>;
  allEndsWith?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  not?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type SubmitResult = {
  __typename?: 'SubmitResult';
  amountOfQuestions: Scalars['Int'];
  amountCorrect: Scalars['Int'];
  coinsEarned: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  rawModuleMessageCreated?: Maybe<RawModuleMessage>;
};


export type SubscriptionRawModuleMessageCreatedArgs = {
  filter?: Maybe<RawModuleMessageFilter>;
};

export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};


export type TimeSeries = {
  __typename?: 'TimeSeries';
  id: Scalars['String'];
  name: Scalars['String'];
  columns: Array<Scalars['String']>;
  points: Array<TimeSeriesPoint>;
};

export type TimeSeriesPoint = {
  __typename?: 'TimeSeriesPoint';
  values: Array<Scalars['String']>;
};

export type TimeSeriesResult = {
  __typename?: 'TimeSeriesResult';
  timestamps?: Maybe<Array<Maybe<Scalars['String']>>>;
  series?: Maybe<Array<Maybe<TimeSeries>>>;
};

export type TokenResult = {
  __typename?: 'TokenResult';
  token?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  emailValidated?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<UserRole>>;
  lockedUntil?: Maybe<Scalars['DateTime']>;
  lockedFrom?: Maybe<Scalars['DateTime']>;
  resourceAccess?: Maybe<Array<UserResourceAccess>>;
};

export enum UserCommunicationChannel {
  Email = 'EMAIL'
}

export type UserCommunicationPreference = {
  __typename?: 'UserCommunicationPreference';
  channel: UserCommunicationChannel;
  type: UserCommunicationPreferenceCommunicationType;
  allow: Scalars['Boolean'];
};

export enum UserCommunicationPreferenceCommunicationType {
  RewardsAndUpdatesForChild = 'REWARDS_AND_UPDATES_FOR_CHILD'
}

export type UserCommunicationPreferenceInput = {
  channel: UserCommunicationChannel;
  type: UserCommunicationPreferenceCommunicationType;
  allow: Scalars['Boolean'];
};

export type UserCreateInput = {
  email: Scalars['String'];
  emailValidated: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roles: Array<UserRoleCreateInput>;
  lockedUntil?: Maybe<Scalars['DateTime']>;
  lockedFrom?: Maybe<Scalars['DateTime']>;
  resourceAccess: Array<UserResourceAccessCreateInput>;
};

export type UserFilter = {
  id?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  emailValidated?: Maybe<BooleanFilter>;
  firstName?: Maybe<StringFilter>;
  lastName?: Maybe<StringFilter>;
  lockedUntil?: Maybe<DateTimeFilter>;
  lockedFrom?: Maybe<Scalars['DateTime']>;
};

export type UserResourceAccess = {
  __typename?: 'UserResourceAccess';
  projectId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  pupilId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type UserResourceAccessCreateInput = {
  projectId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  pupilId?: Maybe<Scalars['String']>;
};

export type UserResourceAccessFilter = {
  projectId?: Maybe<StringFilter>;
  schoolId?: Maybe<StringFilter>;
  classId?: Maybe<StringFilter>;
  pupilId?: Maybe<StringFilter>;
  createdAt?: Maybe<StringFilter>;
};

export type UserResourceAccessUpdateInput = {
  projectId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  pupilId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  role: Role;
  projectId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type UserRoleCreateInput = {
  role: Role;
  projectId: Scalars['String'];
};

export type UserRoleFilter = {
  role?: Maybe<UserRoleRoleFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  projectId?: Maybe<StringFilter>;
};

export type UserRoleRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Maybe<Role>>>;
};

export type UserRoleUpdateInput = {
  role: Role;
  projectId: Scalars['String'];
};

export type UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  emailValidated?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRoleUpdateInput>>;
  lockedUntil?: Maybe<Scalars['DateTime']>;
  lockedFrom?: Maybe<Scalars['DateTime']>;
  resourceAccess?: Maybe<Array<UserResourceAccessUpdateInput>>;
};

export type Username = {
  __typename?: 'Username';
  username: Scalars['String'];
  seed: Scalars['String'];
  id: Scalars['String'];
};

export type ValidatePupilClassNumberInput = {
  class: Scalars['String'];
  classNumber: Scalars['Int'];
};

export type ValidatePupilTagInput = {
  tag: PupilTagInput;
  schoolyearIds?: Maybe<Array<Scalars['String']>>;
};

export type Zone = {
  __typename?: 'Zone';
  id: Scalars['String'];
  title: Scalars['String'];
  color: Scalars['String'];
  school: School;
  geoFencePoints: Array<ZoneGeoFencePoint>;
};

export type ZoneCreateInput = {
  title: Scalars['String'];
  color: Scalars['String'];
  school: Scalars['String'];
  geoFencePoints: Array<ZoneGeoFencePointInput>;
};

export type ZoneFilter = {
  id?: Maybe<StringFilter>;
  school?: Maybe<BasicStringFilter>;
  AND?: Maybe<Array<ZoneFilter>>;
  OR?: Maybe<Array<ZoneFilter>>;
};

export type ZoneGeoFencePoint = {
  __typename?: 'ZoneGeoFencePoint';
  id: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ZoneGeoFencePointInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ZoneUpdateInput = {
  title?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  geoFencePoints?: Maybe<Array<ZoneGeoFencePointInput>>;
};
