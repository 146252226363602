/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetShopItemsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetShopItems = (
  { __typename?: 'Query' }
  & { assignedShopItems: Array<(
    { __typename?: 'AssignedShopItem' }
    & Pick<Types.AssignedShopItem, 'id' | 'price' | 'title' | 'description' | 'instructions' | 'availableStock' | 'canOrder'>
    & { image: (
      { __typename?: 'Asset' }
      & Pick<Types.Asset, 'url'>
    ) }
  )>, shopItemOrders: Array<(
    { __typename?: 'ShopItemOrder' }
    & Pick<Types.ShopItemOrder, 'id' | 'price'>
    & { shopItem?: Types.Maybe<(
      { __typename?: 'AssignedShopItem' }
      & Pick<Types.AssignedShopItem, 'title' | 'instructions'>
      & { type: (
        { __typename?: 'ShopItemType' }
        & Pick<Types.ShopItemType, 'type'>
      ), image: (
        { __typename?: 'Asset' }
        & Pick<Types.Asset, 'url'>
      ) }
    )> }
  )> }
);


export const GetShopItemsDocument = gql`
    query getShopItems {
  assignedShopItems(
    sort: [{field: "createdAt", order: DESC}, {field: "id", order: DESC}]
  ) {
    id
    price
    title
    description
    instructions
    availableStock
    image {
      url
    }
    canOrder
  }
  shopItemOrders(sort: [{field: "createdAt", order: DESC}]) {
    id
    price
    shopItem {
      title
      instructions
      type {
        type
      }
      image {
        url
      }
    }
  }
}
    `;

/**
 * __useGetShopItems__
 *
 * To run a query within a React component, call `useGetShopItems` and pass it any options that fit your needs.
 * When your component renders, `useGetShopItems` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopItems({
 *   variables: {
 *   },
 * });
 */
export function useGetShopItems(baseOptions?: Apollo.QueryHookOptions<GetShopItems, GetShopItemsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShopItems, GetShopItemsVariables>(GetShopItemsDocument, options);
      }
export function useGetShopItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopItems, GetShopItemsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShopItems, GetShopItemsVariables>(GetShopItemsDocument, options);
        }
export type GetShopItemsHookResult = ReturnType<typeof useGetShopItems>;
export type GetShopItemsLazyQueryHookResult = ReturnType<typeof useGetShopItemsLazyQuery>;
export type GetShopItemsQueryResult = Apollo.QueryResult<GetShopItems, GetShopItemsVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    