import Colors from "style/colors";
import styled from "styled-components";

const RewardListItemStyle = styled.li`
	display: flex;
	justify-content: space-between;
	color: ${Colors.Primary};
	padding: 16px 16px 16px 24px;
	background-color: ${Colors.PrimaryLight};
	border-radius: 8px;
	align-items: center;

	.name {
		font-size: 1.4em;
	}

	.price-container {
		display: flex;

		.price {
			font-size: 1.6em;
			font-weight: bold;
			margin-right: 8px;
		}
	}
`;

export default RewardListItemStyle;
