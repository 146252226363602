import Colors from "style/colors";
import styled from "styled-components";

const ProfileMetricStyle = styled.div`
	background-color: ${Colors.White};
	color: ${Colors.Black};
	margin: 0px 4px;
	border-radius: 8px;
	padding: 40px 30px;
	background-color: ${Colors.White};
	box-shadow: 0px 5px 15px ${Colors.BlackTransparant};
	text-align: center;

	img {
		height: 48px;
		width: 48px;
		margin-bottom: 16px;
	}

	p {
		font-size: 1.8em;
		font-weight: 700;
		color: ${Colors.Primary};
	}

	p:nth-of-type(2) {
		font-weight: 400;
		font-size: 1.2em;
	}
`;

export default ProfileMetricStyle;
