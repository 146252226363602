/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRewardsVariables = Types.Exact<{
  filterRewards?: Types.Maybe<Types.RewardFilter>;
  pagingRewards?: Types.Maybe<Types.Paging>;
  sortRewards?: Types.Maybe<Array<Types.Maybe<Types.Sort>> | Types.Maybe<Types.Sort>>;
  filterRewardRequests?: Types.Maybe<Types.RewardRequestFilter>;
  pagingRewardRequests?: Types.Maybe<Types.Paging>;
  sortRewardRequests?: Types.Maybe<Array<Types.Maybe<Types.Sort>> | Types.Maybe<Types.Sort>>;
}>;


export type GetRewards = (
  { __typename?: 'Query' }
  & { rewards: Array<(
    { __typename?: 'Reward' }
    & Pick<Types.Reward, 'id' | 'title' | 'isRewardedMultipleTimes' | 'valueInCredits' | 'rewardRequestsCount'>
    & { image: (
      { __typename?: 'Asset' }
      & Pick<Types.Asset, 'url'>
    ), rewardAssignments: Array<(
      { __typename?: 'RewardAssignment' }
      & Pick<Types.RewardAssignment, 'overrideRewardedMultipleTimes' | 'isRewardedMultipleTimes' | 'overrideValueInCredits' | 'valueInCredits' | 'amount'>
    )> }
  )>, rewardRequests: Array<(
    { __typename?: 'RewardRequest' }
    & Pick<Types.RewardRequest, 'id'>
    & { pupil: (
      { __typename?: 'Pupil' }
      & Pick<Types.Pupil, 'id' | 'firstName' | 'lastName'>
      & { username?: Types.Maybe<(
        { __typename?: 'Username' }
        & Pick<Types.Username, 'username'>
      )> }
    ), reward: (
      { __typename?: 'Reward' }
      & Pick<Types.Reward, 'id' | 'title' | 'valueInCredits'>
      & { image: (
        { __typename?: 'Asset' }
        & Pick<Types.Asset, 'url'>
      ), rewardAssignments: Array<(
        { __typename?: 'RewardAssignment' }
        & Pick<Types.RewardAssignment, 'overrideRewardedMultipleTimes' | 'isRewardedMultipleTimes' | 'overrideValueInCredits' | 'valueInCredits' | 'amount'>
      )> }
    ) }
  )>, profile: (
    { __typename?: 'Profile' }
    & { pupil: (
      { __typename?: 'Pupil' }
      & { pupilTotal?: Types.Maybe<(
        { __typename?: 'PupilTotal' }
        & Pick<Types.PupilTotal, 'id' | 'totalCredits'>
      )> }
    ) }
  ) }
);


export const GetRewardsDocument = gql`
    query getRewards($filterRewards: RewardFilter, $pagingRewards: Paging, $sortRewards: [Sort], $filterRewardRequests: RewardRequestFilter, $pagingRewardRequests: Paging, $sortRewardRequests: [Sort]) {
  rewards(filter: $filterRewards, paging: $pagingRewards, sort: $sortRewards) {
    id
    title
    image {
      url
    }
    isRewardedMultipleTimes
    valueInCredits
    rewardRequestsCount
    rewardAssignments {
      overrideRewardedMultipleTimes
      isRewardedMultipleTimes
      overrideValueInCredits
      valueInCredits
      amount
    }
  }
  rewardRequests(
    filter: $filterRewardRequests
    paging: $pagingRewardRequests
    sort: $sortRewardRequests
  ) {
    id
    pupil {
      id
      firstName
      lastName
      username {
        username
      }
    }
    reward {
      id
      title
      image {
        url
      }
      valueInCredits
      rewardAssignments {
        overrideRewardedMultipleTimes
        isRewardedMultipleTimes
        overrideValueInCredits
        valueInCredits
        amount
      }
    }
  }
  profile {
    pupil {
      pupilTotal {
        id
        totalCredits
      }
    }
  }
}
    `;

/**
 * __useGetRewards__
 *
 * To run a query within a React component, call `useGetRewards` and pass it any options that fit your needs.
 * When your component renders, `useGetRewards` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewards({
 *   variables: {
 *      filterRewards: // value for 'filterRewards'
 *      pagingRewards: // value for 'pagingRewards'
 *      sortRewards: // value for 'sortRewards'
 *      filterRewardRequests: // value for 'filterRewardRequests'
 *      pagingRewardRequests: // value for 'pagingRewardRequests'
 *      sortRewardRequests: // value for 'sortRewardRequests'
 *   },
 * });
 */
export function useGetRewards(baseOptions?: Apollo.QueryHookOptions<GetRewards, GetRewardsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRewards, GetRewardsVariables>(GetRewardsDocument, options);
      }
export function useGetRewardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRewards, GetRewardsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRewards, GetRewardsVariables>(GetRewardsDocument, options);
        }
export type GetRewardsHookResult = ReturnType<typeof useGetRewards>;
export type GetRewardsLazyQueryHookResult = ReturnType<typeof useGetRewardsLazyQuery>;
export type GetRewardsQueryResult = Apollo.QueryResult<GetRewards, GetRewardsVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    