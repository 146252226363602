import ButtonStyle from "components/button/ButtonStyle";
import Colors from "style/colors";
import styled from "styled-components";

const ModalStyle = styled.div`
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	background-color: ${Colors.PrimaryTransparent};
	display: flex;
	justify-content: center;
	align-items: center;
	animation: background-fade-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);

	.modal {
		border-radius: 8px;
		background-color: ${Colors.PrimaryLight};
		width: 80vw;
		height: 80vh;
		padding: 56px 104px;
		color: ${Colors.Primary};
		display: flex;
		flex-direction: column;
		text-align: center;
		transform-origin: 50% 50%;
		animation: modal-pop-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);

		.title {
			font-size: 4em;
			color: ${Colors.Secondary};
			font-weight: bold;
			text-align: center;
			padding: 0 10vw;
		}

		.icon {
			margin: 56px 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body {
			font-size: 1.4em;
		}

		.buttons {
			display: flex;
			margin-top: 40px;

			${ButtonStyle} {
				margin: 0 4px;
			}
		}
	}

	@keyframes background-fade-in {
		from { opacity: 0; }
		to { opacity: 1; }
	}

	@keyframes modal-pop-in {
		from { transform: scale(0, 0); }
		80% { transform: scale(1.3, 1.3); }
		to { transform: scale(1, 1); }
	}
`;

export default ModalStyle;
