import Button from "components/button/Button";
import useProfile from "hooks/profile/useProfile";
import { Reward } from "hooks/rewards/models/reward";
import useRewards from "hooks/rewards/useRewards";
import useRoutes from "hooks/routes/useRoutes";
import useShopItems from "hooks/shopItems/useShopItems";
import { PageComponent } from "models/component";
import { ShopItemOrder } from "models/schema";
import { Link } from "react-router-dom";
import nl from "translations/nl";
import OrderHistorySection from "./components/orderHistorySection/orderHistorySection";
import RewardCard from "./components/rewardCard/rewardCard";
import Wallet from "./components/wallet/wallet";
import { useGetRewards } from "./models/getRewards";
import { useGetShopItems } from "./models/getShopItems";
import WebshopPageStyle from "./webshopPageStyle";

const WebshopPage: PageComponent = () => {
	const { models: routes } = useRoutes();
	const { models: { profile } } = useProfile(useGetRewards());
	const {
		models: { rewards, purchasedRewards, isLoading: rewardsLoading },
		operations: { placeOrder },
	} = useRewards(useGetRewards());

	const {
		models: { shopItems, purchasedShopItems, isLoading: shopItemsLoading },
		operations: { placeShopItemOrder },
	} = useShopItems(useGetShopItems());

	const renderRewardCard = (reward: Reward): JSX.Element => (
		<RewardCard {...reward} operations={{ placeOrder }} />
	);

	const renderShopItemCard = (shopItem: any): JSX.Element => (
		<RewardCard
			id={shopItem.id}
			imageUrl={shopItem.image.url}
			name={shopItem.title || ""}
			price={shopItem.price}
			canOrder={shopItem.canOrder}
			operations={{
				placeOrder: placeShopItemOrder,
			}}
			collectionInstructions={shopItem.instructions}
		/>
	);

	const purchasedRewardsAndShopItems = [...purchasedShopItems, ...purchasedRewards].map((item) => ({ name: ((item as Reward).name || (item as ShopItemOrder).shopItem?.title || ""), price: item.price || 0 }));

	return (
		<WebshopPageStyle>
			<div className="wrapper">
				<Link className="back-button" to={routes.profile}>
					<Button>{nl.webshop.backToProfile}</Button>
				</Link>
				<Wallet totalCredits={profile.totalCredits} />

				<section className="catalog">
					{(rewardsLoading || shopItemsLoading) && <div className="catalog-overlay"><h3>{nl.loading}</h3></div>}
					{!rewardsLoading && (rewards.length === 0 || shopItems.length === 0) && <div className="catalog-overlay"><h3>{nl.webshop.noRewards}</h3></div>}
					{!rewardsLoading && rewards.map(renderRewardCard)}
					{!rewardsLoading && shopItems.map(renderShopItemCard)}
				</section>

				<OrderHistorySection purchasedRewards={purchasedRewardsAndShopItems} isLoading={rewardsLoading || shopItemsLoading} />
			</div>
		</WebshopPageStyle>
	);
};

export default WebshopPage;
