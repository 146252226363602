import event from "event";
import { AuthenticateWithPasswordInput } from "hooks/authentication/models/input";
import useAuthentication from "hooks/authentication/useAuthentication";
import { ApolloAPI } from "models/API";
import { InteractionLogic } from "models/logic";
import { AssignedShopItem, ShopItemOrder } from "models/schema";
import { useState } from "react";
import { useCreateOneShopItemOrder } from "./models/createOneShopItemOrder";

type API = ApolloAPI<"assignedShopItems" | "shopItemOrders">;

interface Operations {
	placeShopItemOrder: (shopItemId: AssignedShopItem["id"], input?: AuthenticateWithPasswordInput) => Promise<void>;
}

interface Models {
	shopItems: DeepPartial<AssignedShopItem>[];
	purchasedShopItems: DeepPartial<ShopItemOrder>[];
	isLoading: boolean;
	errorMessage: string;
}

export interface ShopItemEvents {
	"shopItemOrderPlaced": (type: string, id: AssignedShopItem["id"]) => void;
	"shopItemOrderConfirmed": () => void;
}

const useShopItems: InteractionLogic<API, Operations, Models> = (api) => {
	const { operations: { verifyWithPassword }, models: { isLoading: isAuthLoading } } = useAuthentication();

	const [createOneShopItemOrder, { loading: isCreateOneShopItemOrderLoading }] = useCreateOneShopItemOrder();

	const [errorMessage, setErrorMessage] = useState<Models["errorMessage"]>(api?.error?.message || "");
	const isLoading: Models["isLoading"] = api?.loading || isAuthLoading || isCreateOneShopItemOrderLoading;

	const shopItemsDTOs: DeepPartial<AssignedShopItem>[] = api?.data?.assignedShopItems || [];
	const shopItems: Models["shopItems"] = shopItemsDTOs;

	const purchasedShopItems: Models["purchasedShopItems"] = api?.data?.shopItemOrders || [];

	const placeShopItemOrder: Operations["placeShopItemOrder"] = async (shopItemId, input) => {
		if (!input) {
			event.emit("shopItemOrderPlaced", "shopItem", shopItemId);
		} else {
			try {
				const { isVerified } = await verifyWithPassword(input);

				if (isVerified) {
					const result = await createOneShopItemOrder({ variables: { assignedShopItemId: shopItemId } });
					const isSuccessful: boolean = !!result.data?.createOneShopItemOrder;

					if (isSuccessful) {
						event.emit("shopItemOrderConfirmed");
					} else {
						setErrorMessage("OOPS");
					}
				} else {
					setErrorMessage("OOPS");
				}
			} catch (error) {
				if (typeof error === "string") {
					setErrorMessage(error);
				}
			}
		}
	};

	return {
		models: {
			shopItems,
			purchasedShopItems,
			isLoading,
			errorMessage,
		},
		operations: {
			placeShopItemOrder,
		},
	};
};

export default useShopItems;
