import { InteractionLogic } from "models/logic";

import { ApolloAPI } from "../../models/API";
import { Profile as ProfileDTO } from "../../models/schema";
import { Profile } from "./models/profile";
import ProfileMapper from "./ProfileMapper";

type API = ApolloAPI<"profile">;

interface Operations {
}

type Models = {
	profile: Profile
	loading: boolean;
};

const useProfile: InteractionLogic<API, Operations, Models> = (profileAPI) => {
	const mapper = new ProfileMapper();

	const profileDTO: DeepPartial<ProfileDTO> = profileAPI?.data?.profile || {};
	const profile = mapper.profileDTOToProfile(profileDTO);

	const loading = profileAPI?.loading || false;

	return ({
		models: {
			profile,
			loading,
		},
		operations: {
		},
	});
};

export default useProfile;
