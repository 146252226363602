import event from "event";
import { AuthenticateWithPasswordInput } from "hooks/authentication/models/input";
import useAuthentication from "hooks/authentication/useAuthentication";
import useProfile from "hooks/profile/useProfile";
import { ApolloAPI } from "models/API";
import { InteractionLogic } from "models/logic";
import { Reward as RewardDTO } from "models/schema";
import { useState } from "react";
import RewardMapper from "./RewardMapper";
import { usePlaceOrder } from "./models/placeOrder";
import { Reward, RewardWithCanGetMultiple } from "./models/reward";

type API = ApolloAPI<"rewards" | "rewardRequests" | "profile">;

interface Operations {
	placeOrder: (rewardId: Reward["id"], input?: AuthenticateWithPasswordInput) => Promise<void>;
}

type PurchasedReward = Pick<Reward, "id" | "name" | "price">;

interface Models {
	rewards: Reward[];
	purchasedRewards: PurchasedReward[];
	isLoading: boolean;
	errorMessage: string;
}

export interface RewardEvents {
	"orderPlaced": (type: string, id: Reward["id"]) => void;
	"orderConfirmed": (reward: Reward) => void;
}

const useRewards: InteractionLogic<API, Operations, Models> = (api) => {
	const { operations: { verifyWithPassword }, models: { isLoading: isAuthLoading } } = useAuthentication();
	const { models: { profile } } = useProfile(api);
	const [placeOrderMutation, { loading: isPlaceOrderLoading }] = usePlaceOrder();

	const [errorMessage, setErrorMessage] = useState<Models["errorMessage"]>(api?.error?.message || "");
	const isLoading: Models["isLoading"] = api?.loading || isAuthLoading || isPlaceOrderLoading;
	const mapper = new RewardMapper(profile.totalCredits);
	const purchasedRewards: Models["purchasedRewards"] = api?.data?.rewardRequests?.map(mapper.rewardRequest.toInternal) || [];

	const hasAlreadyBeenAwarded = (purchasedReward: PurchasedReward, reward: Reward): boolean => (
		purchasedReward.id === reward.id
	);

	const canBeAwarded = (reward: RewardWithCanGetMultiple): boolean => (
		reward.canGetMultiple || !purchasedRewards.some((purchasedReward) => hasAlreadyBeenAwarded(purchasedReward, reward))
	);

	const rewardDTOs: DeepPartial<RewardDTO>[] = api?.data?.rewards || [];
	const rewards: Models["rewards"] = rewardDTOs
		.map(mapper.reward.toInternalWithCanGetMultiple)
		.filter(canBeAwarded);

	const placeOrder: Operations["placeOrder"] = async (rewardId, input) => {
		if (!input) {
			event.emit("orderPlaced", "reward", rewardId);
		} else {
			try {
				const { isVerified } = await verifyWithPassword(input);

				if (isVerified) {
					const result = await placeOrderMutation({ variables: { reward: rewardId } });
					const isSuccessful: boolean = !!result.data?.createOneRewardRequest;

					if (isSuccessful) {
						event.emit("orderConfirmed", mapper.rewardRequest.toInternal(result.data?.createOneRewardRequest || {}));
					}
				}
			} catch (error) {
				if (typeof error === "string") {
					setErrorMessage(error);
				}
			}
		}
	};

	return {
		models: {
			rewards,
			purchasedRewards,
			isLoading,
			errorMessage,
		},
		operations: {
			placeOrder,
		},
	};
};

export default useRewards;
