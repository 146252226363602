const nl = {
	loading: "Aan het laden...",
	buttons: {
		login: "Inloggen",
		logout: "Ik wil stoppen",
		confirm: "Bevestigen",
		cancel: "Terug keren",
	},
	homePage: {
		title: "Duurzaam, gezond & veilig naar school in",
		projectMetrics: {
			totalDistanceLabel: "duurzame kilometers afgelegd",
			totalCO2Label: "minder CO2 uitstoot",
			totalFuelLabel: "brandstof per maand per gezin bespaard",
		},
		totalSustainableMovements: {
			labelPrefix: "duurzame verplaatsingen met",
			labelSuffix: "leeringen",
		},
	},
	login: {
		form: {
			labels: {
				classId: "Wat is je klas?",
				studentIdNumber: "Wat is je klasnummer?",
				password: "Wat is je wachtwoord?",
				forgotPassword: "Wachtwoord vergeten? Vraag hulp aan je meester of juf!",
			},
			errors: {
				generalMessage: "Oeps, je hebt waarschijnlijk iets verkeerd ingevuld. Probeer opnieuw!",
			},
			placeholders: {
				classId: "Kies een klas",
			},
		},
		nfc: {
			title: "Je kan ook inloggen met je bandje!",
			subtitle: "Hou je bandje tegen de lezer vooraan het scherm",
		},
		back: "Terug naar het vorige scherm",
	},
	profile: {
		hello: "Hallo",
		charts: {
			titles: {
				personalCredits: "Hoeveel muntjes verzamelde je de laatste 7 dagen",
				classCredits: "Hoeveel muntjes heeft jouw klas al verzameld",
			},
		},
		link: {
			webshop: "Bekijk de webwinkel",
		},
		metrics: {
			labels: {
				totalCredits: "muntjes",
				totalHighFives: "high-fives",
			},
		},
	},
	webshop: {
		yourCoins: "Jouw muntjes!",
		yourPreviousRewards: "Deze beloningen heb je al eens gekocht!",
		backToProfile: "Terug naar je profiel",
		coins: "muntjes",
		noRewards: "Er zijn momenteel geen beloningen beschikbaar of je hebt al alle mogelijke beloningen verdient!",
		form: {
			title: "Bijna klaar! Nog even bevestigen dat jij het bent!",
		},
	},
	modal: {
		idle: {
			title: "Hé, ben je er nog?",
			body: "Je hebt al even niets meer gedaan. Over enkele seconden zul je uitloggen!",
			stillHere: "Ik ben er nog!",
		},
		orderConfirmed: {
			title: "Hoera! Je beloning is besteld!",
			body: "Ben je klaar om uit te loggen, of kijk je liever nog wat verder rond?",
			stopSession: "Ik wil stoppen",
			continueSession: "Verder kijken",
		},
	},
};

export default nl;
