import Colors from "style/colors";
import styled from "styled-components";

const ProjectMetricStyle = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;

	p:first-of-type {
		font-size: 2em;
		font-weight: 700;
		margin-bottom: 8px;
	}

	p:nth-of-type(2) {
		color: ${Colors.Black};
		opacity: 0.65;
	}

	img {
		height: 36px;
		margin-bottom: 16px;
	}
`;

export default ProjectMetricStyle;
