import Layout from "style/layout";
import styled from "styled-components";
import OrderHistorySectionStyle from "./components/orderHistorySection/orderHistorySectionStyle";
import WalletStyle from "./components/wallet/walletStyle";

const WebshopPageStyle = styled(Layout)`
	.wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 160px calc(100vh - 160px);
		column-gap: 8px;
		row-gap: 8px;
		grid-template-areas:
			"back-button .       wallet"
			"catalog     catalog history";

		.back-button {
			grid-area: back-button;
			max-width: 1fr;
			align-self: center;
		}

		${WalletStyle} {
			grid-area: wallet;
		}

		.catalog {
			grid-area: catalog;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 8px;
			row-gap: 8px;
			overflow-y: scroll;
			
			.catalog-overlay {
				grid-column: 1 / 3;

				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		${OrderHistorySectionStyle} {
			grid-area: history;
		}
	}
`;

export default WebshopPageStyle;
