import { UILogic } from "@aptus/frontend-core";
import {
	RefObject, useEffect, useReducer, useRef,
} from "react";

export interface NfcInputProps {
	submit: (nfcId: string) => void;
}

type Props = NfcInputProps;

interface Models {
	ref: RefObject<HTMLInputElement>;
}

const useNfcInput: UILogic<Props, undefined, Models> = ({ submit }) => {
	const reducer = (state: string, action: string) => {
		if (action === "clear") {
			return "";
		}

		return state + action;
	};

	const [text, setText] = useReducer(reducer, "");
	const ref = useRef<HTMLInputElement>(null);
	const NFC_LENGTH = 10;

	const toHex = (str: string): string => (
		parseInt(str, 10).toString(16).padStart(8, "0")
	);

	if (text.length === NFC_LENGTH) {
		submit(toHex(text));
		setText("clear");
	}

	const onKeyPress = (e: KeyboardEvent): void => {
		setText(e.key);
	};

	const onClick = (e: MouseEvent): void => {
		const element = e.target;
		const isInput = element instanceof HTMLInputElement;

		if (!isInput && ref.current) {
			ref.current.focus();
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", onKeyPress);
		window.addEventListener("click", onClick);

		return () => {
			window.removeEventListener("keydown", onKeyPress);
			window.addEventListener("click", onClick);
		};
	}, []);

	return {
		models: {
			ref,
		},
	};
};

export default useNfcInput;
