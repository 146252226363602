import { PupilCredit as PupilCreditDTO } from "../../models/schema";
import { PupilCredit } from "./models/pupilCredit";

class PupilCreditMapper {
	pupilCreditDTOToPupilCredit = (pupilCredit: DeepPartial<PupilCreditDTO>): PupilCredit => ({
		id: pupilCredit.id || "",
		createdAt: pupilCredit.createdAt as any as string || "",
		credit: pupilCredit.credit || 0,
		description: pupilCredit.description || "",
		type: pupilCredit.type || "",
	});
}

export default PupilCreditMapper;
