import { UIComponent } from "models/component";
import AlertStyle from "./AlertStyle";
import { AlertProps } from "./useAlert";

const Alert: UIComponent<AlertProps> = ({ message, type }) => (
	<AlertStyle type={type}>
		<p>{message}</p>
	</AlertStyle>
);

export default Alert;
