const Colors = {
	Primary: "#256be9",
	PrimaryDark: "#0064b2",
	PrimaryLight: "#e7f1ff",
	PrimaryTransparent: "rgba(20, 120, 255, .8)",
	Secondary: "#fecc41",
	White: "#ffffff",
	WhiteTransparant: "rgba(255, 255, 255, 0.3)",
	Black: "#000000",
	BlackTransparant: "rgba(0, 0, 0, 0.1)",
	Red: "#d81a1a",
	RedLight: "#ffe2e2",
	Invisible: "rgba(0, 0, 0, 0)",
};

export default Colors;
