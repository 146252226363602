import Colors from "style/colors";
import styled from "styled-components";

const OrderHistorySectionStyle = styled.section`
	background-color: ${Colors.White};
	border-radius: 8px;
	padding: 24px 16px;

	display: flex;
	flex-direction: column;

	.title {
		font-size: 1.4em;
		color: ${Colors.Primary};
		margin: 16px 0 48px;
		text-align: center;
	}

	ul {
		display: grid;
		grid-auto-flow: row;
		grid-auto-rows: min-content;
		row-gap: 8px;

		flex-grow: 1;
		overflow: auto;
	}
`;

export default OrderHistorySectionStyle;
