import { UIComponent } from "models/component";
import nl from "translations/nl";
import WalletStyle from "./walletStyle";
import creditsIcon from "../../../../assets/svg/credits.svg";

interface WalletProps {
	totalCredits: number;
}

const Wallet: UIComponent<WalletProps> = ({ totalCredits }) => (
	<WalletStyle>
		<p className="title">{nl.webshop.yourCoins}</p>
		<div className="coins">
			<p className="amount">{totalCredits}</p>
			<img src={creditsIcon} alt={nl.webshop.coins} />
		</div>
	</WalletStyle>
);

export default Wallet;
