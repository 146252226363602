import { Class as ClassDTO } from "../../models/schema";
import { Class } from "./models/class";

/**
 * This class maps all our DTOs (Data Transfer Object) to more useable models or back.
 * Converting these prevents our code from being cluttered with null checks or allows us to
 * add frontend specific object keys.
 */

class ClassMapper {
	classDTOToClass = (clasz: DeepPartial<ClassDTO>): Class => ({
		id: clasz?.id || "",
		title: clasz?.title || "",
		schoolTitle: clasz?.school?.title || "",
		classTotal: {
			totalCredits: clasz.classTotal?.totalCredits || 0,
		},
	});
}

export default ClassMapper;
