import styled from "styled-components";
import ProjectMetricStyle from "pages/homePage/components/projectMetric/ProjectMetricStyle";
import Colors from "style/colors";

const ProjectMetricsSectionStyle = styled.div`
	display: flex;

	.divider {
		border-left: 1px solid ${Colors.WhiteTransparant};
		margin: 36px 48px;
	}

	${ProjectMetricStyle} {
		max-width: 200px;
	}
`;

export default ProjectMetricsSectionStyle;
