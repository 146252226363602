import { DTOMap } from "@aptus/frontend-core";
import { Reward as RewardDTO, RewardRequest as RewardRequestDTO } from "../../models/schema";
import { Reward, RewardWithCanGetMultiple } from "./models/reward";
import placeholder from "../../assets/images/placeholder.jpg";

/**
 * This class maps all our DTOs (Data Transfer Object) to more useable models or back.
 * Converting these prevents our code from being cluttered with null checks or allows us to
 * add frontend specific object keys.
 */

interface RewardMapper {
	reward: Pick<DTOMap<RewardDTO, Reward>, "toInternal"> & { "toInternalWithCanGetMultiple": (reward: DeepPartial<RewardDTO>) => RewardWithCanGetMultiple };
	rewardRequest: Pick<DTOMap<RewardRequestDTO, Reward>, "toInternal">;
}

class RewardMapper implements RewardMapper {
	private totalCredits: number;

	constructor(totalCredits: number) {
		this.totalCredits = totalCredits;
	}

	private isInStock = (reward: DeepPartial<RewardDTO>): boolean => {
		const rewardAssignments = reward.rewardAssignments || [];
		const totalStock: number = rewardAssignments[0].amount || 0;
		const totalSold: number = reward?.rewardRequestsCount ?? 0;

		return totalStock > totalSold;
	};

	public reward: RewardMapper["reward"] = {
		toInternal: (reward) => {
			const [assignment] = reward?.rewardAssignments || [{}];
			const price = assignment?.overrideValueInCredits ? assignment?.valueInCredits : reward?.valueInCredits;
			const userHasEnoughMoney = this.totalCredits > (price || 0);
			const canOrderMultiple = assignment?.overrideRewardedMultipleTimes ? assignment?.isRewardedMultipleTimes : reward?.isRewardedMultipleTimes;

			return {
				id: reward.id || "",
				name: reward.title || "Geen naam beschikbaar",
				imageUrl: reward.image?.url || placeholder,
				price: price || 0,
				canOrder: userHasEnoughMoney && (canOrderMultiple || false) && !this.isInStock(reward),
				collectionInstructions: (assignment.overrideCollectInstructions ? assignment.collectInstructions : reward.collectInstructions) || "",
				note: !canOrderMultiple ? "Deze beloning kan slechts 1 maal besteld worden." : undefined,
			};
		},
		toInternalWithCanGetMultiple: (reward) => ({
			...this.reward.toInternal(reward),
			canGetMultiple: reward.isRewardedMultipleTimes || false,
		}),
	};

	public rewardRequest: RewardMapper["rewardRequest"] = {
		toInternal: (rewardRequest) => this.reward.toInternal(rewardRequest.reward || {}),
	};
}

export default RewardMapper;
