import { useEffect, useRef } from "react";

const useInterval = (callback: () => void, delay: number | null) => {
	const savedCallback = useRef<any>();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => savedCallback.current();
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}

		return () => {};
	}, [delay]);
};

export default useInterval;
