import HomepageFive from "assets/images/homepage-five.png";
import useClient from "hooks/client/useClient";
import useProjectMetrics from "hooks/projectMetrics/useProjectMetrics";
import { PageComponent } from "models/component";
import ProjectMetricsSection from "pages/homePage/components/projectMetricsSection/ProjectMetricsSection";
import TotalSustainableMovements from "pages/homePage/components/totalSustainableMovements/TotalSustainableMovements";
import HomePageStyle from "pages/homePage/HomePageStyle";
import nl from "translations/nl";
import NfcInput from "components/nfcInput/nfcInput";
import useAuthentication from "hooks/authentication/useAuthentication";
import { useGetProjectMetrics } from "./models/getProjectMetrics";

const HomePage: PageComponent = () => {
	const {
		totalDistance,
		totalCO2,
		totalFuel,
		totalMovements,
		totalPupils,
	} = useProjectMetrics(useGetProjectMetrics({
		context: { useAnonymousToken: true },
		variables: { paging: { limit: 1, offset: 0 } },
		pollInterval: 300000,
	})).models;
	const { loginWithNfc } = useAuthentication().operations;

	const { client } = useClient().models;

	return (
		<HomePageStyle>
			<div className="project-metrics-container">
				<h1>{`${nl.homePage.title} ${client.title}`}</h1>
				<ProjectMetricsSection
					totalDistance={totalDistance}
					totalCO2={totalCO2}
					totalFuel={totalFuel}
				/>
				<TotalSustainableMovements
					totalMovements={totalMovements}
					totalPupils={totalPupils}
				/>
			</div>
			<img className="homepage-five" src={HomepageFive} alt="Five" />
			<NfcInput submit={(token) => loginWithNfc({ value: token })} />
		</HomePageStyle>
	);
};

export default HomePage;
