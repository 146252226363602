import { Client } from "hooks/client/useClient";

export enum Clients {
	Default = "default",
	Harelbeke = "harelbeke",
	Moorsele = "moorsele",
	Houthulst = "houthulst",
	Aptus = "aptus",
	Genk = "genk",
	Schoten = "schoten",
}

export const clients: Record<string, Client> = {
	[Clients.Default]: {
		title: "",
		urls: {},
	},
	[Clients.Schoten]: {
		title: "Schoten",
		urls: {
			// "localhost:3000": {
			// clientId: "high-five-schoten-app",
			// clientSecret: "76b5f2ad-a5a9-4634-b167-e1554cfe917f",
			// },
			"dev.kiosk.schoten.highfive.aptus.be": {
				clientId: "high-five-schoten-app",
				clientSecret: "76b5f2ad-a5a9-4634-b167-e1554cfe917f",
			},
			"acc.kiosk.schoten.highfive.aptus.be": {
				clientId: "high-five-schoten-app-acc",
				clientSecret: "9d6640ac-86c0-488d-83e7-91f2d8114846",
			},
			"kiosk.schoten.high-five.io": {
				clientId: "high-five-schoten-app",
				clientSecret: "5e36c635-cab4-4ac6-b25a-bf7b5d763933",
			},
		},

	},
	[Clients.Harelbeke]: {
		title: "Harelbeke",
		urls: {
			/* "localhost:3000": {
							clientId: "high-five-harelbeke-app",
							clientSecret: "225752a3-31b9-473f-92a0-631dfd7f6238"
					}, */
			"localhost:8081": {
				clientId: "high-five-harelbeke-app",
				clientSecret: "225752a3-31b9-473f-92a0-631dfd7f6238",
			},
			"dev.kiosk.highfive.aptus.be": {
				clientId: "high-five-harelbeke-app",
				clientSecret: "225752a3-31b9-473f-92a0-631dfd7f6238",
			},
			"dev.kiosk.harelbeke.highfive.aptus.be": {
				clientId: "high-five-harelbeke-app",
				clientSecret: "225752a3-31b9-473f-92a0-631dfd7f6238",
			},
			"acc.kiosk.harelbeke.highfive.aptus.be": {
				clientId: "high-five-harelbeke-app-acc",
				clientSecret: "876ae25b-be4f-47ca-bfd3-a8074b134ac9",
			},
			"kiosk.harelbeke.high-five.io": {
				clientId: "high-five-harelbeke-app",
				clientSecret: "378a1049-faa7-4193-bab2-387586c41ed7",
			},
		},
	},
	[Clients.Moorsele]: {
		title: "Moorsele",
		urls: {
			"acc.kiosk.wevelgem.highfive.aptus.be": {
				clientId: "high-five-wevelgem-app-acc",
				clientSecret: "9efb28f6-b74c-4c0c-90f8-cb2265f8a4fc",
			},
			"kiosk.moorsele.high-five.io": {
				clientId: "high-five-wevelgem-app",
				clientSecret: "a304b55f-dea6-47bd-854f-b6f06b2e048f",
			},
		},
	},
	[Clients.Houthulst]: {
		title: "Houthulst",
		urls: {
			// "localhost:3000": {
			//     // dev
			//     clientId: "highfive-houthulst-app",
			//     clientSecret: "51d79fb4-45e3-4eb3-9f9d-cd9b632389b1"
			// },
			// "localhost:3000": { // acc
			//     clientId: "high-five-houthulst-app",
			//     clientSecret: "5ba54885-43d9-42be-a645-bbab524648e9"
			// },
			// "localhost:3000": {
			//     clientId: "high-five-aptus-kantoor-app",
			//     clientSecret: "23fc3832-fbe3-4d1a-848d-349a326e2af1"
			// },
			"dev.kiosk.houthulst.highfive.aptus.be": {
				clientId: "highfive-houthulst-app",
				clientSecret: "51d79fb4-45e3-4eb3-9f9d-cd9b632389b1",
			},
			"acc.kiosk.houthulst.highfive.aptus.be": {
				clientId: "high-five-houthulst-app",
				clientSecret: "5ba54885-43d9-42be-a645-bbab524648e9",
			},
			"kiosk.houthulst.high-five.io": {
				clientId: "high-five-houthulst-app",
				clientSecret: "5ba54885-43d9-42be-a645-bbab524648e9",
			},
		},
	},

	[Clients.Aptus]: {
		title: "Aptus",
		urls: {
			"localhost:3000": {
				clientId: "high-five-aptus-kantoor-app",
				clientSecret: "30112408-534d-40f3-bc85-e8edac8be04f",
			},
			"dev.kiosk.aptus-kantoor.highfive.aptus.be": {
				clientId: "high-five-aptus-kantoor-app",
				clientSecret: "30112408-534d-40f3-bc85-e8edac8be04f",
			},
			"acc.kiosk.aptus-kantoor.highfive.aptus.be": {
				clientId: "high-five-aptus-kantoor-app",
				clientSecret: "30112408-534d-40f3-bc85-e8edac8be04f",
			},
			"kiosk.aptus-kantoor.high-five.io": {
				clientId: "high-five-aptus-kantoor-app",
				clientSecret: "23fc3832-fbe3-4d1a-848d-349a326e2af1",
			},
		},
	},
	[Clients.Genk]: {
		title: "Genk",
		urls: {
			// "localhost:3000": {
			//     clientId: "high-five-genk-app",
			//     clientSecret: "d283bf50-b8b3-4a6d-924d-931f91c42fac"
			// },
			"dev.genk-kiosk.highfive.aptus.be": {
				clientId: "high-five-genk-app",
				clientSecret: "d283bf50-b8b3-4a6d-924d-931f91c42fac",
			},
			"acc.genk-kiosk.highfive.aptus.be": {
				clientId: "high-five-genk-app",
				clientSecret: "d283bf50-b8b3-4a6d-924d-931f91c42fac",
			},
			"genk-kiosk.high-five.io": {
				clientId: "high-five-genk-app",
				clientSecret: "991b30aa-906f-4270-933f-27360b69e809",
			},
		},
	},
};
