import TokenManager from "hooks/authentication/tokenManager";
import useRoutes from "hooks/routes/useRoutes";
import { FunctionComponent } from "react";
import { Redirect, Route as OriginalRoute, RouteProps as OriginalRouteProps } from "react-router";

interface RouteProps extends OriginalRouteProps {
	private?: boolean;
}

const Route: FunctionComponent<RouteProps> = ({ private: isPrivate, ...routeProps }) => {
	const { models: routes } = useRoutes();

	if (isPrivate && TokenManager.tokenExpired) {
		return <Redirect to={routes.home} />;
	}

	return <OriginalRoute {...routeProps} />;
};

export default Route;
