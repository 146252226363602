import "style/index.css";
import { FunctionComponent, StrictMode, useState } from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import client from "client";
import Routes from "Routes";
import reportWebVitals from "reportWebVitals";
import modalContext, { ModalContext } from "hooks/useModals/modalContext";
import ModalPage from "pages/modalPage/modalPage";

// Uncomment this and import worker from './mocks/worker' to use the mocked API in the browser

// if (process.env.NODE_ENV === 'development') {
//   worker.start();
// }

const App: FunctionComponent = () => {
	const [activeModal, setActiveModal] = useState<ModalContext["activeModal"]>();

	return (
		<StrictMode>
			<ApolloProvider client={client}>
				<BrowserRouter>
					<modalContext.Provider value={{ activeModal, setActiveModal }}>
						<Routes />
						<ModalPage />
					</modalContext.Provider>
				</BrowserRouter>
			</ApolloProvider>
		</StrictMode>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
