import Colors from "style/colors";
import styled from "styled-components";
import { ButtonStyleProps } from "./useButton";

const theme = {
	primary: {
		text: Colors.White,
		shadow: Colors.PrimaryDark,
		background: Colors.Primary,
	},
	secondary: {
		text: Colors.Primary,
		shadow: Colors.BlackTransparant,
		background: Colors.White,
	},
	danger: {
		text: Colors.White,
		shadow: Colors.BlackTransparant,
		background: Colors.Red,
	},
	disabled: {
		text: Colors.White,
		shadow: Colors.Invisible,
		background: Colors.PrimaryLight,
	},
};

const ButtonStyle = styled.button<ButtonStyleProps>`
	width: 100%;
	height: 48px;
	border-radius: 8px;
	background-color: ${(props) => (props.disabled ? theme.disabled.background : theme[props.buttonType].background)};
	box-shadow: 0 5px 1px ${(props) => (props.disabled ? theme.disabled.shadow : theme[props.buttonType].shadow)};
	border: none;
	color: ${(props) => (props.disabled ? theme.disabled.text : theme[props.buttonType].text)};
	font-weight: 700;
	font-size: 1.4em;
`;

export default ButtonStyle;
