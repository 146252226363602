/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProfileLoginDetailsVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProfileLoginDetails = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & { pupil: (
      { __typename?: 'Pupil' }
      & { pupilTags?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'PupilTag' }
        & Pick<Types.PupilTag, 'type' | 'tag' | 'title'>
      )>>> }
    ) }
  ) }
);


export const GetProfileLoginDetailsDocument = gql`
    query getProfileLoginDetails {
  profile {
    pupil {
      pupilTags {
        type
        tag
        title
      }
    }
  }
}
    `;

/**
 * __useGetProfileLoginDetails__
 *
 * To run a query within a React component, call `useGetProfileLoginDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileLoginDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileLoginDetails({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileLoginDetails(baseOptions?: Apollo.QueryHookOptions<GetProfileLoginDetails, GetProfileLoginDetailsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileLoginDetails, GetProfileLoginDetailsVariables>(GetProfileLoginDetailsDocument, options);
      }
export function useGetProfileLoginDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileLoginDetails, GetProfileLoginDetailsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileLoginDetails, GetProfileLoginDetailsVariables>(GetProfileLoginDetailsDocument, options);
        }
export type GetProfileLoginDetailsHookResult = ReturnType<typeof useGetProfileLoginDetails>;
export type GetProfileLoginDetailsLazyQueryHookResult = ReturnType<typeof useGetProfileLoginDetailsLazyQuery>;
export type GetProfileLoginDetailsQueryResult = Apollo.QueryResult<GetProfileLoginDetails, GetProfileLoginDetailsVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    