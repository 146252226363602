import Button from "components/button/Button";
import { Reward } from "hooks/rewards/models/reward";
import { UIComponent } from "models/component";
import nl from "translations/nl";
import RewardCardStyle from "./rewardCardStyle";
import creditsIcon from "../../../../assets/svg/credits.svg";

interface Operations {
	placeOrder: (rewardId: Reward["id"]) => void;
}

interface RewardCardProps extends Reward {
	operations: Operations;
}

const RewardCard: UIComponent<RewardCardProps> = ({
	id, imageUrl, name, operations, price, canOrder, note,
}) => (
	<RewardCardStyle>
		<img className="thumbnail" src={imageUrl} alt={name} />
		<p className="name">{name}</p>
		<p className="note">{note}</p>
		<Button onClick={() => operations.placeOrder(id)} disabled={!canOrder}>
			{price}
			<img src={creditsIcon} alt={nl.webshop.coins} width="39" height="27" />
		</Button>
	</RewardCardStyle>
);

export default RewardCard;
