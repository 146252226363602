import PageWithForm from "components/pageWithForm/pageWithForm";
import useProfile from "hooks/profile/useProfile";
import useRewards from "hooks/rewards/useRewards";
import useRoutes from "hooks/routes/useRoutes";
import useShopItems from "hooks/shopItems/useShopItems";
import { PageComponent } from "models/component";
import { useParams } from "react-router";
import ConfirmForm from "./components/confirmForm/confirmForm";
import ConfirmPageStyle from "./confirmPageStyle";
import { useGetProfileLoginDetails } from "./models/getProfileLoginDetails";

interface RouteParams {
	type: string;
	id: string;
}

const ConfirmPage: PageComponent = () => {
	const { operations: { placeOrder }, models: { isLoading: isRewardsLoading, errorMessage: rewardsErrorMessage } } = useRewards();
	const { operations: { placeShopItemOrder }, models: { isLoading: isShopItemsLoading, errorMessage: shopItemsErrorMessage } } = useShopItems();
	const { models: { profile } } = useProfile(useGetProfileLoginDetails());
	const { models: routes } = useRoutes();
	const { type, id } = useParams<RouteParams>();

	const submitOrder = (password: string): void => {
		if (!profile || !profile.pupilTags || !profile.pupilTags.length) {
			return;
		}

		const tag = profile.pupilTags[0];
		if (type === "reward") {
			placeOrder(
				id,
				{
					nfcId: tag.tag,
					nfcTagType: tag.type,
					password,
				},
			);
		}

		if (type === "shopItem") {
			placeShopItemOrder(
				id,
				{
					nfcId: tag.tag,
					nfcTagType: tag.type,
					password,
				},
			);
		}
	};

	return (
		<ConfirmPageStyle>
			<PageWithForm>
				<ConfirmForm
					isLoading={isRewardsLoading || isShopItemsLoading}
					hasError={!!rewardsErrorMessage || !!shopItemsErrorMessage}
					cancelUrl={routes.webshop}
					operations={{
						onSubmit: submitOrder,
					}}
				/>
			</PageWithForm>
		</ConfirmPageStyle>
	);
};

export default ConfirmPage;
