/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProfileVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProfile = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename?: 'Profile' }
    & { pupil: (
      { __typename?: 'Pupil' }
      & Pick<Types.Pupil, 'firstName' | 'lastName'>
      & { username?: Types.Maybe<(
        { __typename?: 'Username' }
        & Pick<Types.Username, 'username'>
      )>, class: (
        { __typename?: 'Class' }
        & Pick<Types.Class, 'id'>
      ), pupilTotal?: Types.Maybe<(
        { __typename?: 'PupilTotal' }
        & Pick<Types.PupilTotal, 'id' | 'totalCredits' | 'totalRegistrations'>
      )> }
    ) }
  ) }
);


export const GetProfileDocument = gql`
    query getProfile {
  profile {
    pupil {
      firstName
      lastName
      username {
        username
      }
      class {
        id
      }
      pupilTotal {
        id
        totalCredits
        totalRegistrations
      }
    }
  }
}
    `;

/**
 * __useGetProfile__
 *
 * To run a query within a React component, call `useGetProfile` and pass it any options that fit your needs.
 * When your component renders, `useGetProfile` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfile({
 *   variables: {
 *   },
 * });
 */
export function useGetProfile(baseOptions?: Apollo.QueryHookOptions<GetProfile, GetProfileVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfile, GetProfileVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfile, GetProfileVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfile, GetProfileVariables>(GetProfileDocument, options);
        }
export type GetProfileHookResult = ReturnType<typeof useGetProfile>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfile, GetProfileVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    