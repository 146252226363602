import { Profile as ProfileDTO } from "../../models/schema";
import { Profile } from "./models/profile";

class ProfileMapper {
	profileDTOToProfile = (profile: DeepPartial<ProfileDTO>): Profile => ({
		id: profile?.pupil?.id || "",
		firstName: profile?.pupil?.firstName || "",
		lastName: profile?.pupil?.lastName || "",
		username: profile?.pupil?.username?.username || "",
		classId: profile?.pupil?.class?.id || "",
		classNumber: profile.pupil?.classNumber || 0,
		totalCredits: profile?.pupil?.pupilTotal?.totalCredits || 0,
		totalHighFives: profile?.pupil?.pupilTotal?.totalRegistrations || 0,
		pupilTags: profile?.pupil?.pupilTags || [],
	});
}

export default ProfileMapper;
