import { UIComponent } from "models/component";
import ModalStyle from "./modalStyle";

export interface ModalProps {
	title: string;
	icon: JSX.Element;
	body: JSX.Element | string;
	buttons: JSX.Element;
}

const Modal: UIComponent<ModalProps> = ({
	body, buttons, icon, title,
}) => (
	<ModalStyle>
		<div className="modal">
			<h1 className="title">{title}</h1>
			<div className="icon">{icon}</div>
			<p className="body">{body}</p>
			<div className="buttons">{buttons}</div>
		</div>
	</ModalStyle>
);

export default Modal;
