import { UIComponent } from "models/component";
import CountdownStyle from "./countdownStyle";
import useCountdown, { CountdownProps } from "./useCountdown";

const Countdown: UIComponent<CountdownProps> = (props) => {
	const { models: { secondsLeft } } = useCountdown(props);

	return (
		<CountdownStyle>
			{secondsLeft}
		</CountdownStyle>
	);
};

export default Countdown;
