/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LoginWithPasswordVariables = Types.Exact<{
  nfcTagId: Types.Scalars['String'];
  nfcTagType: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type LoginWithPassword = (
  { __typename?: 'Mutation' }
  & { nfcPasswordPupilLogin: (
    { __typename?: 'LoginResult' }
    & Pick<Types.LoginResult, 'token'>
  ) }
);


export const LoginWithPasswordDocument = gql`
    mutation loginWithPassword($nfcTagId: String!, $nfcTagType: String!, $password: String!) {
  nfcPasswordPupilLogin(
    nfcTagId: $nfcTagId
    nfcTagType: $nfcTagType
    password: $password
  ) {
    token
  }
}
    `;
export type LoginWithPasswordMutationFn = Apollo.MutationFunction<LoginWithPassword, LoginWithPasswordVariables>;

/**
 * __useLoginWithPassword__
 *
 * To run a mutation, you first call `useLoginWithPassword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithPassword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithPassword, { data, loading, error }] = useLoginWithPassword({
 *   variables: {
 *      nfcTagId: // value for 'nfcTagId'
 *      nfcTagType: // value for 'nfcTagType'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginWithPassword(baseOptions?: Apollo.MutationHookOptions<LoginWithPassword, LoginWithPasswordVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithPassword, LoginWithPasswordVariables>(LoginWithPasswordDocument, options);
      }
export type LoginWithPasswordHookResult = ReturnType<typeof useLoginWithPassword>;
export type LoginWithPasswordMutationResult = Apollo.MutationResult<LoginWithPassword>;
export type LoginWithPasswordMutationOptions = Apollo.BaseMutationOptions<LoginWithPassword, LoginWithPasswordVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    