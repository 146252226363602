/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetClassCreditsVariables = Types.Exact<{
  filter?: Types.Maybe<Types.ClassFilter>;
  paging?: Types.Maybe<Types.Paging>;
  sort?: Types.Maybe<Array<Types.Maybe<Types.Sort>> | Types.Maybe<Types.Sort>>;
}>;


export type GetClassCredits = (
  { __typename?: 'Query' }
  & { classes: Array<(
    { __typename?: 'Class' }
    & Pick<Types.Class, 'id' | 'title'>
    & { classTotal: (
      { __typename?: 'ClassTotal' }
      & Pick<Types.ClassTotal, 'id' | 'totalCredits'>
    ) }
  )> }
);


export const GetClassCreditsDocument = gql`
    query getClassCredits($filter: ClassFilter, $paging: Paging, $sort: [Sort]) {
  classes(filter: $filter, paging: $paging, sort: $sort) {
    id
    title
    classTotal {
      id
      totalCredits
    }
  }
}
    `;

/**
 * __useGetClassCredits__
 *
 * To run a query within a React component, call `useGetClassCredits` and pass it any options that fit your needs.
 * When your component renders, `useGetClassCredits` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassCredits({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetClassCredits(baseOptions?: Apollo.QueryHookOptions<GetClassCredits, GetClassCreditsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassCredits, GetClassCreditsVariables>(GetClassCreditsDocument, options);
      }
export function useGetClassCreditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassCredits, GetClassCreditsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassCredits, GetClassCreditsVariables>(GetClassCreditsDocument, options);
        }
export type GetClassCreditsHookResult = ReturnType<typeof useGetClassCredits>;
export type GetClassCreditsLazyQueryHookResult = ReturnType<typeof useGetClassCreditsLazyQuery>;
export type GetClassCreditsQueryResult = Apollo.QueryResult<GetClassCredits, GetClassCreditsVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    