/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LoginWithNfcVariables = Types.Exact<{
  nfcTagId: Types.Scalars['String'];
  validationCode: Types.Scalars['String'];
}>;


export type LoginWithNfc = (
  { __typename?: 'Mutation' }
  & { nfcPupilLogin: (
    { __typename?: 'LoginResult' }
    & Pick<Types.LoginResult, 'token'>
  ) }
);


export const LoginWithNfcDocument = gql`
    mutation loginWithNfc($nfcTagId: String!, $validationCode: String!) {
  nfcPupilLogin(
    nfcTagId: $nfcTagId
    nfcTagType: "HIGH_FIVE"
    validationCode: $validationCode
  ) {
    token
  }
}
    `;
export type LoginWithNfcMutationFn = Apollo.MutationFunction<LoginWithNfc, LoginWithNfcVariables>;

/**
 * __useLoginWithNfc__
 *
 * To run a mutation, you first call `useLoginWithNfc` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithNfc` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithNfc, { data, loading, error }] = useLoginWithNfc({
 *   variables: {
 *      nfcTagId: // value for 'nfcTagId'
 *      validationCode: // value for 'validationCode'
 *   },
 * });
 */
export function useLoginWithNfc(baseOptions?: Apollo.MutationHookOptions<LoginWithNfc, LoginWithNfcVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithNfc, LoginWithNfcVariables>(LoginWithNfcDocument, options);
      }
export type LoginWithNfcHookResult = ReturnType<typeof useLoginWithNfc>;
export type LoginWithNfcMutationResult = Apollo.MutationResult<LoginWithNfc>;
export type LoginWithNfcMutationOptions = Apollo.BaseMutationOptions<LoginWithNfc, LoginWithNfcVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    