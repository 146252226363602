import { UIComponent } from "models/component";

import { CreditsBarChartStyle, CreditsBarChartItem } from "./CreditsBarChartStyle";
import useCreditsBarChart, { CreditsBarChartProps } from "./useCreditsBarChart";

const CreditsBarChart: UIComponent<CreditsBarChartProps> = (props) => {
	const { bars } = useCreditsBarChart(props).models;

	return (
		<CreditsBarChartStyle>
			{bars.map((bar) => (
				<CreditsBarChartItem height={bar.height} key={`${Math.random()}${bar.value}`}>
					<p>{bar.value}</p>
					<div>
						<div />
					</div>
				</CreditsBarChartItem>
			))}
		</CreditsBarChartStyle>
	);
};

export default CreditsBarChart;
