import Colors from "style/colors";
import Layout from "style/layout";
import styled from "styled-components";

import TotalSustainableMovementsStyle from "./components/totalSustainableMovements/TotalSustainableMovementsStyle";

export default styled(Layout)`
	.project-metrics-container,
	.sign-in-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-right: 480px;
	}

	.project-metrics-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 4px;
		padding-top: 96px;
		padding-bottom: 96px;
		padding-left: 24px;
		background-color: ${Colors.Primary};
		color: ${Colors.White};

		h1 {
			font-size: 2.4em;
			font-weight: 900;
			margin-bottom: 48px;
		}
	}

	${TotalSustainableMovementsStyle} {
		margin-top: 54px;
		margin-bottom: -264px;
	}

	.homepage-five {
		position: absolute;
		right: 0;
		top: 36px;
		width: 480px;
	}
`;
