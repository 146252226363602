import event from "event";
import useAuthentication from "hooks/authentication/useAuthentication";
import { Reward } from "hooks/rewards/models/reward";
import useInterval from "hooks/useInterval";
import { InteractionLogic } from "models/logic";
import {
	useCallback, useContext, useEffect, useState,
} from "react";
import modalContext from "./modalContext";
import { Modal } from "./models/modal";

interface Operations {
	openModal: (modal: Modal) => void;
	closeModal: () => void;
}

interface Models {
	activeModal?: Modal;
	logoutDelay: number;
}

export interface ModalEvents {
	"idled": () => void;
}

const useModals: InteractionLogic<{}, Operations, Models> = () => {
	const SECOND: number = 1000;
	const IDLE_DELAY: number = window.appEnvironment.ENVIRONMENT === "localhost" ? (60 * 60) * SECOND : 60 * SECOND;
	const LOGOUT_DELAY: number = 10 * SECOND;

	const { activeModal, setActiveModal } = useContext(modalContext);
	const { models: { isLoggedIn } } = useAuthentication();
	const [isIdleTimerActive, setIsIdleTimerActive] = useState<boolean>(isLoggedIn);
	const [idleTime, setIdleTime] = useState<number>(0);

	const openModal: Operations["openModal"] = (modal) => {
		if (activeModal && activeModal.type !== modal.type) {
			setActiveModal(modal);
		}
	};

	const closeModal: Operations["closeModal"] = () => {
		setActiveModal(undefined);
	};

	const resetIdleTime = (): void => (
		setIdleTime(0)
	);

	const activateIdleTimer = useCallback((): void => {
		setIsIdleTimerActive(true);
	}, []);

	const deactivateIdleTimer = useCallback((): void => {
		setIsIdleTimerActive(false);
		resetIdleTime();
	}, []);

	if (idleTime === IDLE_DELAY / SECOND) {
		openModal({ type: "idle" });
	}

	if (idleTime === (IDLE_DELAY + LOGOUT_DELAY) / SECOND) {
		closeModal();
		event.emit("idled");
	}

	if (activeModal?.type === "idle" && idleTime < IDLE_DELAY / SECOND) {
		closeModal();
	}

	useInterval(
		() => setIdleTime(idleTime + 1),
		isIdleTimerActive ? SECOND : null,
	);

	useEffect(() => {
		event.on("login", activateIdleTimer);
		event.on("logout", deactivateIdleTimer);

		return () => {
			event.removeListener("login", activateIdleTimer);
			event.removeListener("logout", deactivateIdleTimer);
		};
	}, [activateIdleTimer, deactivateIdleTimer]);

	useEffect(() => {
		window.addEventListener("mousedown", resetIdleTime);

		return () => {
			window.removeEventListener("mousedown", resetIdleTime);
		};
	}, []);

	useEffect(() => {
		const openOrderConfirmedModal = (reward: Reward) => openModal({ type: "orderConfirmed", collectionInstructions: reward.collectionInstructions });
		event.on("orderConfirmed", openOrderConfirmedModal);

		return () => {
			event.removeListener("orderConfirmed", openOrderConfirmedModal);
		};
	}, []);

	return {
		models: {
			activeModal,
			logoutDelay: LOGOUT_DELAY,
		},
		operations: {
			openModal,
			closeModal,
		},
	};
};

export default useModals;
