import Colors from "style/colors";
import styled from "styled-components";
import { AlertStyleProps } from "./useAlert";

const colors = {
	error: {
		Main: Colors.Red,
		Light: Colors.RedLight,
	},
	info: {
		Main: Colors.Primary,
		Light: Colors.PrimaryLight,
	},
};

const AlertStyle = styled.div`
	width: 100%;
	padding: 10px 16px;
	font-weight: 700;
	border-radius: 4px;
	margin-bottom: 24px;
	color: ${(props: AlertStyleProps) => colors[props.type].Main};
	border: 2px solid ${(props: AlertStyleProps) => colors[props.type].Main};
	background-color: ${(props: AlertStyleProps) => colors[props.type].Light};
`;

export default AlertStyle;
