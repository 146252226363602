import Button from "components/button/Button";
import Modal, { ModalProps } from "components/modal/modal";
import useModals from "hooks/useModals/useModals";
import { PageComponent } from "models/component";
import { Modal as ActiveModal, OrderConfirmedModal } from "hooks/useModals/models/modal";
import useAuthentication from "hooks/authentication/useAuthentication";
import nl from "translations/nl";
import partyEmoji from "../../assets/images/party-emoji.png";
import Countdown from "./components/countdown/countdown";

const ModalPage: PageComponent = () => {
	const {
		models: { activeModal, logoutDelay },
		operations: { closeModal },
	} = useModals();
	const {
		operations: { logout },
	} = useAuthentication();

	const logoutAndCloseModal = (): void => {
		logout();
		closeModal();
	};

	const isOrderConfirmedModal = (modal: ActiveModal): modal is OrderConfirmedModal => "collectionInstructions" in modal;

	const content: { [modal in ActiveModal["type"]]: ModalProps } = {
		idle: {
			title: nl.modal.idle.title,
			icon: <Countdown delay={logoutDelay} />,
			body: nl.modal.idle.body,
			buttons: <Button buttonType="primary" onClick={closeModal}>{nl.modal.idle.stillHere}</Button>,
		},
		orderConfirmed: {
			title: nl.modal.orderConfirmed.title,
			icon: <img src={partyEmoji} alt="test" width="158" height="158" />,
			body: (
				<p>
					{nl.modal.orderConfirmed.body}
					<br />
					{activeModal && isOrderConfirmedModal(activeModal) ? activeModal.collectionInstructions : ""}
				</p>
			),
			buttons: (
				<>
					<Button buttonType="danger" onClick={logoutAndCloseModal}>{nl.modal.orderConfirmed.stopSession}</Button>
					<Button buttonType="secondary" onClick={closeModal}>{nl.modal.orderConfirmed.continueSession}</Button>
				</>
			),
		},
	};

	if (!activeModal) {
		return null;
	}

	return <Modal {...content[activeModal.type]} />;
};

export default ModalPage;
