import event from "event";
import { Routes } from "hooks/routes/models/routes";
import { InteractionLogic } from "models/logic";
import { useEffect } from "react";
import { useHistory } from "react-router";

interface Operations {
	goToHome: () => void;
	goToProfile: () => void;
	goToWebshop: () => void;
	goToOrderConfirmation: (type: string, id: string) => void;
}

type Models = Routes;

const useRoutes: InteractionLogic<{}, Operations, Models> = () => {
	const history = useHistory();

	const routes: Routes = {
		home: "/",
		profile: "/profile",
		webshop: "/webshop",
		order: {
			confirm: "/order/:type/:id",
		},
	};

	const goToHome: Operations["goToHome"] = () => {
		history.push(routes.home);
	};
	const goToProfile: Operations["goToProfile"] = () => {
		history.push(routes.profile);
	};

	const goToWebshop: Operations["goToWebshop"] = () => {
		history.push(routes.webshop);
	};

	const goToOrderConfirmation: Operations["goToOrderConfirmation"] = (type, rewardId) => {
		history.push(routes.order.confirm.replace(":id", rewardId).replace(":type", type));
	};

	useEffect(() => {
		event.on("login", goToProfile);

		return () => {
			event.removeListener("login", goToProfile);
		};
	}, [goToProfile]);

	useEffect(() => {
		event.on("logout", goToHome);

		return () => {
			event.removeListener("logout", goToHome);
		};
	}, [goToHome]);

	useEffect(() => {
		event.on("orderPlaced", goToOrderConfirmation);

		return () => {
			event.removeListener("orderPlaced", goToOrderConfirmation);
		};
	});

	useEffect(() => {
		event.on("shopItemOrderPlaced", goToOrderConfirmation);

		return () => {
			event.removeListener("shopItemOrderPlaced", goToOrderConfirmation);
		};
	});

	useEffect(() => {
		event.on("orderConfirmed", goToProfile);

		return () => {
			event.removeListener("orderConfirmed", goToProfile);
		};
	}, [goToProfile]);

	useEffect(() => {
		event.on("shopItemOrderConfirmed", goToProfile);

		return () => {
			event.removeListener("shopItemOrderConfirmed", goToProfile);
		};
	}, [goToProfile]);

	return {
		models: routes,
		operations: {
			goToHome,
			goToProfile,
			goToWebshop,
			goToOrderConfirmation,
		},
	};
};

export default useRoutes;
