import { UILogic } from "models/logic";
import { Children, ReactNode } from "react";
import { SideComponent } from "./pageWithFormStyle";

export interface PageWithFormProps {
	children: ReactNode;
}

type Props = PageWithFormProps;

interface Models {
	form?: ReactNode;
	side?: ReactNode;
}

const usePageWithForm: UILogic<Props, undefined, Models> = ({ children }) => {
	const [form, side = <SideComponent />] = Children.toArray(children);

	return {
		models: {
			form,
			side,
		},
		operations: undefined,
	};
};

export default usePageWithForm;
