import dayjs from "dayjs";
import decode from "jwt-decode";

interface TokenPayload {
	sub: string;
	exp: number;
	iat: number;
}

enum LocalStorageKeys {
	Token = "token",
	AnonymousToken = "anonymousToken",
}

const isTokenExpired = (token: string): boolean => {
	const decoded = decode<TokenPayload>(token);

	if (!decoded.exp) {
		return true;
	}

	return dayjs().isAfter(dayjs.unix(decoded.exp));
};

class TokenManager {
	public static get token() {
		return localStorage.getItem(LocalStorageKeys.Token) || "";
	}

	public static get tokenExpired() {
		return this.token ? isTokenExpired(this.token) : true;
	}

	public static set token(token: string) {
		if (token) {
			localStorage.setItem(LocalStorageKeys.Token, token);
		} else {
			localStorage.removeItem(LocalStorageKeys.Token);
		}
	}

	public static get anonymousToken() {
		return window.environment.anonymousToken || "";
	}

	public static get anonymousTokenExpired() {
		return this.anonymousToken ? isTokenExpired(this.anonymousToken) : true;
	}

	public static set anonymousToken(token: string) {
		localStorage.setItem(LocalStorageKeys.AnonymousToken, token);
	}
}

export default TokenManager;
