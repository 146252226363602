import { UIComponent } from "models/component";
import ButtonStyle from "./ButtonStyle";
import { ButtonProps } from "./useButton";

const Button: UIComponent<ButtonProps> = ({
	disabled, buttonType = "primary", onClick, children,
}) => (
	<ButtonStyle buttonType={buttonType} disabled={disabled} onClick={onClick}>
		{children}
	</ButtonStyle>
);

export default Button;
