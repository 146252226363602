import { UILogic } from "models/logic";
import { useEffect, useState } from "react";

export interface CountdownProps {
	delay: number;
}

interface Models {
	secondsLeft: number;
}

const useCountdown: UILogic<CountdownProps, {}, Models> = ({ delay }) => {
	const SECOND = 1000;
	const [secondsLeft, setSecondsLeft] = useState<Models["secondsLeft"]>(delay / SECOND);
	let timer: NodeJS.Timeout;

	useEffect(() => {
		clearInterval(timer);
		timer = setTimeout(() => {
			if (secondsLeft > 0) {
				setSecondsLeft(secondsLeft - 1);
			}
		}, SECOND);
	}, [delay, secondsLeft]);

	return {
		models: {
			secondsLeft,
		},
		operations: {},
	};
};

export default useCountdown;
