import { InteractionLogic } from "models/logic";
import { clients } from "hooks/client/clientConfig";

export interface AnonymousTokenClientConfig {
	clientId: string;
	clientSecret: string;
}

export interface Client {
	title: string;
	urls: Record<string, AnonymousTokenClientConfig>;
}

interface Operations {
}

type Models = {
	client: Client;
	anonymousTokenClientConfig: AnonymousTokenClientConfig;
};

const useClient: InteractionLogic<{}, Operations, Models> = () => {
	const currentClientKey = Object.keys(clients).find((key) => !!clients[key].urls[window.location.host]) || "default";

	const client = clients[currentClientKey];
	const anonymousTokenClientConfig = client && client.urls[window.location.host];

	return ({
		models: {
			client,
			anonymousTokenClientConfig,
		},
		operations: {
		},
	});
};

export default useClient;
