import Colors from "style/colors";
import styled from "styled-components";

export const CreditsBarChartStyle = styled.div`
	display: flex;
`;

export interface CreditsBarChartItemProps {
	height: number;
}

export const CreditsBarChartItem = styled.div<CreditsBarChartItemProps>`
	flex: 1;
	height: 156px;
	display: flex;
	flex-direction: column;
	margin: 0px 4px;
	justify-content: space-between;

	div {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		
		& div {
			background-color: ${Colors.Primary};
			border-radius: 8px; 
			height: ${(props) => props.height}%;
			opacity: ${(props) => props.height / 100};
		}
	}

	p {
		color: ${Colors.Primary};
		text-align: center;
		font-size: 1.4em;
		font-weight: 700;
		margin-bottom: 24px;
	}
`;
