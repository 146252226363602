import Button from "components/button/Button";
import dayjs from "dayjs";
import useAuthentication from "hooks/authentication/useAuthentication";
import useClasses from "hooks/classes/useClasses";
import useProfile from "hooks/profile/useProfile";
import usePupilCredits from "hooks/pupilCredits/usePupilCredits";
import useRoutes from "hooks/routes/useRoutes";
import { PageComponent } from "models/component";
import { SortOrder } from "models/schema.d";
import ProfilePageStyle from "pages/profilePage/ProfilePageStyle";
import { Link } from "react-router-dom";
import nl from "translations/nl";

import ClassCreditsLeaderboard from "./components/classCreditsLeaderboard/ClassCreditsLeaderboard";
import CreditsBarChart from "./components/creditsBarChart/CreditsBarChart";
import ProfileInfo from "./components/profileInfo/ProfileInfo";
import { useGetClassCredits } from "./models/getClassCredits";
import { useGetProfile } from "./models/getProfile";
import { useGetPupilCredits } from "./models/getPupilCredits";

const ProfilePage: PageComponent = () => {
	const { logout } = useAuthentication().operations;
	const { models: routes } = useRoutes();

	const { profile, loading } = useProfile(useGetProfile()).models;
	const {
		classId,
		username,
		totalCredits,
		totalHighFives,
	} = profile;

	const { pupilCreditTotalsForLastSevenDays } = usePupilCredits(useGetPupilCredits({
		variables: {
			sort: [{
				field: "createdAt",
				order: SortOrder.Desc,
			}],
			filter: {
				createdAt: {
					gte: dayjs().subtract(6, "day").startOf("day").toISOString(),
					lte: dayjs().endOf("day").toISOString(),
				},
			},
		},
	})).models;

	const { classes } = useClasses(useGetClassCredits()).models;

	return (
		<ProfilePageStyle>
			<div className="left">
				<ProfileInfo
					username={username}
					totalCredits={totalCredits}
					totalHighFives={totalHighFives}
					loading={loading}
				/>

				<Link to={routes.webshop}>
					<Button>{nl.profile.link.webshop}</Button>
				</Link>

				<Button onClick={logout} buttonType="danger">{nl.buttons.logout}</Button>
			</div>
			<div className="right">
				<h2>{nl.profile.charts.titles.personalCredits}</h2>
				<CreditsBarChart values={pupilCreditTotalsForLastSevenDays} />
				<h2>{nl.profile.charts.titles.classCredits}</h2>
				<ClassCreditsLeaderboard classesWithCredits={classes} activeId={classId} />
			</div>
		</ProfilePageStyle>
	);
};

export default ProfilePage;
