import Colors from "style/colors";
import styled from "styled-components";

const TotalSustainableMovementsStyle = styled.div`
	div {
		display: flex;
	}

	div > p {
		color: ${Colors.Black};
		font-weight: 700;
		font-size: 4em;
		margin: 0px 4px;
		border-radius: 4px;
		padding: 40px 30px;
		background-color: ${Colors.White};
		box-shadow: 0px 5px 15px ${Colors.BlackTransparant};
	}

	& > p {
		color: ${Colors.Primary};
		font-size: 1.4em;
		text-align: center;
		margin-top: 36px;
	}
`;

export default TotalSustainableMovementsStyle;
