/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateOneShopItemOrderVariables = Types.Exact<{
  assignedShopItemId: Types.Scalars['String'];
}>;


export type CreateOneShopItemOrder = (
  { __typename?: 'Mutation' }
  & { createOneShopItemOrder: (
    { __typename?: 'ShopItemOrder' }
    & Pick<Types.ShopItemOrder, 'id'>
  ) }
);


export const CreateOneShopItemOrderDocument = gql`
    mutation createOneShopItemOrder($assignedShopItemId: String!) {
  createOneShopItemOrder(assignedShopItemId: $assignedShopItemId) {
    id
  }
}
    `;
export type CreateOneShopItemOrderMutationFn = Apollo.MutationFunction<CreateOneShopItemOrder, CreateOneShopItemOrderVariables>;

/**
 * __useCreateOneShopItemOrder__
 *
 * To run a mutation, you first call `useCreateOneShopItemOrder` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneShopItemOrder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneShopItemOrder, { data, loading, error }] = useCreateOneShopItemOrder({
 *   variables: {
 *      assignedShopItemId: // value for 'assignedShopItemId'
 *   },
 * });
 */
export function useCreateOneShopItemOrder(baseOptions?: Apollo.MutationHookOptions<CreateOneShopItemOrder, CreateOneShopItemOrderVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneShopItemOrder, CreateOneShopItemOrderVariables>(CreateOneShopItemOrderDocument, options);
      }
export type CreateOneShopItemOrderHookResult = ReturnType<typeof useCreateOneShopItemOrder>;
export type CreateOneShopItemOrderMutationResult = Apollo.MutationResult<CreateOneShopItemOrder>;
export type CreateOneShopItemOrderMutationOptions = Apollo.BaseMutationOptions<CreateOneShopItemOrder, CreateOneShopItemOrderVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    