import { UIComponent } from "models/component";
import ProjectMetricStyle from "pages/homePage/components/projectMetric/ProjectMetricStyle";
import nl from "translations/nl";

interface Props {
	icon: string;
	value: string;
	label: keyof typeof nl.homePage.projectMetrics;
}

const ProjectMetric: UIComponent<Props> = ({ icon, value, label }) => (
	<ProjectMetricStyle>
		<img src={icon} alt="" />
		<p>{value}</p>
		<p>{nl.homePage.projectMetrics[label]}</p>
	</ProjectMetricStyle>
);

export default ProjectMetric;
