import { UILogic } from "models/logic";

export interface CreditsBarChartProps {
	values: number[]
}

type Props = CreditsBarChartProps;

interface Models {
	bars: {
		height: number;
		value: number;
	}[]
}

interface Operations {}

const useCreditsBarChart: UILogic<Props, Operations, Models> = ({ values }) => {
	const maxValue = Math.max(...values);

	const bars = values.map((value) => ({
		height: value === 0 ? 0 : (value / maxValue) * 100,
		value,
	}));

	return ({
		models: { bars },
		operations: { },
	});
};

export default useCreditsBarChart;
