import Colors from "style/colors";
import styled from "styled-components";
import ProfileMetricStyle from "../profileMetric/ProfileMetricStyle";

const ProfileInfoStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	.greeting {
		width: 100%;
		border-radius: 8px;
		background-color: ${Colors.Primary};
		padding-top: 24px;
		padding-bottom: 64px;
		color: ${Colors.White};
		text-align: center;
		margin-top: -5px;

		p {
			font-size: 1.4em;
		}
	}

	.profile-metric-container {
		display: flex;
		width: 100%;
		margin-top: -36px;
		margin-bottom: 36px;
		padding: 0px 48px;

		${ProfileMetricStyle} {
			flex: 1;
		}
	}

	& > img {
		width: 196px;
	}
`;

export default ProfileInfoStyle;
