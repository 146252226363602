import { SchoolyearTotal } from "models/schema";
import { InteractionLogic } from "../../models/logic";
import { ApolloAPI } from "../../models/API";

import { ProjectMetrics } from "./models/projectMetrics.d";

type API = ApolloAPI<"schoolyears" | "moduleRegistrationsCount" | "pupilsCount">;

interface Operations {
}

type Models = ProjectMetrics;

const useProjectMetrics: InteractionLogic<API, Operations, Models> = (projectMetricsAPI) => {
	let totalDistance = 0;
	let totalCO2 = 0;
	let totalFuel = 0;
	let totalMovements = 0;
	let totalPupils = 0;

	if (projectMetricsAPI && projectMetricsAPI.data) {
		const { moduleRegistrationsCount, pupilsCount, schoolyears } = projectMetricsAPI.data;

		const [schoolyear] = schoolyears || [{}];

		if (schoolyear && schoolyear.schoolyearTotal) {
			totalDistance = Math.round((schoolyear.schoolyearTotal as SchoolyearTotal).totalDistance / 1000);
			totalCO2 = Math.round((schoolyear.schoolyearTotal as SchoolyearTotal).totalCO2 / 1000);
			totalFuel = Math.round((schoolyear.schoolyearTotal as SchoolyearTotal).totalFuel);
		}

		totalMovements = moduleRegistrationsCount || 0;
		totalPupils = pupilsCount || 0;
	}

	return ({
		models: {
			totalDistance: `${totalDistance} km`,
			totalCO2: `${totalCO2} kg`,
			totalFuel: `${totalFuel} liter`,
			totalMovements,
			totalPupils,
		},
		operations: {},
	});
};

export default useProjectMetrics;
