/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetPupilCreditsVariables = Types.Exact<{
  filter?: Types.Maybe<Types.PupilCreditFilter>;
  paging?: Types.Maybe<Types.Paging>;
  sort?: Types.Maybe<Array<Types.Maybe<Types.Sort>> | Types.Maybe<Types.Sort>>;
}>;


export type GetPupilCredits = (
  { __typename?: 'Query' }
  & { pupilCredits: Array<(
    { __typename?: 'PupilCredit' }
    & Pick<Types.PupilCredit, 'id' | 'type' | 'credit' | 'createdAt' | 'description'>
  )> }
);


export const GetPupilCreditsDocument = gql`
    query getPupilCredits($filter: PupilCreditFilter, $paging: Paging, $sort: [Sort]) {
  pupilCredits(filter: $filter, paging: $paging, sort: $sort) {
    id
    type
    credit
    createdAt
    description
  }
}
    `;

/**
 * __useGetPupilCredits__
 *
 * To run a query within a React component, call `useGetPupilCredits` and pass it any options that fit your needs.
 * When your component renders, `useGetPupilCredits` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPupilCredits({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPupilCredits(baseOptions?: Apollo.QueryHookOptions<GetPupilCredits, GetPupilCreditsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPupilCredits, GetPupilCreditsVariables>(GetPupilCreditsDocument, options);
      }
export function useGetPupilCreditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPupilCredits, GetPupilCreditsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPupilCredits, GetPupilCreditsVariables>(GetPupilCreditsDocument, options);
        }
export type GetPupilCreditsHookResult = ReturnType<typeof useGetPupilCredits>;
export type GetPupilCreditsLazyQueryHookResult = ReturnType<typeof useGetPupilCreditsLazyQuery>;
export type GetPupilCreditsQueryResult = Apollo.QueryResult<GetPupilCredits, GetPupilCreditsVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    