import { UIComponent } from "models/component";
import TotalSustainableMovementsStyle from "./TotalSustainableMovementsStyle";
import useTotalSustainableMovements, { TotalSustainableMovementsProps } from "./useTotalSustainableMovements";

const TotalSustainableMovements: UIComponent<TotalSustainableMovementsProps> = (props) => {
	const { totalMovementsCharacters, label } = useTotalSustainableMovements(props).models;

	return (
		<TotalSustainableMovementsStyle>
			<div>
				{totalMovementsCharacters.map((value) => <p key={`${value}${Math.random()}`}>{value}</p>)}
			</div>
			<p>{label}</p>
		</TotalSustainableMovementsStyle>
	);
};

export default TotalSustainableMovements;
