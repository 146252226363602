import { UILogic } from "models/logic";
import nl from "translations/nl";

export interface TotalSustainableMovementsProps {
	totalMovements: number;
	totalPupils: number;
}

type Props = TotalSustainableMovementsProps;

interface Models {
	totalMovementsCharacters: string[];
	label: string;
}

interface Operations {
}

const useTotalSustainableMovements: UILogic<Props, Operations, Models> = (props) => {
	const { totalMovements, totalPupils } = props;

	const totalMovementsCharacters = (totalMovements > 0 && totalMovements.toString().split("")) || ["0", "0", "0"];

	const { labelPrefix, labelSuffix } = nl.homePage.totalSustainableMovements;
	const label = `${labelPrefix} ${totalPupils} ${labelSuffix}`;

	return ({
		models: {
			totalMovementsCharacters,
			label,
		},
		operations: {},
	});
};

export default useTotalSustainableMovements;
