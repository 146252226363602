import Colors from "style/colors";
import styled from "styled-components";

export const ClassCreditsLeaderboardStyle = styled.div`
	width: 100%;
`;

export const ClassCreditsLeaderboardItem = styled.div`
	justify-content: space-between;
	border-top: 1px solid ${Colors.BlackTransparant};
	padding: 24px 14px;

	&.active {
		border: none;
		background-color: ${Colors.PrimaryLight};
		border-radius: 8px;
	}

	&,
	& div {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	p {
		font-size: 1.2em;
		color: ${Colors.Primary};
		font-weight: 700;
	}

	& > div:first-of-type {
		p {
			margin-right: 36px;
		}

		p:first-of-type {
			color: ${Colors.Black};
		}
	}

	& > div:nth-of-type(2) {
		p {
			margin-right: 12px;
			color: ${Colors.Black};
		}
	}

	img {
		width: 36px;
		height: 36px;
	}
`;
