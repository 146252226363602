import { UIComponent } from "models/component";
import nl from "translations/nl";
import RewardListItem from "../rewardListItem/rewardListItem";
import OrderHistorySectionStyle from "./orderHistorySectionStyle";

type PurchasedReward = {
	name: string;
	price: number;
};

interface OrderHistorySectionProps {
	purchasedRewards: PurchasedReward[];
	isLoading?: boolean;
}

const OrderHistorySection: UIComponent<OrderHistorySectionProps> = ({ purchasedRewards, isLoading = false }) => {
	const renderRewardListItem = (reward: PurchasedReward): JSX.Element => (
		<RewardListItem {...reward} />
	);

	const placeholderReward: PurchasedReward = {
		name: nl.loading,
		price: 0,
	};

	return (
		<OrderHistorySectionStyle>
			<p className="title">{nl.webshop.yourPreviousRewards}</p>
			<ul>
				{isLoading
					? renderRewardListItem(placeholderReward)
					: purchasedRewards.map(renderRewardListItem)}
			</ul>
		</OrderHistorySectionStyle>
	);
};

export default OrderHistorySection;
