import { UIComponent } from "models/component";
import PageWithFormStyle from "./pageWithFormStyle";
import usePageWithForm, { PageWithFormProps } from "./usePageWithForm";

const PageWithForm: UIComponent<PageWithFormProps> = (props) => {
	const { models: { form, side } } = usePageWithForm(props);

	return (
		<PageWithFormStyle>
			<div className="form">{form}</div>
			<div className="side">{side}</div>
		</PageWithFormStyle>
	);
};

export default PageWithForm;
