import Alert from "components/alert/Alert";
import Button from "components/button/Button";
import { Field, Form, Formik } from "formik";
import { UIComponent } from "models/component";
import { Link } from "react-router-dom";
import nl from "translations/nl";
import ConfirmFormStyle from "./confirmFormStyle";

interface Operations {
	onSubmit: (password: string) => void;
}

interface ConfirmFormProps {
	isLoading: boolean;
	hasError: boolean;
	cancelUrl: string;
	operations: Operations;
}

enum Fields {
	Password = "password",
}

const ConfirmForm: UIComponent<ConfirmFormProps> = ({
	operations, hasError, isLoading, cancelUrl,
}) => {
	const onSubmit = (values: any): void => {
		operations.onSubmit(values.Fields.Password);
	};

	return (
		<ConfirmFormStyle>
			<Formik initialValues={{ password: "" }} onSubmit={onSubmit}>
				<Form>
					<h1>{nl.webshop.form.title}</h1>
					<label htmlFor={Fields.Password}>{nl.login.form.labels.password}</label>
					<Field type="password" name="Fields.Password" />

					<p className="helper-text">{nl.login.form.labels.forgotPassword}</p>
					{hasError && <Alert message={nl.login.form.errors.generalMessage} type="error" />}

					<Button disabled={isLoading}>{nl.buttons.confirm}</Button>
					<Link to={cancelUrl}>
						<Button buttonType="secondary">
							{nl.buttons.cancel}
						</Button>
					</Link>
				</Form>
			</Formik>
		</ConfirmFormStyle>
	);
};

export default ConfirmForm;
