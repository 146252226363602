import useRoutes from "hooks/routes/useRoutes";
import { FunctionComponent } from "react";
import { Switch } from "react-router";
import HomePage from "pages/homePage/HomePage";
import ProfilePage from "pages/profilePage/ProfilePage";
import Route from "components/route/Route";
import WebshopPage from "pages/webshopPage/webshopPage";
import ConfirmPage from "pages/confirmPage/confirmPage";

const Routes: FunctionComponent = () => {
	const { models: routes } = useRoutes();

	return (
		<Switch>
			<Route exact path={routes.home} component={HomePage} />
			<Route private exact path={routes.profile} component={ProfilePage} />
			<Route private exact path={routes.webshop} component={WebshopPage} />
			<Route private exact path={routes.order.confirm} component={ConfirmPage} />
		</Switch>
	);
};

export default Routes;
