import { UIComponent } from "models/component";
import Credits from "assets/svg/credits.svg";

import { ClassCreditsLeaderboardItem, ClassCreditsLeaderboardStyle } from "./ClassCreditsLeaderboardStyle";
import useClassCreditsLeaderboard, { ClassCreditsLeaderboardProps } from "./useClassCreditsLeaderboard";

const ClassCreditsLeaderboard: UIComponent<ClassCreditsLeaderboardProps> = (props) => {
	const { sortedClassesWithCredits } = useClassCreditsLeaderboard(props).models;

	return (
		<ClassCreditsLeaderboardStyle>
			{sortedClassesWithCredits.map(({ id, title, classTotal }, i) => (
				<ClassCreditsLeaderboardItem className={id === props.activeId ? "active" : ""} key={id}>
					<div>
						<p>{`${i + 1}.`}</p>
						<p>{title}</p>
					</div>
					<div>
						<p>{classTotal.totalCredits}</p>
						<img src={Credits} alt="credits" />
					</div>
				</ClassCreditsLeaderboardItem>
			))}
		</ClassCreditsLeaderboardStyle>
	);
};

export default ClassCreditsLeaderboard;
