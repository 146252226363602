import styled from "styled-components";
import Colors from "./colors";

/**
 * This is the base layout for every page component.
 * It enforces a consistent wrapper for the content of each page.
 */

const Layout = styled.main`
	min-height: 100vh;
	max-height: 100vh;
	overflow-y: hidden;
	padding: 10px;
	background-color: ${Colors.PrimaryLight};

	label {
		display: block;
		margin-bottom: 12px;
	}

	select,
	input {
		width: 100%;
		height: 36px;
		border-radius: 4px;
		border: none;
		margin-bottom: 24px;
		font-weight: 700;
		font-size: 1.0em;
		color: ${Colors.Primary};
	}

	select {
			padding: 0px 12px;

			option.placeholder {
				font-weight: 400;
				color: ${Colors.BlackTransparant};
			}
	}

	input {
		padding: 0px 16px;
	}
`;

export default Layout;
