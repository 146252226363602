import styled from "styled-components";

const CountdownStyle = styled.div`
	width: 200px;
	height: 200px;
	font-size: 4em;
	text-align: center;
	font-weight: bold;
`;

export default CountdownStyle;
