/* eslint-disable jsx-a11y/no-autofocus */
import { UIComponent } from "models/component";
import { ChangeEvent } from "react";
import useNfcInput from "./useNfcInput";

interface NfcInputProps {
	submit: (value: string) => void;
}

const NfcInput: UIComponent<NfcInputProps> = (props) => {
	const { submit } = props;
	const { models: { ref } } = useNfcInput(props);

	return (
		<input
			ref={ref}
			onFocus={(e) => window.appEnvironment.ENVIRONMENT !== "localhost" && e.target.blur()}
			type="text"
			autoFocus
			hidden={window.appEnvironment.ENVIRONMENT !== "localhost"}
			onChange={(e: ChangeEvent<HTMLInputElement>) => submit(e.target.value || "")}
		/>
	);
};

export default NfcInput;
