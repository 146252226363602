import ProfileFive from "assets/images/profile-five.png";
import Credits from "assets/svg/credits.svg";
import Hand from "assets/svg/hand.svg";
import { UIComponent } from "models/component";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Colors from "style/colors";
import nl from "translations/nl";

import ProfileMetric from "../profileMetric/ProfileMetric";
import ProfileInfoStyle from "./ProfileInfoStyle";

interface Props {
	loading: boolean;
	username: string;
	totalCredits: number;
	totalHighFives: number;
}

const ProfileInfo: UIComponent<Props> = ({
	username, totalCredits, totalHighFives, loading,
}) => (
	<ProfileInfoStyle>
		<img src={ProfileFive} alt="Five" />
		<div className="greeting">
			<p>{nl.profile.hello}</p>
			<h1>{!loading ? username : <SkeletonTheme color={Colors.Primary} highlightColor={Colors.PrimaryLight}><Skeleton width="75%" /></SkeletonTheme>}</h1>
		</div>

		<div className="profile-metric-container">
			<ProfileMetric icon={Credits} label={nl.profile.metrics.labels.totalCredits} value={totalCredits} />
			<ProfileMetric icon={Hand} label={nl.profile.metrics.labels.totalHighFives} value={totalHighFives || 0} />
		</div>
	</ProfileInfoStyle>
);

export default ProfileInfo;
