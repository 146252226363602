import ButtonStyle from "components/button/ButtonStyle";
import Colors from "style/colors";
import Layout from "style/layout";
import styled from "styled-components";

export default styled(Layout)`
	display: grid;
	grid-template-columns: 1fr 1fr;

	.left {
		align-self: center;
		margin-bottom: 48px;
		padding-left: 56px;

		${ButtonStyle} {
			margin-bottom: 16px;
		}
	}

	.right {
		background-color: ${Colors.White};
		border-radius: 8px;
		margin-left: 48px;
		padding: 48px;
		max-height: calc(100vh - 20px);
		overflow: scroll;

		h2 {
			text-align: center;
			color: ${Colors.Primary};
			margin-bottom: 36px;
			font-size: 1.25em;

			&:not(:first-of-type) {
				margin-top: 56px;
			}
		}
	}
`;
