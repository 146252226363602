import Colors from "style/colors";
import styled from "styled-components";

const WalletStyle = styled.div`
	background-color: ${Colors.White};
	border-radius: 8px;
	padding: 24px 0 32px;
	color: ${Colors.Primary};

	.title {
		font-size: 1.4em;
		margin: 0 24px;
		text-align: center;
	}

	.coins {
		display: grid;
		grid-template-columns: 1fr auto auto 1fr;
		grid-template-areas: ". amount icon .";
		column-gap: 16px;
		align-items: center;

		.amount {
			grid-area: amount;
			font-weight: bold;
			font-size: 3.3em;
		}

		img {
			grid-area: icon;
		}
	}
`;

export default WalletStyle;
