import ButtonStyle from "components/button/ButtonStyle";
import Colors from "style/colors";
import styled from "styled-components";

const ConfirmFormStyle = styled.div`
	form {
		display: flex;
		flex-direction: column;
	}

	h1 {
		color: ${Colors.Primary};
		text-align: center;
		padding: 0 15%;
	}

	${ButtonStyle} {
		margin-bottom: 16px;
	}

	.helper-text {
		color: ${Colors.Primary};
		font-size: 1.1em;
		margin-bottom: 14px;
	}
`;

export default ConfirmFormStyle;
