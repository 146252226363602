import ButtonStyle from "components/button/ButtonStyle";
import Colors from "style/colors";
import styled from "styled-components";

const RewardCardStyle = styled.div`
	border-radius: 8px;
	background-color: ${Colors.White};
	padding: 16px;
	display: grid;
	grid-auto-flow: row;
	row-gap: 24px;
	height: 456px;

	.thumbnail {
		width: 100%;
		height: 248px;
		object-fit: cover;
		border-radius: 8px;
	}

	.name {
		color: ${Colors.Primary};
		font-size: 1.4em;
	}

	.note {
		color: ${Colors.Black};
		font-size: 1em;
	}

	${ButtonStyle} {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			margin-left: 16px;
		}
	}
`;

export default RewardCardStyle;
