import {
	ApolloClient, from, HttpLink, InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import TokenManager from "hooks/authentication/tokenManager";

const cache = new InMemoryCache();

const httpLink = new HttpLink({
	uri: window.appEnvironment.API_URL,
	fetch: (...args) => fetch(...args),
});

const authLink = setContext((_, previousContext) => {
	const { headers, useAnonymousToken } = previousContext;

	let token = TokenManager.anonymousToken;

	if (TokenManager.token && !useAnonymousToken) {
		token = TokenManager.token;
	}

	if (!token) {
		return headers;
	}

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : null,
		},
	};
});

const link = from([
	authLink,
	httpLink,
]);

export default new ApolloClient({ cache, link });
