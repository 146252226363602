import Fuel from "assets/svg/fuel.svg";
import Heart from "assets/svg/heart.svg";
import Toxic from "assets/svg/toxic.svg";
import { UIComponent } from "models/component";
import ProjectMetric from "pages/homePage/components/projectMetric/ProjectMetric";
import ProjectMetricsSectionStyle from "pages/homePage/components/projectMetricsSection/ProjectMetricsSectionStyle";

export interface ProjectMetricsSectionProps {
	totalDistance: string;
	totalCO2: string;
	totalFuel: string;
}

const ProjectMetricsSection: UIComponent<ProjectMetricsSectionProps> = (props) => {
	const { totalDistance, totalFuel, totalCO2 } = props;

	return (
		<ProjectMetricsSectionStyle>
			<ProjectMetric icon={Heart} label="totalDistanceLabel" value={totalDistance} />
			<div className="divider" />
			<ProjectMetric icon={Toxic} label="totalCO2Label" value={totalCO2} />
			<div className="divider" />
			<ProjectMetric icon={Fuel} label="totalFuelLabel" value={totalFuel} />
		</ProjectMetricsSectionStyle>
	);
};

export default ProjectMetricsSection;
