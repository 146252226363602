import { Class } from "hooks/classes/models/class";
import { UILogic } from "models/logic";

export interface ClassCreditsLeaderboardProps {
	classesWithCredits: Pick<Class, "id" | "title" | "classTotal">[]
	activeId?: string;
}

type Props = ClassCreditsLeaderboardProps;

interface Models {
	sortedClassesWithCredits: ClassCreditsLeaderboardProps["classesWithCredits"];
}

interface Operations {}

const useClassCreditsLeaderboard: UILogic<Props, Operations, Models> = ({ classesWithCredits }) => {
	const sortedClassesWithCredits = classesWithCredits.sort((a, b) => b.classTotal.totalCredits - a.classTotal.totalCredits);

	return ({
		models: { sortedClassesWithCredits },
		operations: { },
	});
};

export default useClassCreditsLeaderboard;
