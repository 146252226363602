import { UIComponent } from "models/component";
import CountUp from "react-countup";

import ProfileMetricStyle from "./ProfileMetricStyle";

interface Props {
	icon: string;
	value: number;
	label: string;
}

const ProfileMetric: UIComponent<Props> = ({
	icon, value, label,
}) => (
	<ProfileMetricStyle>
		<img src={icon} alt={label} />
		<p><CountUp end={value} /></p>
		<p>{label}</p>
	</ProfileMetricStyle>
);

export default ProfileMetric;
