import { createContext } from "react";
import { Modal } from "./models/modal";

export interface ModalContext {
	activeModal?: Modal;
	setActiveModal: (modal?: Modal) => void;
}

const modalContext = createContext<ModalContext>({
	activeModal: undefined,
	setActiveModal: () => {},
});

export default modalContext;
