import { InteractionLogic } from "models/logic";
import dayjs from "dayjs";
import { groupBy } from "utils/arrayUtils";
import { PupilCredit as PupilCreditDTO } from "../../models/schema.d";

import { ApolloAPI } from "../../models/API";

import { PupilCredit } from "./models/pupilCredit";
import PupilCreditMapper from "./pupilCreditMapper";

type API = ApolloAPI<"pupilCredits">;

interface Operations {
}

export interface PupilCreditsByDay {
	day: string;
	pupilCredits: PupilCredit[];
}

type Models = {
	pupilCredits: PupilCredit[];
	pupilCreditsByDay: PupilCreditsByDay[];
	pupilCreditTotalsForLastSevenDays: number[];
};

const getPupilCreditTotalsForLastSevenDays = (pupilCreditsByDay: PupilCreditsByDay[]): number[] => {
	const arr: number[] = [];

	for (let i = 6; i >= 0; i -= 1) {
		const day = dayjs().subtract(i, "day").startOf("day").toString();

		const pupilCreditsForDay = pupilCreditsByDay.find((item) => item.day === day);

		if (pupilCreditsForDay) {
			arr.push(pupilCreditsForDay.pupilCredits.length);
		} else {
			arr.push(0);
		}
	}

	return arr;
};

const usePupilCredits: InteractionLogic<API, Operations, Models> = (pupilCreditsAPI) => {
	const mapper = new PupilCreditMapper();

	const extractProfileDTOsFromData = (data: API["data"]): DeepPartial<PupilCreditDTO>[] => data?.pupilCredits || [];

	const pupilCredits = pupilCreditsAPI?.data
		? extractProfileDTOsFromData(pupilCreditsAPI.data).map(mapper.pupilCreditDTOToPupilCredit)
		: [];

	const pupilCreditsWithDay = pupilCredits.map((pupilCredit) => (
		{
			day: dayjs(pupilCredit.createdAt).startOf("day").toString(),
			...pupilCredit,
		}
	));

	const pupilCreditsByDayRecord = groupBy(pupilCreditsWithDay, "day");
	const pupilCreditsByDay: PupilCreditsByDay[] = [];

	Object.keys(pupilCreditsByDayRecord).forEach((day: string) => {
		pupilCreditsByDay.push({
			day,
			pupilCredits: pupilCreditsByDayRecord[day],
		});
	});

	const pupilCreditTotalsForLastSevenDays = getPupilCreditTotalsForLastSevenDays(pupilCreditsByDay);

	return ({
		models: {
			pupilCredits,
			pupilCreditsByDay,
			pupilCreditTotalsForLastSevenDays,
		},
		operations: {
		},
	});
};

export default usePupilCredits;
