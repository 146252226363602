import { ApolloError } from "@apollo/client";
import { InteractionLogic } from "models/logic";
import { useEffect, useState } from "react";

import event from "event";
import client from "client";
import { useLoginWithPassword } from "./models/loginWithPassword";
import TokenManager from "./tokenManager";
import { AuthenticateWithNfcInput, AuthenticateWithPasswordInput } from "./models/input";
import { useLoginWithNfc } from "./models/loginWithNfc";

interface Operations {
	verifyWithPassword(input: AuthenticateWithPasswordInput): Promise<{ isVerified: boolean }>;
	loginWithNfc(input: AuthenticateWithNfcInput): Promise<void>;
	verifyWithNfc(input: AuthenticateWithNfcInput): Promise<{ isVerified: boolean }>;
	logout(): void;
}

type Models = {
	isLoggedIn: boolean;
	isLoading: boolean;
	error?: ApolloError;
};

export interface AuthenticationEvents {
	"login": () => void;
	"logout": () => void;
}

const useAuthentication: InteractionLogic<{}, Operations, Models> = () => {
	const [loginWithPasswordMutation, { loading: isPasswordLoginLoading }] = useLoginWithPassword({ context: { useAnonymousToken: true } });
	const [loginWithNfcMutation, { loading: isNfcLoginLoading }] = useLoginWithNfc({ context: { useAnonymousToken: true } });
	const [error, setError] = useState<ApolloError>();
	const isLoggedIn: Models["isLoggedIn"] = !!TokenManager.token;

	const logout = () => {
		TokenManager.token = "";
		client.clearStore();
		event.emit("logout");
	};

	const verifyWithPassword: Operations["verifyWithPassword"] = async (input) => {
		try {
			const result = await loginWithPasswordMutation({
				variables: {
					nfcTagId: input.nfcId,
					nfcTagType: input.nfcTagType,
					password: input.password,
				},
			});

			if (result && result.data && result.data.nfcPasswordPupilLogin.token) {
				TokenManager.token = result.data.nfcPasswordPupilLogin.token;
				return { isVerified: true };
			}

			return { isVerified: false };
		} catch (_error) {
			return { isVerified: false };
		}
	};

	const loginWithNfc: Operations["loginWithNfc"] = async (input) => {
		try {
			const result = await loginWithNfcMutation({
				variables: {
					nfcTagId: input.value,
					validationCode: window.appEnvironment.NFC_VALIDATION_CODE,
				},
			});

			if (result && result.data && result.data.nfcPupilLogin.token) {
				TokenManager.token = result.data.nfcPupilLogin.token;
				event.emit("login");
			}
		} catch (_error) {
			if (_error instanceof ApolloError) {
				setError(_error);
			}
		}
	};

	const verifyWithNfc: Operations["verifyWithNfc"] = async (input) => {
		try {
			const result = await loginWithNfcMutation({
				variables: {
					nfcTagId: parseInt(input.value, 10).toString(16),
					validationCode: window.appEnvironment.NFC_VALIDATION_CODE,
				},
			});

			if (result && result.data && result.data.nfcPupilLogin.token) {
				TokenManager.token = result.data.nfcPupilLogin.token;
				return { isVerified: true };
			}

			return { isVerified: false };
		} catch (_error) {
			return { isVerified: false };
		}
	};

	useEffect(() => {
		event.on("idled", logout);

		return () => {
			event.removeListener("idled", logout);
		};
	}, [logout]);

	return ({
		models: {
			isLoggedIn,
			isLoading: isPasswordLoginLoading || isNfcLoginLoading,
			error,
		},
		operations: {
			verifyWithPassword,
			loginWithNfc,
			verifyWithNfc,
			logout,
		},
	});
};

export default useAuthentication;
