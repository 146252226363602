/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PlaceOrderVariables = Types.Exact<{
  reward: Types.Scalars['String'];
}>;


export type PlaceOrder = (
  { __typename?: 'Mutation' }
  & { createOneRewardRequest: (
    { __typename?: 'RewardRequest' }
    & Pick<Types.RewardRequest, 'id'>
    & { reward: (
      { __typename?: 'Reward' }
      & Pick<Types.Reward, 'id' | 'collectInstructions'>
      & { rewardAssignments: Array<(
        { __typename?: 'RewardAssignment' }
        & Pick<Types.RewardAssignment, 'overrideCollectInstructions' | 'collectInstructions'>
      )> }
    ) }
  ) }
);


export const PlaceOrderDocument = gql`
    mutation placeOrder($reward: String!) {
  createOneRewardRequest(reward: $reward) {
    id
    reward {
      id
      collectInstructions
      rewardAssignments {
        overrideCollectInstructions
        collectInstructions
      }
    }
  }
}
    `;
export type PlaceOrderMutationFn = Apollo.MutationFunction<PlaceOrder, PlaceOrderVariables>;

/**
 * __usePlaceOrder__
 *
 * To run a mutation, you first call `usePlaceOrder` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrder` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrder, { data, loading, error }] = usePlaceOrder({
 *   variables: {
 *      reward: // value for 'reward'
 *   },
 * });
 */
export function usePlaceOrder(baseOptions?: Apollo.MutationHookOptions<PlaceOrder, PlaceOrderVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaceOrder, PlaceOrderVariables>(PlaceOrderDocument, options);
      }
export type PlaceOrderHookResult = ReturnType<typeof usePlaceOrder>;
export type PlaceOrderMutationResult = Apollo.MutationResult<PlaceOrder>;
export type PlaceOrderMutationOptions = Apollo.BaseMutationOptions<PlaceOrder, PlaceOrderVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    