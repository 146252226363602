// This is a strictly typed version of the default NodeJS EventEmitter.
import { AuthenticationEvents } from "hooks/authentication/useAuthentication";
import { RewardEvents } from "hooks/rewards/useRewards";
import { ShopItemEvents } from "hooks/shopItems/useShopItems";
import { ModalEvents } from "hooks/useModals/useModals";
import { StrictEventEmitter } from "strict-event-emitter";

type EventsMap =
	AuthenticationEvents
	& ModalEvents
	& RewardEvents
	& ShopItemEvents;

/**
 * Allows to emit events and listen to them. This allows us to chain different Interaction Domains
 * together without explicitly depending on each other.
 */
const event = new StrictEventEmitter<EventsMap>();

export default event;
