import { Reward } from "hooks/rewards/models/reward";
import { UIComponent } from "models/component";
import nl from "translations/nl";
import RewardListItemStyle from "./rewardListItemStyle";
import creditsIcon from "../../../../assets/svg/credits.svg";

interface RewardListItemProps {
	name: Reward["name"];
	price: Reward["price"];
}

const RewardListItem: UIComponent<RewardListItemProps> = ({ name, price }) => (
	<RewardListItemStyle>
		<p className="name">{`1x ${name}`}</p>
		<div className="price-container">
			<p className="price">{price}</p>
			<img src={creditsIcon} alt={nl.webshop.coins} width="39" height="27" />
		</div>
	</RewardListItemStyle>
);

export default RewardListItem;
